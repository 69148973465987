import React, { useRef, useState } from "react";
import { RgbaStringColorPicker } from "react-colorful";
import { colord } from "colord";
import Portal from '../../../common/Portal.js';
import useClickOutside from '../../../common/useClickOutside.js';
import IconButton from '../../buttons/IconButton';
import { ReactComponent as ChevronDown } from '../../icons/svg/chevron-down.svg';
import { ReactComponent as Close } from '../../icons/svg/close.svg';
import DropDown from '../../DropDown.js';

function ChoseColor(props){
    const colorPickerRef = useRef();

    const { color, closePalette, onChange, openColorPicker, togglePalette } = props;

    //const close = () => togglePalette(false);

    const rgbaString = () => {
        return color && color.startsWith("rgba") ? color : colord(color).toRgbString();
    };

    //useClickOutside(colorPickerRef, close, openColorPicker);

    return (
        <div ref={colorPickerRef}>
            {!props.readOnly ?
                !color ?
                    <IconButton className="relative rounded-full my-auto" onClick={(e) => togglePalette(e)} color="transparent">
                    {
                        openColorPicker ?
                            <Close style={{ width: '.8em', height: '.8em' }} className="fill-current text-xl cursor-pointer" />
                            :
                            <ChevronDown style={{ width: '.8em', height: '.8em' }} className="fill-current text-xl cursor-pointer" />
                    }
                    </IconButton>
                    :
                    <div
                        className="relative cursor-pointer rounded-full my-auto h-5 w-5"
                        style={{
                            backgroundColor: color
                        }}
                        onClick={(e) => togglePalette(e)}
                    />
                
                :

                <div
                    className="relative cursor-default rounded-full my-auto h-5 w-5"
                    style={{
                        backgroundColor: color
                    }}
                />
            }
            
            {openColorPicker && (
                <DropDown
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            e.stopPropagation()
                        }
                    }}
                    ref={colorPickerRef}
                    open={openColorPicker}
                    onClose={() => closePalette(false)}
                    isspreadsheet={props.isSpreadsheet}
                    posx={props.posX}
                    posy={props.posY}
                    screentype={props.screenType}
                >
                    <div className="absolute rounded-lg shadow-lg z-40">
                        <RgbaStringColorPicker color={rgbaString()} onChange={onChange} />
                    </div>
                </DropDown>
            )}
        </div>
    );
};

export default ChoseColor;
