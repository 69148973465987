import React from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { observer } from 'mobx-react-lite'

const base_state = {
    graph: {},
    tooltipFormat: value => value,
    axisBottom: {
        tickSize: 6,
        tickPadding: 5,
        tickRotation: 0,
        legend: "retesasdfasdf",
        legendPosition: 'middle',
        legendOffset: 32,
        field: {},
        field_labels: false


    },
    axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Cantidad',
        legendPosition: 'middle',
        legendOffset: -40
    },
    tickValues: 'every 1 month',
    markers: []


}

const BarGraph = observer(function BarGraph(props) {
    const {  graph } = props
    const legend = graph.x_legend
    const y_legend = graph.y_legend
    const data = graph.screen.data_values
    const format = graph.x_labels ? value => graph.x_labels[value]:value=>value
    const tooltip_format = graph.x_labels ? id => graph.x_labels[id]:id=>id

    function get_tooltip(datum){
        
        return (
            <strong className="px-2 py-2 bg-white text-xs"  style={{zIndex:9999,color:datum.color }}>
        
            {tooltip_format(datum.data.id)} : {datum.data.value}
          </strong>
        )
    }

    function onBarClick(bar){
        console.log("BAR RECEIVED ON CLICK")
        console.log(bar)
        if(graph.screen.default_action){
            const record = graph.screen.data.get_record_by_id(bar.data.id)
            graph.screen.default_action.execute([record])
        }


    }
    return (


        <ResponsiveBar
            data={data}
            keys={['value']}
            indexBy={graph.groupby}
            margin={{ top: 10, right: 20, bottom: 50, left: 60 }}
            colorBy='index'
            padding={0.3}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            tooltip = {(e) => 
                get_tooltip(e)
                   
               }
            
            axisBottom={{
                tickSize: 6,
                tickPadding: 5,
                tickRotation: 0,
                legend: legend,
                legendPosition: 'middle',
                legendOffset: 32,
                field: graph.x_field,
                field_labels: graph.x_labels,
                format:format


            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: y_legend,
                legendPosition: 'middle',
                legendOffset: -50
            }}
            // tooltipFormat={() => value => value}
            labelSkipWidth={12}
            labelSkipHeight={12}
            groupMode="grouped"


            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            onClick={onBarClick}
        />




    )

});
export default BarGraph;