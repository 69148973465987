import { makeAutoObservable, autorun, runInAction, action, observable, makeObservable, computed } from "mobx"
import { evalCondition } from '../common'

export class AttachmentsHandler {
    screen = false

    constructor(screen) {
        // makeObservable(this, {
        //     loading: observable,
        //     dependencies: observable,
        //     inputs: observable,
        //     reload: action,
        //     update_inputs:action,
        //     setLoading:action
        // })

        this.screen = screen        


    }

    async get_by_id(id, data_url=false){
        const args = {
            'view': this.screen.view.id
        }
        let attach = await this.screen.connection.dispatch('GET', `/attachment/${id}`, args, false, false, true)
        if(data_url && attach.data){
            attach = window.URL.createObjectURL(attach.data)
        }
        return attach
    }
    
    
}
