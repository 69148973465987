import React, { useState, useEffect, useReducer, Fragment } from 'react';
import Paper from '../ui/surfaces/Paper.js'
import { ReactComponent as SearchIcon } from '../ui/icons/svg/filter.svg';
import Filter from '../ui/fields/Filter.js';
import FormButton from '../ui/buttons/FormButton.js';
import DropDown from './DropDown.js';
import IconButton from './buttons/IconButton.js';
import {observer} from 'mobx-react-lite'


const FilterBar = observer(function FilterBar(props){
    const { screen, parent_ref, filtersInCalendar } = props
    const filters = screen.filters
    const visibleActiveFilters = filters.applied_qty
    const record = filters.record
    const default_open = (screen.default_filters_open && (!screen.current_search || !screen.current_search.length))
    const [filters_open, setFiltersOpen] = useState(default_open)
    
    function toogleFilters(){
        setFiltersOpen(!filters_open)
    }

    function addFilter(){
        return filters.addFilter()
    }
    function removeFilter(filter){
        return filters.removeFilter(filter)
    }
    function search(){
        
        let search_started = filters.search()
        if(search_started){
            toogleFilters()
        }
        
        
        
        
    }
  
   
    return (
        
        <>
      <div className="inline-flex relative w-fit">
        {visibleActiveFilters > 0 ? (
          <div className="absolute inline-block -mt-3 top-0 right-0 bottom-auto left-auto translate-x-2/4 -translate-y-1/2 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 py-0.5 px-1.5 text-xxs leading-none text-center whitespace-nowrap align-baseline font-medium bg-red-600 text-white rounded-full z-10">
            {visibleActiveFilters}
          </div>
        ) : (
          ""
        )}


        <IconButton
          color="transparent"
          className="text-primary"
          title="Añadir Filtro"
          onClick={toogleFilters}
        >
          <div className="w-4 h-4">
            <SearchIcon
              color="primary"
              className="fill-current"
            />
          </div>
        </IconButton>
      </div>

            {filters_open &&

            <DropDown
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        e.stopPropagation()
                        search()
                    }
                }}
                ref={parent_ref}
                open={filters_open}
                onClose={toogleFilters}
            >
                <Paper className="mt-1 border-t-2 pt-4 rounded text-gray-600">
                    <div >
                        {filters.active_filters.map((filter, index) => (

                            <div key={index} className={ filter.hide_modifiers ? "pl-15 pr-6 py-2.5" : "px-6 py-2.5" }>
                                <Filter
                                    index={index}
                                    filter={filter}
                                    removeFilter={removeFilter}
                                    record={record}
                                    
                                    


                                />
                            </div>




                        ))}

                    </div>
                        <div className="flex items-center justify-between pb-6 pt-4 px-8 w-full">
                            <div>
                            <span className="cursor-pointer transition duration-300 font-bold rounded-full outline-primary truncate text-xs py-2 px-2 mr-2 bg-transparent text-primary hover:bg-light-selected" onClick={(e)=>{addFilter()}}>
                                + Añadir Filtro
                            </span>
                            </div>
                            <div>
                                <FormButton
                                 color="primary" 
                                 state_attrs={{invisible:false,readonly:false}}
                                 onClick={(e)=>{search()}}>
                                    BUSCAR
                                </FormButton>
                            </div>
                        </div>
                    
                </Paper>
            </DropDown> 
            }
            




        </>
        
        

        
    )

})

export default FilterBar;