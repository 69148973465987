import React, { useEffect, useState } from 'react';
import Field from '../Field.js';
import ChoseColor from './ChoseColor.js';

const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal";

function ColorPicker(props) {
    const {
        field,
        onFocus,
        state_attrs,
        record
    } = props;

    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [posX, setPosX] = useState(null);
    const [posY, setPosY] = useState(null);

    const isSpreadsheet = field.screen.type === "spreadsheet";
    const screenType = field.screen.type

    const togglePalette = (e) => {
        if (!openColorPicker) {
            if (isSpreadsheet) {
                const gridWidth = parseInt(document.getElementById("body_grid").style.width)
                const gridHeight = parseInt(document.getElementById("body_grid").style.height)
                if(e.clientX > gridWidth){
                    setPosX(!(e.clientX - gridWidth > 60) ? e.clientX - 200 : gridWidth - 20);
                    setPosY(e.clientY > gridHeight ? (e.clientY - 215) : (e.clientY + 25));
                } else if(gridWidth - e.clientX > 15 && gridWidth - e.clientX < 180){
                    setPosX((gridWidth - e.clientX > 15) ? e.clientX - 200 : gridWidth - 20);
                    setPosY(e.clientY > gridHeight ? (e.clientY - 215) : (e.clientY + 25));
                } else {
                    setPosX(e.clientX);
                    setPosY(e.clientY > gridHeight ? (e.clientY - 215) : (e.clientY + 25));
                }
            } else {
                const formSize = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect()

                setPosX(e.clientX - 170);
                setPosY(e.clientY < 272 ? ((Math.abs(formSize.y) + e.clientY) + 95): ((Math.abs(formSize.y) + e.clientY) - 135));
            }
        }
        setOpenColorPicker(!openColorPicker);
    }

    return (
        <div className="flex justify-between align-middle">
            <div className={field.screen.type === "form" ? "w-4/5" : "w-40"}>
                <input
                    id={props.id || props.name}
                    autoFocus={props.autoFocus}
                    // tabIndex="-1"
                    ref={props.inputRef}
                    name={props.name}
                    label={props.label}
                    required={state_attrs.required}
                    readOnly={state_attrs.readonly}
                    className={base_cls}
                    value={props.value}
                    onChange={(e) => {props.onChange(e,e.target.value)}}
                    onBlur={props.onBlur}
                    placeholder={field.description}
                    onKeyDown={props.handleKeyDown}
                    style={props.style}
                    autoComplete="off"
                    onFocus={onFocus}
                />
            </div>

            <ChoseColor
                color={props.value}
                closePalette={setOpenColorPicker}
                onChange={(e) => {props.onChange(e,e)}}
                field={field}
                openColorPicker={openColorPicker}
                posX={posX}
                posY={posY}
                readOnly={state_attrs.readonly}
                screenType={screenType}
                togglePalette={togglePalette}
                value={props.value}
            />
        </div>
    )
}

export default ColorPicker;