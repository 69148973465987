import React, { useEffect, useRef, useState } from "react";
import Modal from '../../Modal.js';
import useClickOutside from '../../../common/useClickOutside.js';
import IconButton from '../../buttons/IconButton';
import FormButton from '../../buttons/FormButton.js'
import { ReactComponent as CheveronLeft } from '../../icons/svg/cheveron-left.svg';
import { ReactComponent as CheveronRight } from '../../icons/svg/cheveron-right.svg';
import { ReactComponent as EditIcon } from '../../icons/svg/edit-pencil.svg';
import { ReactComponent as ScreenFull } from '../../icons/svg/screen-full.svg';
import ListItem from './ListItem.js';
import Tooltip from '../../Tooltip.js';

const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal cursor-pointer";

function TransferList(props) {
    const {
        addPosibleValues,
        autoFocus,
        field,
        handleAllLeft,
        handleAllRight,
        handleCheckedLeft,
        handleCheckedRight,
        handleOneLeft,
        handleOneRight,
        handleToggle,
        leftChecked,
        onBlur,
        onChange,
        onFocus,
        posible,
        record,
        rightChecked,
        selected,
        setPosible,
        state_attrs
    } = props;

    //Not applied yet
    const transferListHeight = props.transferListHeight ? props.transferListHeight : 468;

    const [openTransferList, setOpenTransferList] = useState(false);

    const transferListRef = useRef();

    const isSpreadsheet = field.screen.type === "spreadsheet";
    const isReadonly = field.readonly;

    const saveChanges = () => {
        if(field.type === "multi_link"){
            field.set_value(selected, record) 
            onBlur(false, selected)
        } else {
            onChange(false, selected)
            onBlur(false, selected)
        }
    }

    const closeTransferList = () => {
        setOpenTransferList(false)
    }

    const saveTansferListModal = () => {
        setOpenTransferList(false);
        saveChanges();
    }

    useClickOutside(transferListRef, closeTransferList, openTransferList);

    return (
        <>
        {isSpreadsheet ? 
            <>
                <div className="flex justify-between align-middle">
                    <div
                        className={field.screen.type === "form" ? "w-4/5" : "w-40"}
                        onClick={() => setOpenTransferList(true)}
                    >
                        <input
                            id={props.id || props.name}
                            autoFocus={autoFocus}
                            ref={props.inputRef}
                            name={props.name}
                            label={props.label}
                            required={state_attrs.required}
                            readOnly={state_attrs.readonly}
                            className={base_cls}
                            value={field.get_string_value(record)}
                            onChange={() => setOpenTransferList(true)}
                            onBlur={onBlur}
                            placeholder={field.description}
                            onKeyDown={props.handleKeyDown}
                            style={props.style}
                            autoComplete="off"
                            onFocus={onFocus}
                        />
                    </div>

                    <IconButton
                        onClick={e => setOpenTransferList(true)}
                        className="justify-end px-2 my-1 h-8 w-6"
                        color="transparent"
                    >
                        {isReadonly ?
                            <ScreenFull className="fill-current text-primary h-3 w-3 z-40" />
                        :
                            <EditIcon className="fill-current text-primary h-3 w-3 z-40" />
                        }
                    </IconButton>
                </div>

            {openTransferList && (
                <Modal
                    open={openTransferList}
                    style={{ height: isReadonly ? '480px': '530px', width: '532px' }}
                    containerRefModal={transferListRef}
                    className={`${isReadonly ? 'bg-gray-200' : 'bg-white'} shadow-xl`}
                >
                    <div
                        style={{
                            height: `${transferListHeight}px`,
                            padding: '16px',
                        }}
                        className="flex justify-between"
                    >
                        <ListItem
                            checkValues={handleToggle}
                            handleClickRight={handleCheckedRight}
                            handleOneRight={handleOneRight}
                            isReadonly={isReadonly}
                            items={posible}
                            listType="posible"
                            record={record}
                            title="Posibles"
                        />

                        {isReadonly ?
                            <div className="p-10"/>
                            :
                            <div className="py-10">
                                <div className="py-10">
                                    <div className="m-2 p-2">
                                        <Tooltip content="Añanadir todos" type="standard">
                                            <IconButton
                                                className={`rounded-full border border-gray-400 h-14 w-14 p-2 z-40 ${posible && posible.length === 0 ?
                                                        "opacity-50 cursor-not-allowed"
                                                        :
                                                        "hover:bg-light-selected"
                                                }`}
                                                disabled={posible && posible.length === 0}
                                                onClick={()=> handleAllRight()}
                                            >
                                                <CheveronRight className="fill-current text-primary h-6 w-6" />
                                                <CheveronRight className="fill-current text-primary h-6 w-6" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className="m-2 p-2">
                                        <IconButton 
                                            className={`rounded-full border border-gray-400 h-10 w-10 p-2 z-40 ${leftChecked && !leftChecked.length > 0 ?
                                                    "opacity-50 cursor-not-allowed"
                                                    :
                                                    "hover:bg-light-selected"
                                            }`}
                                            disabled={leftChecked && !leftChecked.length > 0}
                                            onClick={()=> handleCheckedRight()}
                                        >
                                            <CheveronRight className="fill-current text-primary h-5 w-5" />
                                        </IconButton>
                                    </div>
                                    <div className="m-2 p-2">
                                        <IconButton 
                                            className={`rounded-full border border-gray-400 h-10 w-10 p-2 z-40 ${rightChecked && !rightChecked.length > 0 ?
                                                    "opacity-50 cursor-not-allowed"
                                                    :
                                                    "hover:bg-light-selected"
                                            }`}
                                            disabled={rightChecked && !rightChecked.length > 0}
                                            onClick={()=> handleCheckedLeft()}
                                        >
                                            <CheveronLeft className="fill-current text-primary h-5 w-5" />
                                        </IconButton>
                                    </div>

                                    <div className="m-2 p-2">
                                        <Tooltip content="Eliminar todos" type={field.tooltip_type}>
                                            <IconButton
                                                className={`rounded-full border border-gray-400 h-14 w-14 p-2 z-40 ${selected && selected.length === 0 ?
                                                        "opacity-50 cursor-not-allowed"
                                                        :
                                                        "hover:bg-light-selected"
                                                }`}
                                                disabled={selected && selected.length === 0}
                                                onClick={()=> handleAllLeft()}
                                            >
                                                <CheveronLeft className="fill-current text-primary h-6 w-6" />
                                                <CheveronLeft className="fill-current text-primary h-6 w-6" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                        }

                        <ListItem
                            checkValues={handleToggle}
                            handleClickLeft={handleCheckedLeft}
                            handleOneLeft={handleOneLeft}
                            isReadonly={isReadonly}
                            items={selected}
                            listType="selected"
                            record={record}
                            title="Selecteds"
                        />
                    </div>

                    {!isReadonly &&
                        <div className="flex justify-center pt-4">
                            <FormButton
                                state_attrs={state_attrs}
                                variant="list"
                                color="transparent"
                                className=" mr-1"
                                onClick={() => saveTansferListModal()}
                            >
                                Guardar
                            </FormButton>
    
                            <FormButton
                                state_attrs={state_attrs}
                                variant="list"
                                color="transparent"
                                className=" ml-1"
                                onClick={() => setOpenTransferList(false)}
                            >
                                Cancelar
                            </FormButton>
                        </div>
                    }
                </Modal>
            )}
            </>
            :
            <div
                style={{
                    height: `${transferListHeight}px`,
                    padding: '10px',
                }}
                className="flex justify-between"
            >
                <ListItem
                    checkValues={handleToggle}
                    handleClickRight={handleCheckedRight}
                    handleOneRight={handleOneRight}
                    isReadonly={isReadonly}
                    items={posible}
                    listType="posible"
                    saveChanges={saveChanges}
                    record={record}
                    title="Posibles"
                />

                {isReadonly ?
                    <div className="p-10"/>
                    :
                    <div className="py-10">
                        <div className="py-10">
                            <div className="m-2 p-2">
                                <Tooltip content="Añanadir todos" type="standard">
                                    <IconButton
                                        className={`rounded-full border border-gray-400 h-14 w-14 p-2 z-40 ${posible && posible.length === 0 ?
                                                "opacity-50 cursor-not-allowed"
                                                :
                                                "hover:bg-light-selected"
                                        }`}
                                        disabled={posible && posible.length === 0}
                                        onClick={()=> handleAllRight()}
                                    >
                                        <CheveronRight className="fill-current text-primary h-6 w-6" />
                                        <CheveronRight className="fill-current text-primary h-6 w-6" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="m-2 p-2">
                                <IconButton 
                                    className={`rounded-full border border-gray-400 h-10 w-10 p-2 z-40 ${leftChecked && !leftChecked.length > 0 ?
                                            "opacity-50 cursor-not-allowed"
                                            :
                                            "hover:bg-light-selected"
                                    }`}
                                    disabled={leftChecked && !leftChecked.length > 0}
                                    onClick={()=> handleCheckedRight()}
                                >
                                    <CheveronRight className="fill-current text-primary h-5 w-5" />
                                </IconButton>
                            </div>
                            <div className="m-2 p-2">
                                <IconButton 
                                    className={`rounded-full border border-gray-400 h-10 w-10 p-2 z-40 ${rightChecked && !rightChecked.length > 0 ?
                                            "opacity-50 cursor-not-allowed"
                                            :
                                            "hover:bg-light-selected"
                                    }`}
                                    disabled={rightChecked && !rightChecked.length > 0}
                                    onClick={()=> handleCheckedLeft()}
                                >
                                    <CheveronLeft className="fill-current text-primary h-5 w-5" />
                                </IconButton>
                            </div>

                            <div className="m-2 p-2">
                                <Tooltip content="Eliminar todos" type={field.tooltip_type}>
                                    <IconButton
                                        className={`rounded-full border border-gray-400 h-14 w-14 p-2 z-40 ${selected && selected.length === 0 ?
                                                "opacity-50 cursor-not-allowed"
                                                :
                                                "hover:bg-light-selected"
                                        }`}
                                        disabled={selected && selected.length === 0}
                                        onClick={()=> handleAllLeft()}
                                    >
                                        <CheveronLeft className="fill-current text-primary h-6 w-6" />
                                        <CheveronLeft className="fill-current text-primary h-6 w-6" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                }

                <ListItem
                    checkValues={handleToggle}
                    handleClickLeft={handleCheckedLeft}
                    handleOneLeft={handleOneLeft}
                    isReadonly={isReadonly}
                    items={selected}
                    listType="selected"
                    record={record}
                    saveChanges={saveChanges}
                    selectedItems={selected}
                    title="Selecteds"
                />
            </div>
        }
        </>
    )
}


export default TransferList;