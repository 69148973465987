import React, {useContext} from 'react';

import SessionContext from '../connection/SessionContext'
import ThemeContext from './ThemeContext'
import {Theme} from './Theme'


export function ThemeContextProvider({children, use_default_theme}){


  const connection = useContext(SessionContext)
  const theme = new Theme(connection, use_default_theme)

  
    return (

   
        <ThemeContext.Provider value={theme}>

          <React.Fragment>

            {children}


          </React.Fragment>





        </ThemeContext.Provider>
       
    )
  
  
  
  
    }
  
