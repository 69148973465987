import React, { useContext } from 'react';
import {SessionContext} from './SessionContext'
import { NotificationContext } from '../notifications/NotificationContext'
import {Connection} from './Connection'

export function ConnectionProvider({ children, connection_info, load_db_adapter }) {

    const notifications = useContext(NotificationContext)
    return (
      <SessionContext.Provider value={new Connection(notifications, connection_info, load_db_adapter)}>
        {children}
      </SessionContext.Provider>
    )
  
  
  
  
  }
  
  
  export default ConnectionProvider;
