import React, { useState } from 'react';
import IconButton from '../../buttons/IconButton';
import { ReactComponent as CheveronLeft } from '../../icons/svg/cheveron-left.svg';
import { ReactComponent as CheveronRight } from '../../icons/svg/cheveron-right.svg';

function Item(props){
    const {
        checkValues,
        handleOneLeft,
        handleOneRight,
        item,
        isReadonly,
        listType
    } = props;

    const [selected, setSelected] = useState(false);

    const selectItem = (item) => {
        setSelected(!selected);
        checkValues(item);
    }

    return (
        <>
            {isReadonly ?
                <div className="items-center my-1">
                    {listType === "posible" ?
                        <p className="text-sm p-2">{item.rec_name ? item.rec_name : item.label}</p>
                        :
                        <div className="w-full flex justify-end">
                            <p className="text-sm p-2">{item.rec_name ? item.rec_name : item.label}</p>
                        </div>
                    }
                </div>
                :
                <div className={`flex items-center justify-between my-1 hover:bg-light-selected ${selected ? 'bg-light-selected' : ''}`}>
                    {listType === "posible" ?
                        <>
                            <div className="cursor-pointer w-4/5" onClick={() => selectItem(item)}>
                                <p className="text-sm p-2">{item.rec_name ? item.rec_name : item.label}</p>
                            </div>
    
                            <IconButton 
                                className="rounded-full p-2 z-40 hover:bg-light-selected"
                                onClick={() => handleOneRight(item)}
                            >
                                <CheveronRight className="fill-current text-primary h-3 w-3" />
                            </IconButton>
                        </>
                        :
                        <>
                            <IconButton 
                                className="rounded-full p-2 z-40 hover:bg-light-selected"
                                onClick={() => handleOneLeft(item)}
                            >
                                <CheveronLeft className="fill-current text-primary h-3 w-3" />
                            </IconButton>
    
                            <div className="cursor-pointer w-4/5 flex justify-end" onClick={() => selectItem(item)}>
                                <p className="text-sm p-2">{item.rec_name ? item.rec_name : item.label}</p>
                            </div>
                        </>
                    }
                </div>
            }
        </>
    )
}

export default Item;