import { environment } from '../common'
//NATIVE_WEB COMPATIBILIty
// import AsyncStorage from '@react-native-async-storage/async-storage';
// const AsyncStorage = require('react-native-async-storage/async-storage');

let asyncStorage = false
try{
    asyncStorage = require('@react-native-async-storage/async-storage').default
    // const {fetch} = require('react-native-fetch-api')
}
catch(e){
    console.log("Web Environment")
    
}

const Storage = environment() === 'web' ? 
                localStorage
                :
                asyncStorage
                




export const createStorage = ()=>{
    const env = environment()
    if (env === 'web'){
        return new WebStorage()
    }
    else if (env === 'native'){
        return new NativeStorage()
    }
    else{
        throw new Error('Not implemented Environment:'+ env)
    }
}

export class WebStorage {

    constructor() {
        this.environment = 'web'

    }


    getItem(key){
        return Storage.getItem(key)
    }

    setItem(key, value){
        return Storage.setItem(key, value)
    }

    removeItem(key){
        return Storage.removeItem(key);
    }


}

export class NativeStorage {

    constructor() {
        this.environment = 'native'

    }


    async getItem(key){
        let val = ""
        
        
        try {
            const value = await Storage.getItem(key)
            if(value !== null) {
             val = value
            }
          } catch(e) {
            console.log("Error")
            console.log(e)
          }
        
        return val
    }

    async setItem(key, value){
        let res = false

        try {
            await Storage.setItem(key, value)
            res = true
          } catch(e) {
            console.log("Error")
            console.log(e)
          }
        return res
    }

    async removeItem(key){
        let res = false

        try {
            await Storage.removeItem(key)
            res = true
          } catch(e) {
            console.log("Error")
            console.log(e)
          }
        return res
    }


}