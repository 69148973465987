import  {  useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useClickOutside(ref, callback, open, enabled) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        if(open && enabled){
            
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback(event.target)
                    
                }
            }
    
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, open, enabled]);
}

export default useClickOutside;