import React, {  Suspense } from 'react';

function CustomLoader(props) {
    const {screen, component_name} = props
    const view = component_name ? component_name:screen.view.custom_view_name  
    const LazyComponent = React.lazy(()=> import(`../../custom_views/views/${view}.js`))

    return (
        // <div>
        //     MOCKUP
        
        // </div>
      <Suspense fallback={<div>Loading...</div>}>
        <LazyComponent {...props}/>
        
      </Suspense>
    );
  }

export default CustomLoader