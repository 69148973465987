import { makeObservable, action, observable } from 'mobx'
import { Screen } from '../screen/Screen'
import { ScreenGroup } from '../screen/ScreenGroup'


export class Login {
    screen=""
    connection=""
    constructor(connection){
        makeObservable(this,{
            setScreen:action,
            screen:observable
        })
        this.connection = connection
        this.getLoginView()
        
        

    }

    createScreen(definition){
        let group = new ScreenGroup(this.connection, true)
        
        // let screen = new Screen({}, definition.view, this.connection,false, [], {},false, false,false,false)
        // let screen = group.addScreen({}, definition.view, this.connection,false, [], {},false, false,false,false)
        let screen = group.addScreen(definition.view,false,[],{},true,false,false,false,false)
        screen.createFields(definition.fields)
        screen.createActions(definition.actions)
        screen.loadingFields= false
        
        return screen
    }

    setScreen(definition, login_info){
        let screen = this.createScreen(definition)
        // let stored_values = JSON.parse(this.connection.storage.getItem('kalenis_login_info'))
        let values = login_info ? login_info:{'id':-1}
        let record = screen.data.addRecord({values:values})
        screen.set_active_record(record)
        screen.data.loadingInitialData = false
        screen.data.loading = false

        //Map 'login' action to getToken method
        screen.set_actions_map({
            'login': (records)=>{this.getToken(records)}
        })
        this.screen = screen

        

    }

    async getLoginView(){
        const abortController = new AbortController();
        let login_info = false
        let view_definition = await this.connection.dispatch('GET', '/auth/login_view', {}, false, true, false, abortController)
        if(!view_definition){
            return
        }
        let stored_data = await this.connection.storage.getItem('kalenis_login_info');
        
        if(stored_data){
            login_info = JSON.parse(stored_data)
        }
        

        
        if(view_definition){
            this.setScreen(view_definition, login_info)    
        }
        
    }

    async getToken(records){
        
        let session = await this.connection.login(records[0]._values)
        
        if(!session){
            this.connection.notifications.addSnack({
                message:"Verifique los datos ingresados",
                level:"error",
                
            })
            return false
        }

        await this.connection.storage.setItem('kalenis_login_info', JSON.stringify(records[0]._values))
        return true
    }

    async getSignupView(){
        const abortController = new AbortController();
        let view_definition = await this.connection.dispatch('GET', '/auth/signup_request_view', {}, false, true, false, abortController)
        

        return view_definition
    }
    async requestSignup(){
        
        let style = {
            'modal':{ 
                height: 'calc(70% - 100px)', 
                backgroundColor:'transparent',
                boxShadow: 'none' 
            }
        }
        let cls = {'modal':'w-full sm:w-2/4 lg:w-2/4 h-full'}
        let definition = await this.getSignupView()
        let screen = false
        if(!definition){
            return false
        }

        screen = this.screen.modal_childs.addScreen(definition.view, this.connection,false, [], {},false, false,false,false)
        screen.createFields(definition.fields)
        screen.createActions(definition.actions)
        let record = screen.data.addRecord({values:{'id':-1}})
        screen.set_active_record(record)
        screen.loadingFields= false
        screen.data.loadingInitialData = false
        screen.data.loading = false
        screen.set_actions_map({
            'request_signup': (records)=>{this.submitRequestSignup(records)}
        })
        screen.add_style(style)
        screen.add_classname(cls)
        
    }

    async submitRequestSignup(records){
        
        const abortController = new AbortController();
        let res = await this.screen.connection.dispatch('POST', '/auth/new_signup_request', records[0]._values, false, false, false, abortController)
        
        if(res){
            this.screen.modal_childs.clear()
            this.connection.notifications.addSnack({
                message:"Muchas gracias. Su solicitud será revisada.",
                level:"sucessfull"
            })
        }
    }

    async getForgoPasswordView(){
        const abortController = new AbortController();
        let view_definition = await this.connection.dispatch('GET', '/auth/reset_password_request_view', {}, false, true, false, abortController)
        return view_definition
    }

    async forgotPassword(){
        
        let style = {
            'modal':{ 
                height: 'calc(70% - 100px)', 
                backgroundColor:'transparent',
                boxShadow: 'none' 
            }
        }
        let cls = {'modal':'w-full sm:w-2/4 lg:w-2/4 h-full'}
        let definition = await this.getForgoPasswordView()
        let screen = false
        if(!definition){
            return false
        }

        screen = this.screen.modal_childs.addScreen(definition.view, this.connection,false, [], {},false, false,false,false)
        screen.createFields(definition.fields)
        screen.createActions(definition.actions)
        let record = screen.data.addRecord({values:{'id':-1}})
        screen.set_active_record(record)
        screen.loadingFields= false
        screen.data.loadingInitialData = false
        screen.data.loading = false
        screen.set_actions_map({
            'request_password_reset': (records)=>{this.submitPasswordResetRequest(records)}
        })
        screen.add_style(style)
        screen.add_classname(cls)
        
    }

    async submitPasswordResetRequest(records){
        
        const abortController = new AbortController();
        let res = await this.screen.connection.dispatch('POST', '/auth/reset_password', records[0]._values, false, false, false, abortController)
        
        if(res){
            this.screen.modal_childs.clear()
            this.connection.notifications.addSnack({
                message:"Muchas gracias, las instrucciones serán enviadas por E-mail",
                level:"sucessfull"
            })
        }
    }





}