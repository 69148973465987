import React from 'react';
import { Screens } from 'kalenis-web-components'

function AppBar(props) {


    return (
        <>
            <Screens.Sm down>
                <div className="w-full z-40 shadow-md top-0 text-contrast-primary right-0 fixed bg-primary ">
                    {props.children}
                </div>
            </Screens.Sm>
            <Screens.Md up>
                <div style={{ width: `calc(100% - ${props.menuWidth}px)` }} className="w-full z-40 text-contrast-primary shadow-md top-0 right-0 fixed bg-primary ">
                    {props.children}
                </div>
            </Screens.Md>

            

        </>



    )

}

export default AppBar;