import ReactDOM from 'react-dom'





export const Portal = props => {

    let mount = document.getElementById("portal_root");
    if(props.target){
      mount = document.getElementById(props.target)
    }

    return ReactDOM.createPortal(
        props.children,
        mount,
    
    );
  };

export default Portal;