import React from "react";
import { ReactComponent as CloseIcon } from "./icons/svg/close.svg";

function Chip(props) {
  const {spaceBetween, text, allowRemove, onRemove} = props;
  return (
    <span className={`${spaceBetween} cursor-default px-2.5 pl-4 py-1 rounded-full text-gray-600 bg-gray-300 font-medium text-xsm flex align-center w-max active:bg-gray-400 transition duration-300 ease`} >
      {text}
      {allowRemove &&
        <button
          className="bg-transparent hover focus:outline-none"
          onClick={onRemove}
          >
            <CloseIcon className="w-2.5 ml-2 fill-current opacity-50 hover:opacity-90 transition duration-200" />
        </button>
      }
      
    </span>
  );
}

export default Chip;
