import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"




export class BarPieGraph {
    id = ""
    type = ""
    groupby = ""
    action = ""
    markers = []
    screen = ""
    

    get definition(){
        return {
            id:this.id,
            type:this.type,
            groupby:this.groupby,
            action:this.action,
            markers:this.markers,

        }
    }

    get x_field(){
        
        return this.screen.fields.find(f => f.name == this.groupby)
    }
    get x_legend(){
        return this.x_field ? this.x_field.description:""
    }
    get value_field(){
        return this.screen.fields.find(f => f.name == 'value')
    }
    get y_legend(){
        return this.value_field ? this.value_field.description:""
    }
    get x_labels(){
        let labels = false
        if (this.x_field.type === 'selection'){
            labels = {}
            this.x_field.selection_options.forEach(function (option) {
                labels[option.value] = option.label
              })
        }
        else if(this.x_field.type === 'many2one'){
            labels = {}
            this.screen.data.records.forEach(function(record){
                const value = this.x_field.get_value(record)
                
                labels[record.id] = value ? value.rec_name:""
            }.bind(this))
        }
        
        return  labels
    }

    constructor(definition, screen) {
        this.id = definition.id
        this.type = definition.type
        this.groupby = definition.groupby
        this.action = definition.action
        this.markers = definition.graph_markers
        this.screen = screen

        makeObservable(this, {
            type: observable,
            groupby: observable,
            action: observable,
            markers: observable,

        })

    }



}