
export const findTags = (content, tagname) => {
    let html_tree = document.createElement('html')
    html_tree.innerHTML = content
    let tags = html_tree.getElementsByTagName(tagname)
    return {tags, html_tree}
}

export const setImgPlaceholders = (content) => {
    let {tags, html_tree} = findTags(content, 'img')
    for(let img of tags){
        img.className += 'skeleton'
        img.alt = '...'
        
    }
    return html_tree.outerHTML
}

const html_template = {
    findTags,
    setImgPlaceholders
}

export default html_template