import BinaryImagePreview from './BinaryImagePreview'
import ColorPicker from './ColorPicker'
import EmptyWidget from './EmptyWidget'
import MultiLink from './MultiLink.js';
import MultiSelection from './MultiSelection.js';
import {JavascriptEditor, PythonEditor} from './CodeText';

let definitions = {
    'binary':{
        'image_binary':BinaryImagePreview
    },
    'attachment':{
        'image':BinaryImagePreview,
    },
    'char':{
        'color_picker':ColorPicker
    },
    'multi_selection':{
        'transfer_list':MultiSelection
    },
    'multi_link':{
        'transfer_list':MultiLink
    },
    'text':{
        'code_editor_py':PythonEditor,
        'code_editor_js':JavascriptEditor
    }
}

const get = (field_type,name) => {
    if(definitions[field_type] && definitions[field_type][name]){
        return definitions[field_type][name]
    }
    else{
        return EmptyWidget
    }
    

}

export const widgets = {
    get
}