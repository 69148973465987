import React, { useEffect, useReducer, useRef, useState } from 'react';
import './VListStyles.css';
import 'react-virtualized/styles.css';
import { Grid } from 'react-virtualized';
import ArrowNavigation from '../../common/hoc/ArrowNavigation'
import useClickOutside  from '../../common/useClickOutside'
import { observer } from 'mobx-react-lite'
import Cell from './Cell'

const initialState = {
    scrollToRow: -1,
    scrollToColumn: -1,
    activeCell: '',

}

const CHANGE_ROW_KEYS = ['Enter', 'ArrowDown', 'ArrowDown']


const SpreadsheetBody= observer(function SpreadsheetBody(props) {

    const screen = props.screen;
    const fields = screen.visible_fields;
    const data = screen.data.records
    const isSpreadsheet = screen.type === 'spreadsheet'
    const [state, dispatch] = useReducer(reducer, initialState);
    const containerRef = useRef(null);
    const [focusInGrid, setFocusIn] = useState(false)
    const rowCount = data.length
    
    useClickOutside(containerRef, focusOut, focusInGrid);

    function reducer(state, action) {
        const new_state = { ...state }

        switch (action.type) {
            case 'RESET':
                return initialState;
            case 'SET_ACTIVE_CELL':
                new_state.scrollToRow = action.payload.scrollToRow
                new_state.scrollToColumn = action.payload.scrollToColumn
                new_state.activeCell = action.payload.activeCell
                return { ...state, ...new_state }
            

        }

    }
    const { scrollToRow, scrollToColumn, activeCell } = state;
   
    



    // Clean focused cell when order changes
    useEffect(() => {
        const key = ""
        focusCell({ key, rowIndex: -1, columnIndex: -1 })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orderedColumn]);


    //END HOOKS

    async function createRow(rowIndex, columnIndex) {
        if (!rowIndex || rowIndex === props.rowCount) {
            rowIndex = props.rowCount + 1
            columnIndex = 1
        }


        // await props.active_record.newRecord({ rowIndex })
        let record = screen.data.addRecord({values:{}, index:rowIndex})
        screen.set_active_record(record)

        

        // focusCell({
        //     key: key,
        //     newFocusedColumn: columnIndex,
        //     newFocusedRow: rowIndex,
        //     event: false,
        // })
    }

    function save_active_record(){
        
        let saved = true
        if(screen.active_record){
            if(Object.keys(screen.active_record._changed).length){
                if(!screen.default_save_action){
                    screen.notifications.addSnack(
                        { message: "No default save action defined", level: 'error' }
                    )
                    return false
                }
                else if(!screen.default_save_action.execute([data[scrollToRow]])){
                    saved = false
                }
            }
            
        }
        return saved
    }

    function focusCell({ key, columnIndex, rowIndex, event }) {

        const fr = scrollToRow
        if (fr !== rowIndex) {
            if(screen.auto_save){
                if(!save_active_record()){
                    return
                }
            }
            
            
            screen.set_active_record(data[rowIndex])

        }

        dispatch({ type: 'SET_ACTIVE_CELL', payload: { scrollToRow: rowIndex, scrollToColumn: columnIndex, activeCell: key } })

    }



    function renderBodyCell({ columnIndex, key, rowIndex, style }) {
       
        if (columnIndex < 1) {
            return;
        }
        
        const column = fields[columnIndex]
        const ac = activeCell === key
        
        let record
        
        record = data[rowIndex];


        return (

                <Cell
                    cell_key={key}
                    key={key}
                    field={column}
                    rowIndex={rowIndex}
                    columnIndex={columnIndex}
                    activeCell={ac}
                    style={style}
                    focusCell={focusCell}
                    record={record}
                    getLazyValues={props.getLazyValues}
                    focusInGrid={focusInGrid}
                    isSpreadsheet={isSpreadsheet}
                    screen={screen}
                    saveRecord={save_active_record}/>
            


        );
    }

    function validateNavigation(e) {
        //Save and validate record before navigate
        if (CHANGE_ROW_KEYS.includes(e.key)) {
            const save = props.active_record.save()

            if (!save) {
                e.preventDefault()
                e.stopPropagation()
            }


        }

    }

    // const createKey = ()=> ({ scrollToRow, scrollToColumn }) {
    //     return scrollToRow.toString().concat('-').concat(scrollToColumn.toString())
    //   }

    function createKey(scrollToRow, scrollToColumn) {
        return scrollToRow.toString().concat('-').concat(scrollToColumn.toString())
    }

    function onKeyDown(event) {
        const { rowCount, columnCount } = props
        if (!isSpreadsheet) {
            return
        }

        const {
            scrollToColumn: scrollToColumnPrevious,
            scrollToRow: scrollToRowPrevious,
        } = state;

        let { scrollToColumn, scrollToRow } = state;

        switch (event.key) {
            case 'ArrowDown':
                if(event.target.readOnly){
                    scrollToRow = Math.min(scrollToRow + 1, rowCount - 1)
                } else if(event.target.dataset.ismodal === "true") {
                    return;
                } else if (event.target.nodeName === 'INPUT' && event.target.id.includes("react-select")) {
                    scrollToRow = Math.min(scrollToRow + 1, rowCount - 1)
                } else if(event.target.nodeName === 'INPUT' && (event.target.selectionStart !== event.target.value.length)){
                    scrollToRow = Math.min(scrollToRow + 1, rowCount - 1)
                    //return;
                } else {
                    scrollToRow = Math.min(scrollToRow + 1, rowCount - 1)
                }

                break;
            case 'Enter':                
                if (event.shiftKey) {
                    if(screen.auto_save){
                        if(!save_active_record()){
                            return
                        }
                    }
                    
                    scrollToRow = scrollToRow+1
                    dispatch({ type: 'SET_ACTIVE_CELL', payload: { scrollToRow: scrollToRow, scrollToColumn: scrollToColumn, activeCell: createKey(scrollToRow, scrollToColumn) } })
                    return createRow(scrollToRow,scrollToColumn)
                    // break;
                } else if(event.target.dataset.ismodal === "true") {
                    return;
                } else {
                    scrollToRow = Math.min(scrollToRow + 1, rowCount - 1)
                    
                }
                break;
            case 'ArrowLeft':
                if(event.target.readOnly){
                    scrollToColumn = Math.max(scrollToColumn - 1, 1)
                } else if(event.target.dataset.ismodal === "true") {
                    return;
                } else if(event.target.type === "number"){
                    event.target.type = "text";
                    return;
                }
                if (event.target.nodeName === 'INPUT' && event.target.selectionStart) {
                    return;
                } else {
                    scrollToColumn = Math.max(scrollToColumn - 1, 1)
                }

                break;
            case 'ArrowRight':
                if(event.target.readOnly){
                    scrollToColumn = Math.min(scrollToColumn + 1, columnCount - 1)
                } else if(event.target.dataset.ismodal === "true") {
                    return;
                } else if(event.target.type === "number"){
                    event.target.type = "text";
                    event.target.selectionStart = event.target.value.length
                    return;
                } else if (event.target.nodeName === 'INPUT' && (event.target.selectionStart !== event.target.value.length)) {
                    return;
                } else {
                    scrollToColumn = Math.min(scrollToColumn + 1, columnCount - 1)
                }

                break;
            case 'Tab':
                event.preventDefault()
                if (event.shiftKey) {
                    scrollToColumn = Math.max(scrollToColumn - 1, 1)
                }
                else {
                    scrollToColumn = Math.min(scrollToColumn + 1, columnCount - 1)
                }

                break;
            case 'ArrowUp':
                if(event.target.readOnly){
                    scrollToRow = Math.max(scrollToRow - 1, 0)
                //} else if(event.target.type === 'number'){
                    //return;
                } else if(event.target.dataset.ismodal === "true") {
                    return;
                } else if (event.target.nodeName === 'INPUT' && event.target.selectionStart) {
                    scrollToRow = Math.max(scrollToRow - 1, 0)
                    //return;
                } //else if (event.target.nodeName === 'INPUT' && event.target.id.includes("react-select")) {
                //if(event.target.nodeName === 'INPUT' && (event.target.selectionStart != event.target.value.length)) {   
                    //return;
                 else {
                    scrollToRow = Math.max(scrollToRow - 1, 0)
                }

                break;
        }



        if (
            scrollToColumn !== scrollToColumnPrevious ||
            scrollToRow !== scrollToRowPrevious
        ) {
            event.preventDefault();
            const key = createKey(scrollToRow, scrollToColumn)

            focusCell({ key, columnIndex: scrollToColumn, rowIndex: scrollToRow, event })


        }
    }

    function focusOut(target){
        if(!target.id.includes('react-select')){
            setFocusIn(false)
            // props.active_record.save()
            

        }
        
    }

    return (
        <div 
            ref={containerRef} 
            onClick={()=>{setFocusIn(true)}}
            onPaste={(e)=>{
                console.log("RUNNING ON PASTE")
                console.log(e.clipboardData)
                if(activeCell){
                    e.preventDefault()
                    return screen.pasteValuesFromClipboard({columnIndex:scrollToColumn,rowIndex:scrollToRow,event:e})
                }
                
            }}
            >
            

                <ArrowNavigation
                    columnCount={props.columnCount}
                    isControlled={true}
                    disabled={!isSpreadsheet}
                    onKeyDown={onKeyDown}
                    rowCount={rowCount}
                    scrollToColumn={scrollToColumn}
                    scrollToRow={scrollToRow}
                >
                    {({ onSectionRendered, scrollToColumn, scrollToRow }) => (


                        <Grid
                            id="body_grid"
                            className="BodyGrid"
                            tabIndex={-1}
                            // columnWidth={props.columnWidth}
                            columnWidth={({index})=> {return props.columnWidth({index, width:props.width})}}
                            columnCount={props.columnCount}
                            height={props.height}
                            onScroll={props.onScroll}
                            overscanColumnCount={props.overscanColumnCount}
                            // noContentRenderer={() => { return (<div>{props.noData ? props.noData : ""}</div>) }}
                            overscanRowCount={props.overscanRowCount}
                            cellRenderer={renderBodyCell}
                            rowHeight={props.rowHeight}
                            rowCount={props.rowCount}
                            ref={props.gridRef}
                            width={props.width}
                            // added
                            onSectionRendered={onSectionRendered}
                            scrollToColumn={scrollToColumn}
                            scrollToRow={scrollToRow}
                            // activeCell={props.activeCell}
                            getLazyValues={props.getLazyValues}
                        />


                    )}

                </ArrowNavigation>


            {/*isSpreadsheet &&
                <div >
                    <IconButton color="transparent" onClick={(e) => {
                    // if (props.active_record.save() === false) {
                    //     return;
                    // }
                    dispatch({ type: 'SET_ACTIVE_CELL', payload: { scrollToRow: props.rowCount, scrollToColumn: 1, activeCell: createKey(props.rowCount + 1, 1) } })
                    createRow()


                }}>
                        <AddSolid style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                    </IconButton>
                </div>
            */}
        </div>
    )
})

export default SpreadsheetBody