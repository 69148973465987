import React from 'react';

import { observer } from 'mobx-react-lite'

const HeaderSelector = observer(function HeaderSelector(props) {

    const { screen } = props;
    const checked = screen.all_records_selected

    return (
        <div className={"leftCheckbox"}>
            
            <input
                type="checkbox"
                checked={checked}
                style={{ padding: 0 }}
                onChange={(e)=>{screen.toogleSelectAll(e.target.checked)}}

            />
        </div>

    )


});

export default HeaderSelector;