import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'
import Filter from './Filter'

const QuickFilter = observer(function QuickFilter(props) {
    const { filter, screen } = props;
    const record = screen.filters.record

    return (
        <React.Fragment key={filter.field.id}>

            {/* <div className="w-1/3"> */}
                <Filter
                    filter={filter}
                    removeFilter={() => { }}
                    record={record}
                    // containerStyle={{ 'padding': 0 }}
                    fieldWrapperStyle={{paddingLeft:0}}

                />
            {/* </div> */}


        </React.Fragment>



    )

});

export default QuickFilter;