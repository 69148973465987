import React, {Fragment} from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
import { ReactComponent as Dots } from '../../ui/icons/svg/navigation-more.svg'
import Field from './Field.js';
import { observer } from 'mobx-react-lite'

// const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal"



const DynamicOne2Many = observer(function DynamicOne2Many(props) {
    const { field, state_attrs, record } = props;
    const childs = field.childs
    let cls = field.grid_cls



    return (
        <React.Fragment>
        {childs.length ?
         <React.Fragment>
            {childs.map((f, i) => (
                
                <div style={{ display: state_attrs.invisible ? 'none' : '' }} key={f.name} className={cls}>
                        <Field
                            field={f}
                            record={record}
                            variant="outlined"
                            parent_state_attrs={state_attrs}
                            show_tooltip
                        />
                        
                </div>
                
    
            ))}
        </React.Fragment>
        :
        <React.Fragment></React.Fragment>
        }
       
    </React.Fragment>




    )

});

export default DynamicOne2Many;