import React, { useState, useEffect } from "react";

function Badge(props) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (props.visibility !== isVisible) {
      setIsVisible(props.visibility);
    }
  });

  return (
    <span
      className={(isVisible
        ? "visible opacity-100 transition duration-300 "
        : "invisible opacity-0 w-0 "
      ).concat(
        `badge bg-${props.color} ${
          props.color === "warning"
            ? "bg-opacity-100"
            : props.color === "secondary"
            ? "bg-opacity-20"
            : "bg-opacity-15"
        } text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold text-${
          props.color
        } rounded ${props.className}`
      )}
    >
      {props.title}
    </span>
  );
}

export default Badge;
