import React, { useReducer, useEffect, useState, Fragment } from 'react';
import Container from '../../ui/surfaces/Container.js'
import LineGraph from './LineGraph.js';
import BarGraph from './BarGraph.js';
import PieGraph from './PieGraph.js';
import ViewHeader from '../ViewHeader.js';
import Field from '../../ui/fields/Field';
import DropDown from '../../ui/DropDown'
import { View_ } from '../View'
import IconButton from '../../ui/buttons/IconButton'
import { ReactComponent as ActionIcon } from '../../ui/icons/svg/action.svg';
import Paper from '../../ui/surfaces/Paper.js';
import { observer } from 'mobx-react-lite'


const GraphToolbar = observer(function GraphView(props) {
  const { screen } = props;
  const [open, setOpen] = useState(false)
  const ref = React.createRef();

  function toogleData() {
    setOpen(false)
    screen.switch_view()
  }


  return (
    <div ref={ref}>
      <IconButton onClick={(e) => { setOpen(!open) }} color="transparent">
        <ActionIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
      </IconButton>
      <DropDown
        open={open}
        ref={ref}
        modifiers={{
          'left': -200,
          'width': 200,
          'height': 200,

        }}
      >
        <Paper>
          <div onClick={toogleData} className="w-full border border-gray-300 px-2 py-2 font-sm cursor-pointer">Show Data</div>
        </Paper>

      </DropDown>
    </div>
  )



});

const GraphView = observer(function GraphView(props) {
  const { screen, container } = props
  const graph = screen.active_graph
  const { show_data, data_screen } = screen




  function getGraph() {
    if (graph.type === 'bar') {
      return <BarGraph graph={graph} />
    }
    else if (graph.type === 'pie') {
      return <PieGraph graph={graph} height={props.height} />
    }
    else if (graph.type === 'line') {
      return <LineGraph graph={graph} height={props.height} />
    }
  }


  return (
    <>
      <ViewHeader
        screen={screen}
        isSpreadsheet={false}
        hide_toolbar={props.hide_toolbar}
        extra_action_menu={<GraphToolbar screen={screen} />}
        container_classname={props.height ? false:"rounded bg-white border border-gray-300"}
      />
      <Container
        type={container ? container : 'paper'}
        style={{ height: props.height ? props.height : window.innerHeight * 0.8 }}
        className="px-2 py-2 pb-4 border overflow-hidden">




        <div className="relative flex flex-col" style={{ height: '100%', width: '100%' }}>

          {
            show_data ?
              <>
                <View_
                  screen={data_screen}
                  height={props.height ? props.height : window.innerHeight * 0.8}
                  headless={true}
                />
              </>
              :
              <>
                {getGraph()}
              </>

          }



        </div>







      </Container>
    </>



  )

});

export default GraphView;