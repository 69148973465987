export class FieldsHandler {

    constructor(db, connection){
      this.db = db
      this.connection = connection
  
    }

    async getFieldById(id){
        return await this.db.getDocument('fields:'+id)
    }

    async getExtraViews(view_definition, args){
        const pivot_values = args['pivot_values']
        if(!Object.keys(pivot_values).length){

            const active_record = await this.db.pool.dispatch(args.model, 'get_data', args.search)
            if(active_record && active_record.length){
                const template_view_ids = active_record[0][view_definition.extended_models_fname]
                return template_view_ids
            }
            

        }

        return []
    }


    async getFields(args) {
        const view_id = args['view_id']
        const pivot_values = args['pivot_values']
        let extra_views = false
        let view_definition = await this.db.local_db.get('views:'+view_id)
        if(view_definition.multi_model_view){
           extra_views = await this.getExtraViews(view_definition, args)

        }
        if(extra_views && extra_views.length){
            let fields_id = 'multi_model_fields:'+view_id.toString() + '_' + extra_views.join('_')
            let fields = await this.db.getDocument(fields_id)
            if(fields){
                return fields.fields
            }

        }
        
        let view_fields = await this.db.local_db.query('fields/by_view',{
        //   key: view_id,
        startkey:[view_id],
        endkey:[view_id, {}]
        
        })
        
        let fields = await Promise.all(view_fields.rows.map(function(field){
          return this.db.getDocument(field.id)
        }.bind(this)))

        return fields
    
      }
      async getFieldType(fname, view=false){
        if(!view){
          view = this.master_view
        }
        let view_field = await this.db.local_db.query('fields/field_by_name',{
          key: [view, fname],
        
        })
        const type = view_field.rows.length ? view_field.rows[0].value:false
        return type

      }

      async getFieldOptions(args){
        let options = []
        const field = await this.getFieldById(args.id)
        const target_model = field.target_model
        if(target_model){
            const domain_str = field.domain
            const search = args.search
            let domain = false
            if(domain_str){
                domain = {
                    values: search ? search:[],
                    expr: domain_str ? domain_str:""
                }
            }
            
            let fnames = ['id', 'rec_name']
            if(field.read_fields){

                fnames = [...fnames, ...field.read_fields.split(',')]
            }
            const data = await this.db.pool.dispatch(target_model, 'search', {domain, fnames})
            options = data.docs
            
        }
        return options
      }
    
    
  
  
  
  
  }