import React, { useContext } from 'react';

import { NotificationContext } from './NotificationContext'
import { NotificationManager } from './NotificationManager'
import { NotificationArea } from './NotificationArea'
export function NotificationProvider({ children }) {


  return (
    <NotificationContext.Provider value={new NotificationManager()}>
      <NotificationArea />
      {children}
    </NotificationContext.Provider>
  )

}
