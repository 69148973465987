import React, { useState } from 'react';
import { observer } from 'mobx-react-lite'
import { ReactComponent as ChevronDown } from '../../ui/icons/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../ui/icons/svg/chevron-up.svg';
import Draggable from 'react-draggable';
import { Grid } from 'react-virtualized';
import Tooltip from '../../ui/Tooltip';
import {get_field_align} from './alignment'
import QuickFilter from '../../ui/fields/QuickFilter'

const SpreadsheetHeader = observer(function SpreadsheetHeader(props) {

    const screen = props.screen
    const quick_filters = screen.filters ? screen.filters.quick_filters.length:false
    const orderedColumn = screen.ordered_column
    const columnCount = screen.visible_fields.length;
    const width = props.width

    const onColumnClick = (e, column_name) => {
        //DISPATCH ORDER TO SCREEN
        screen.set_order(column_name)

    }
    function renderHeaderCell({ columnIndex, key, rowIndex, style }) {

        if (columnIndex < 1) {
            return;
        }

        let column = screen.visible_fields[columnIndex]
        const sortable = column.sortable
        let containerClass = "headerCell "
        const contentClassName = 'headerCellContent ' + 'justify-'.concat(get_field_align(column.type))
        const filter = screen.filters ? screen.filters.get_quick_filter_fname(column.name):false

        return (
            <div className={containerClass} key={key} style={style}>
                <div className={contentClassName} style={{ cursor: sortable ? "pointer" : "" }}>
                    <span 
                        onClick={sortable ? function (e) { onColumnClick(e, column.name) } : () => { }} 
                        onContextMenu={function(e){
                            
                            if(column.edit_field()){
                                e.preventDefault()
                                e.stopPropagation()
                            }
                        }}
                        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {column.tooltip ?
                            <Tooltip content={column.tooltip} type={column.tooltip_type}>{column.description}</Tooltip>
                            :
                            column.description
                        }
                    </span>
                    {sortable &&
                        <React.Fragment>
                            {orderedColumn.name === column.name &&
                                <React.Fragment>
                                    {orderedColumn.order === 'DESC' ?
                                        <ChevronDown style={{ width: '1em', height: '1em' }} className="fill-current text-xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" /> : <ChevronUp style={{ width: '1em', height: '1em' }} className="fill-current text-xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" />
                                        //   <FontAwesomeIcon style={{ paddingLeft: '2px' }} icon={faAngleDoubleDown} /> : <FontAwesomeIcon style={{ paddingLeft: '2px' }} icon={faAngleDoubleUp} />
                                    }
                                </React.Fragment>

                            }

                        </React.Fragment>
                    }
                    <Draggable
                        axis='x'
                        defaultClassName='DragHandle'
                        defaultClassNameDragging='DragHandleActive'

                        onStop={(event, data) => props.onResizeStop({

                            columnIndex: columnIndex,

                        })}

                        onDrag={(event, data) => props.resizeColumn({
                            deltaX: data.x,
                            columnIndex: columnIndex,
                            lastX: data.lastX,
                            width:width
                        })}
                        position={{
                            x: 0,
                            y: 0
                        }}
                        zIndex={999}
                    >
                        <div className='DragHandleIcon'>

                            ||
                  </div>
                    </Draggable>
                        
                </div>
                {quick_filters ?
                    <div className="flex pt-2" style={{height:'40px'}}>
                            {filter ?
                            <div className="flex w-full">
                                <QuickFilter filter={filter} screen={screen}/>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                            }
                            
                    </div>
                    
                    :
                    <React.Fragment></React.Fragment>
                }
                


            </div>
        )

    }
    return (
        <Grid
            className="HeaderGrid"
            // columnWidth={({index}))props.columnWidth}
            columnWidth={({index})=> {return props.columnWidth({index, width})}}
            columnCount={columnCount}
            height={props.rowHeight}
            overscanColumnCount={0}
            cellRenderer={renderHeaderCell}
            rowHeight={props.rowHeight}
            rowCount={1}
            scrollLeft={props.scrollLeft}
            ref={props.gridHeaderRef}
            // width={width - scrollbarSize()}
            width={process.env.NODE_ENV === 'test' ? 900 : props.width}
        />

    )
})

export default SpreadsheetHeader