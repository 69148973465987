import React, { useCallback } from 'react';
import { Handle, Position } from 'reactflow';

const handleStyle = { left: 10 };

function ComplementaryNode({ data }) {
  
  return (
    <>
      <div style={{maxWidth:100}}className="border border-gray-300 px-2 py-2 text-xxxs text-default-text font-medium">
        <label htmlFor="text">{data.label}</label>
      </div>
      <Handle type="source" position={Position.Bottom} id="a"/>
    </>
  );
}
export default ComplementaryNode