import { environment, parse_domain, domain_to_object, castValue } from '../common'
import { v4 as uuidv4 } from 'uuid';

export class Pool {
  constructor(db, connection){
    this.models = {}
    this.db = db
    this.connection = connection

  }

  initialize(models){
    models.forEach(function(model){
      this.models[model.model] = new Model(this, model.model, model.view)
    }.bind(this))
    // Create index to speed up the basic model search
    this.db.local_db.createIndex(
      {
        index: {
            fields: ['model_', 'id']
        }
      }
      )
  }

  get(model_name){
    
    if(this.models[model_name]){
      return this.models[model_name]
    }
    else{
      throw new Error("The Model "+model_name+" is not defined or not available for offline use")
    }
  }

  async dispatch(model, method, args){
    
    return await this.get(model)[method](args)
    
  }

  async populateModels(){
    let prms = []
    for(let name in this.models){
      prms.push(this.models[name].loadRecords())
    }
    return await Promise.all(prms)

  }

  async getUpdatedRecords(ids_by_view){
    
    let prms = []
    
    for(let view_id in ids_by_view){
      const ids = ids_by_view[view_id]
      // Get model to update based on view_id
      const route = this.connection.offline_routes.find(r => r.id == view_id)
      if(!route){
          continue
      }
      if(!this.models[route.model]){
        continue
      }
      
      
      prms.push(this.models[route.model].getUpdatedRecords(ids))
  }
  return await Promise.all(prms)
  }

}

export class Model {
  db = false

  constructor(pool, name, master_view_id) {
    this.pool = pool
    this.name = name
    this.master_view = master_view_id
    this.local_db = this.pool.db.local_db
  }

  
  async create_selector(pair, view=false){
    let selector = {}
    let expr = {}
    const operators = {
      '=':'$eq',
      'in':'$in'
    }
    // Assume the fields in master_view cover all possibilities. 
    // Even if the same field has different "widget", the data type should be the same
    // Cast the value by field_type
    if(!view){
      view = this.master_view
    }
    let field_type = await this.pool.db.fields_handler.getFieldType(pair[0], view)
    
    let value = castValue(field_type, pair[2], pair[1])

    expr[operators[pair[1]]] = value
    selector[pair[0]] = expr
    return selector


  }

  async domain_to_selector(domain, view=false){
    let selector = {}
    
    let prms = domain.map(function(pair){
      return this.create_selector(pair, view)
    }.bind(this))
    const selectors = await Promise.all(prms)
    selectors.forEach(function(sel){
      selector = {...sel, ...selector}
    })
  return selector
  }

  eval_domain(values, domain){
    values = domain_to_object(values)
    return parse_domain(values, domain)
  }
  async eval_data_domain(args){
    let action_domain = []
    let search_domain = []
    if(args.action_id){
      let action_doc_id = ('actions:'+args.action_id).toString()
      let action = await this.pool.db.getDocument(action_doc_id)
      if(action.target_view_domain){
        // const target_view_domain = action.target_view_domain.split('"').join("").split("'").join('')
        const target_view_domain = action.target_view_domain.replace(/"/g, "").replace(/'/g, "")
        // let domain_values = domain_to_object(args.action_params)
        // action_domain = parse_domain(domain_values, target_view_domain)
        action_domain = this.eval_domain(args.action_params, target_view_domain)

      }
      
    }
    if(args.search && args.search.length){
      search_domain = args.search
    }
    return [...action_domain, ...search_domain]

  }

  // Domain: {values:[], expr:str}
  async search({search_selector, domain, fnames}){
    let selector = {
      
      model_: { $eq: this.name},
    }
    if(search_selector){
      selector = {...search_selector, ...selector}
    }
    if(domain){
      
      const domain_selector = await this.domain_to_selector(this.eval_domain(domain.values, domain.expr))
      
      selector = {...domain_selector, ...selector}
    }
    
    return await this.pool.db.executeQuery({selector, fields:fnames})


  }
  async read(){

  }

  async write(){

  }

  async delete(docs){
    let delete_prms = docs.map(function (doc) {
      return this.local_db.remove(doc)
    }.bind(this))
    let deleted = await Promise.all(delete_prms)
    return deleted
  }

  get_record_id(id) {
    return this.name + ':' + id
  }

  async save(args) {
    let res = {}
    let values = args.values
    let pending_transaction = args.pending_transaction
    // Compose record documents for localDB
    let prms = []
    for (let rec in values) {
      const doc_id = this.get_record_id(values[rec].id)
      values[rec]['no_sync_'] = true
      values[rec]['model_'] = args.model
      prms.push(this.pool.db.composeDocument(doc_id, values[rec]))

    }
    let new_docs = await Promise.all(prms)
    
    if (pending_transaction) {

      new_docs.forEach(function (doc) {
        // If the record has no _rev, means its been created, set the original 
        // transaction to be used by future transactions on same doc
        if (!doc._rev) {
          doc["origin_transaction"] = pending_transaction.id
        }
        if (doc.related_transactions) {
          doc.related_transactions.push(pending_transaction.id)
        }
        else {
          doc.related_transactions = [pending_transaction.id]
        }
      })

    }
    const new_records = await this.local_db.bulkDocs(new_docs)

    // Read final result from localDB
    let new_values = await Promise.all(new_records.map(function (rec) {
      return this.pool.db.getDocument(rec.id)
    }.bind(this)))

    // Convert list of docs to object with id:values
    new_values.forEach(function (doc) {
      res[doc.id] = doc
    })

    return res
  }
  
  async get_data(args){
    const view = args.view_id
    // let selector = {
    //   model_: { $eq: this.name},
    //   // id: { $eq: "^" + this.name + ":" }
      
    // }
    
    const domain = await this.eval_data_domain(args)
    const search_selector = await this.domain_to_selector(domain, view)
    // selector = {...selector, ...search_selector}
    // const data = await this.pool.db.executeQuery({selector})
    const data = await this.search({search_selector})

    // TODO: review this to count from the db instead of the length
    if (args.count) {
      return data['docs'].length
    }

    return data.docs
  }
  
  async bulk_write(data) {
    let ids = []
    let prms = data.map(function (rec) {
      const id = this.name + ':' + rec.id.toString()
      rec['no_sync_'] = true
      rec['model_'] = this.name
      ids.push(id)
      return this.pool.db.composeDocument(id, rec)
    }.bind(this))
    const docs = await Promise.all(prms)
    await this.local_db.bulkDocs(docs)
    return ids
    
  }

  // Return all current docs in Database for this model(only _id and _rev)
  async getCurrentDocs(){
    const current_records = await this.search({fnames:['_id', '_rev']})
    return current_records.docs
  }

  // Load Records from backend
  async loadRecords(){
    const args = {
      view_id:this.master_view,
      search:[],
      order:[],
      model: this.name,
      offset:0

  }
  const abortController = new AbortController();
  const current_ids = await this.getCurrentDocs()
  let data = await this.pool.connection.dispatch('GET', '/data/get', args, false, false, false, abortController)
  const new_ids = await this.bulk_write(data)

  // Delete records wich are not on the domain anymore (deleted on backend or out of domain)
  const ids_to_delete = current_ids.filter(function(id){return !new_ids.includes(id._id)})
  await this.delete(ids_to_delete)
  return this.name
  
  
  }
  // Update specific records from backend (ids)
  async getUpdatedRecords(ids){
    const search = [['id','in',ids]]
    let args = {
      'view_id': this.master_view,
      'model':this.name,
      'search': search

    }
    const abortController = new AbortController();
    let data = await this.pool.connection.dispatch('GET', '/data/get', args, false, false, false, abortController)
    this.bulk_write(data)
  }
  
  
}

  