import { makeAutoObservable, autorun, runInAction, computed, action, observable } from "mobx"

import { operators, typeOperators, typeMapping, getDefaultOperator, getOperator, BOOLEAN_OPTIONS } from '../search.js'
import { Record } from "./Record";
import { createField } from "./Field"
//Native Web Compatibility
// import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

const DEBOUNCED_TYPES = [
    'char',
    'text',
    'float',
    'integer',
]
export class Filters {
    type = false
    active_filters = []
    record = ""
    quick_filter_timeout = false

    get fields() {
        return this.screen.filterable_fields
    }
    get required_filter_fields(){
        return this.active_filters.filter(function(f){return f.required===true}).map(function(filter){return filter.field})
    }
    get some_applied(){
        return this.active_filters.some(f => (f.field && f.field.has_value(this.record) === true))
    }
    get applied_qty(){
        return this.active_filters.filter(f => (f.field && f.field.has_value(this.record) === true)).length
    }
    get applied_toolbar_qty(){
        return this.active_filters.filter(f => (f.field && f.field.has_value(this.record) === true && !f.quick_filter)).length
    }
    get quick_filters(){
        return this.active_filters.filter(function(f){return f.quick_filter})
    }
    get quick_filters_names(){
        return this.quick_filters.map(function(filter){return filter.field_name})
    }

    constructor(attributes, screen) {
        
        this.type = false
        this.screen = screen
        this.active_filters = []
        // this.record = new Record({ id: -1, on_change_callback:this.on_record_change }, screen)
        this.record = this.create_record()
        // this.record.set_on_change_callback(this.on_record_change)
        
        makeAutoObservable(this, {
            fields: computed,
            addFilter: action,
            removeFilter: action,
            setInitialFilters: action,
            clean: action,
            record:observable,
            screen:observable
            
        })
        

    }
    create_record(){
        return new Record({ id: -1, on_change_callback:(record, fnames) => {this.on_record_change(record, fnames)} }, this.screen)
    }
    apply_quick_filters(record, fnames){
        let filter = this.quick_filters.find(f => f.id == fnames[0])
        if(filter){
            if(DEBOUNCED_TYPES.includes(filter.field.type)){
                if(this.quick_filter_timeout){
                    clearTimeout(this.quick_filter_timeout)
                }
                this.quick_filter_timeout = setTimeout(()=>{this.search()}, filter.debounce_time);
            }
            else{
                this.search()
            }
            
            
        }
        
    }
    on_record_change(record, fnames){
        this.apply_quick_filters(record, fnames)

    }
    getDefaultFilters(){
        const filters = []
        this.screen.default_filters.forEach(function(filter){
            
            let attributes = {}
            attributes['field'] =  this.fields.find(f => f.id === filter.field)
            attributes['operator'] = getOperator(filter.operator)
            attributes['required'] = filter.required
            attributes['readonly_field'] = filter.readonly_field
            attributes['readonly_operator'] = filter.readonly_operator
            attributes['hide_modifiers'] = filter.hide_modifiers
            attributes['quick_filter'] = filter.quick_filter
            attributes['is_default'] = true
            attributes['debounce_time'] = filter.debounce_time
            const new_filter = new Filter(attributes, this)
            filters.push(new_filter)
            
        }.bind(this))
        return filters
    }

    setInitialFilters() {
        const filters = this.getDefaultFilters()
        this.clean()
        // const default_applied = []
        console.log("The search on filters ?")
        console.log(this.screen.current_search)
        this.screen.current_search.forEach(function (f) {
            const attributes = {}


            // find default filter for same field & operator
            const default_filter = filters.find(filter => (
                                                filter.field_name === f[0] && 
                                                filter.is_default && 
                                                filter.operator.value == f[1]))
            // Apply value to default filter
            if(default_filter){
                default_filter.field.set_value(f[2], this.record)
                // default_applied.push(f[0]) 

            }
            // Create new filter
            else{
                attributes.field = this.fields.find(field => field.name === f[0])
                if(attributes.field && attributes.field.searchable){
                    attributes.operator = getOperator(f[1])
                    const filter = new Filter(attributes, this)
                    filter.field.set_value(f[2], this.record)
                    filters.push(filter)
                }
                
                
            }
            

        }.bind(this))
        this.active_filters = filters;
        
        this.active_filters.forEach(function(filter){
            if(filter.field){
                filter.field.extend_value(this.record)
            }
            
        }.bind(this))
        
    }

   

    search(navigate=true) {
        if(this.required_filter_fields.length){
            if(!this.record.validate(this.required_filter_fields)){
                return false
            }
        }
        
        const current_search = []
        const search = this.screen.search_state
        this.active_filters.forEach(function (filter) {
            const val = this.record._values[filter.field.name]
            if(val){
                current_search.push([filter.field_name, filter.operator.value, this.record._values[filter.field.name]])
            }
            

        }.bind(this))
        search['search'] = current_search

        if(navigate && this.screen.navigate){
            if(this.screen.pivot_table){
                this.screen.loadFields(search)
            }
            let url = this.screen.connection.createUrl(search)
            this.screen.navigate(url)
        }
        else{
            search['current_search'] = current_search
            this.screen.do_search(search)
        }
        return true
        

    }
    addFilter() {
        const filter = {}
        filter['field_options'] = this.fields.map(function (f) { return f })
        filter['field'] = ''
        filter['operator'] = ''
        filter['value'] = ''


        this.active_filters.push(new Filter(filter, this))
    }
    removeFilter(filter) {

        const removed = this.active_filters.splice(this.active_filters.findIndex(rec => rec.id === filter.id), 1);
        removed.forEach(function (f) {
            delete this.record._values[f.field.name]
            delete this.record._changed[f.field.name]
        }.bind(this))

    }
    clean() {
        this.active_filters = []
        this.record = this.create_record()
    }

    get_filter_by_id(id){
        return this.active_filters.find(f=>f.id === id)
    }
     /**
* Get filter id from original field name. If duplicated fnames, the last one will be returned.
* @param {string} fname - Original field name to be searched
* @return {Filter} Filter instance
*/
    get_filter_fname(fname){
        return this.active_filters.find(f=>f.field_name === fname)
        
    }

         /**
* Get quick filter id from original field name. If duplicated fnames, the last one will be returned.
* @param {string} fname - Original field name to be searched
* @return {Filter} Filter instance
*/
get_quick_filter_fname(fname){
    return this.active_filters.find(f=>(f.field_name === fname && f.quick_filter==true))
    
}


}

export class Filter {

    field_options = ""
    field = ""
    field_name = ""
    operator = ""
    value = ""
    id = ""
    filter_group = ""
    required = false
    is_default = false
    debounce_time = 500

    get operator_options() {
        if (!this.field) {
            return []
        }
        return operators.filter(function (op) {
            return typeOperators[typeMapping[this.field.type]].includes(op.value)
        }.bind(this))
    }
    get is_field_readonly(){
        return [this.required, this.readonly_field].includes(true)
        
    }
    get is_operator_readonly(){
        return [this.required, this.readonly_operator].includes(true)
        
    }

    constructor(attributes, filter_group) {
        makeAutoObservable(this, {
            setField: action,
            setOperator: action
        })
        this.field_options = attributes.field_options
        this.operator = attributes.operator
        this.required = attributes.required || false
        this.value = attributes.value
        this.readonly_field = attributes.readonly_field || false
        this.readonly_operator = attributes.readonly_operator || false
        this.hide_modifiers = attributes.hide_modifiers || false
        this.is_default = attributes.is_default || false
        this.quick_filter = attributes.quick_filter || false
        this.debounce_time = attributes.debounce_time || 500
        this.id = uuidv4()
        this.filter_group = filter_group
        if (attributes.field) {
            this.createFilterField(attributes.field)
        }

    }

    setOperator(value) {
        this.operator = value
    }

    getFieldType(field) {
        let type = field.type


        if (field.char_search) {
            type = 'char'
        }
        else {
            switch (type) {
                case 'selection': return 'multi_selection'
                case 'many2one': return 'multi_link'
                case 'boolean': return 'selection'
                default: return type
            }
        }


        return type

    }

    createFilterField(field) {
        const type = this.getFieldType(field)
        const new_attributes = { ...field.attributes }
        new_attributes.type = type
        new_attributes.name = this.id
        new_attributes.readonly = false
        new_attributes.required = this.required
        new_attributes.filter = this;
        this.field_name = field.name
        this.field = createField(new_attributes, this.filter_group.screen)
        // if(this.quick_filter){
        //     this.field.on_change = ({value, record}) => {
        //         return this.filter_group.search()
        //     }
        // }
        

    }

    setField(field) {

        if(this.field){
            this.field.set_value("",this.filter_group.record)
        }
        
        this.createFilterField(field)
        
        this.setOperator(getOperator(getDefaultOperator(field.type)))
        


    }
}
