import React, {useContext} from 'react'
import { observer } from 'mobx-react-lite'
import { SessionContext } from './connection/SessionContext'
import { AppContext } from './model/context/AppContext';

export const InjectContext = observer(function InjectContext(props) {
    // function Field(props) {
    console.log("PROPS ON INJECT CONTEXT")
    console.log(props)
    window.portal_bridge = {}
    window.portal_bridge.connection = useContext(SessionContext)
    window.portal_bridge.app = useContext(AppContext)
    if(props.token){
        window.portal_bridge.connection.setSession(props).then(function(ses){
            console.log("I HAVE THE SESSION")
            console.log("setting router")
            window.portal_bridge.connection.setRouter({'routes':window.portal_bridge.connection.routes}) 
        })
        // 
    }
    
    return (
        <React.Fragment>
           
        </React.Fragment>



    )

});
