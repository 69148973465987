// import * as PouchDB from 'pouchdb';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find'
import { environment, parse_domain, domain_to_object } from '../common'
import { v4 as uuidv4 } from 'uuid';
import { Pool } from './Model'
import { FieldsHandler } from './FieldsHandler';
import { makeAutoObservable, autorun, runInAction, computed, action, observable } from "mobx"
// TODO: Migrate Transactions to his own class

export class Database {
  remote_db_name = ""
  local_db_name = ""
  remote_url = ""
  local_db = false
  remote_db = false
  connection = false
  token = ""

  // status by sync: 'in_sync', 'synced', 'error'
  get is_sync_active(){
    return [this.status.structure, 
            this.status.transactions, 
            this.status.records]
            .some(item => item === 'syncing')
  }


  constructor(connection, config, load_db_handler = false) {
    this.connection = connection;
    this.token = config.token
    this.remote_db_name = config.database_name
    this.local_db_name = 'local_'.concat(this.remote_db_name)
    this.remote_url = this.connection.getBasePath() + '/sync/' + this.remote_db_name
    this.pool = new Pool(this, connection)
    this.fields_handler = new FieldsHandler(this, connection)
    this.allowed_log_values = [
      'struct_last_sync',
      'transaction_last_sync',
      'records_last_sync',
      'full_synced'
    ]
    this.status = {
      'structure':false,
      'transactions':false,
      'records':false
    }
    this.last_sync = '-'
    this.pending_transaction_qty = 0
    
   
    makeAutoObservable(this, {
      status:observable,
      setStatus:action,
      last_sync:observable,
      setLastSync:action,
      pending_transaction_qty:observable,
      updatePendingTransactionQty:action
  })


    // this.pool.initialize()
    if (load_db_handler) {
      this.db_handler = load_db_handler()
      this.db_adapter = 'react-native-sqlite'
    }
    else {
      this.db_handler = PouchDB
      // this.db_handler.plugin(PouchDBFind)
    }
    this.db_handler.plugin(PouchDBFind)
    // this.data_handler = new DataHandler(this)

    

    this.path_getters = {
      '/app/v1/view/fields': async function (args, pending_transaction) { return await this.fields_handler.getFields(args) }.bind(this),
      '/app/v1/field/field_options': async function (args, pending_transaction) { return await this.fields_handler.getFieldOptions(args) }.bind(this),
      '/app/v1/view/actions': async function (args, pending_transaction) { return await this.getActions(args) }.bind(this),
      '/app/v1/data/get': async function (args, pending_transaction) { return await this.pool.dispatch(args.model, "get_data", args) }.bind(this),
      '/app/v1/user/context': async function (args, pending_transaction) { return await this.getUserContext() }.bind(this),
      '/app/v1/user/menu': async function (args, pending_transaction) { return await this.getRoutes() }.bind(this),
      '/app/v1/theme/host_theme':async function (args, pending_transaction) { return await this.getTheme() }.bind(this),
      // '/app/v1/data/save': async function (args, pending_transaction) { return this.saveRecord(args, pending_transaction) }.bind(this),
      '/app/v1/data/save': async function (args, pending_transaction) { return this.pool.dispatch(args.model,"save", {...args, pending_transaction}) }.bind(this),
      // TODO
      '/app/v1/view/default_values': async function (args, pending_transaction) { return [{}] }.bind(this),
      '/app/v1/view_handler/editor_definitions': async function (args, pending_transaction) { return {} }.bind(this),
      '/app/v1/view_handler/view_wizards':async function (args, pending_transaction) { return [] }.bind(this),
      '/app/v1/theme_logo':async function (args, pending_transaction) { return false }.bind(this)
      

    }


  }

  async initialize(){
    await this.create_local_db()
    await this.create_remote_db()
    await this.start_live_sync()
    await this.setLastSync()
    // Remove after this
    // this.getActions({'view_id': 105})
  }

  async initialize_pool(){
    this.pool.initialize(this.connection.offline_models)
    await this.populateModels()
    return new Date()

  }
  async populateModels(){
    const conn_status = await this.connection.checkConnection()
    if(conn_status){
      this.setStatus({'records':'syncing'})
      await this.pool.populateModels()
      this.setStatus({'records':'synced'})
      await this.registerSyncDate('records_last_sync')
      if(!this.is_sync_active){
        await this.registerSyncDate('full_synced')
      }



    }

  }


  // Manually Reload structure (one way), transactions (two ways) and records (one way)
  async resync(){
    await this.start_structure_sync(false, false)
    await this.start_transactions_sync(false, false)
    await this.initialize_pool()
    return await this.getLastSync()

  }

  // Last full sync keeped on memory for quick reference
  async setLastSync(date){
    date = date ? date:await this.getLastSync()
    runInAction(() => {
      
      this.last_sync = date
    })
  }

  async updatePendingTransactionQty(){
    const qty = await this.countNewTransactions()
    runInAction(() => {
      
      this.pending_transaction_qty = qty
    })
    return qty
    
  }

  // Save last sync date for each operation on local_db
  async getLastSync(){
    const last_sync = await this.getDocument('log:full_synced')
    return last_sync.full_synced
  }
  async registerLog(key, values){
    const doc_id = 'log:'+key
    const invalid_values = Object.keys(values).some(item => this.allowed_log_values.includes(item) === false)
    if(invalid_values){
      throw new Error('You are trying to register a wrong value on the DB LOG')
    }
    const log_doc = await this.composeDocument(doc_id, values, true)
    return await this.local_db.put(log_doc)
    

  }
  async registerSyncDate(key){
    let new_values = {}
    const sync_date = new Date().toString()
    new_values[key] = sync_date
    if(key == 'full_synced'){
      await this.setLastSync(sync_date)
    }
    return await this.registerLog(key, new_values)
  }

  setStatus(new_status){
    this.status = {...this.status, ...new_status}
  }
  

  async create_local_db() {
    if (this.db_adapter) {

      this.local_db = new this.db_handler(this.local_db_name, { adapter: this.db_adapter })
    }
    else {
      this.local_db = new this.db_handler(this.local_db_name)
    }
    const db_info = await this.local_db.info()
    return this.local_db 
  }

  async create_remote_db() {
    const headers = this.connection.getHeaders()
    
    this.remote_db = new this.db_handler(this.remote_url, {
      fetch: function (url, opts) {
        
        for(let key in headers){
          opts.headers.set(key, headers[key]);
        }
        opts.headers.set('X-Auth-CouchDB-Token', this.token)
        
        
        return PouchDB.fetch(url, opts);
      }.bind(this)
    })
    
    
    try{
      const db_info = await this.remote_db.info()
      
    }
    catch(e){
      
      return this.remote_db
    }
    
    return this.remote_db
  }

  async start_structure_sync(live=true, retry=true){
    let struct_opts = {
      live,
      retry,
      filter:'structure/get',
      query_params: {'role_id':this.connection.user_context.role}
    }

    return this.local_db.replicate.from(this.remote_db, struct_opts)
    .on('change', function (info) {
      this.setStatus({'structure':'syncing'})
      
    }.bind(this))
    .on('active', function (info) {
      
    })
    .on('paused', async function (err) {
      if(live){
        this.setStatus({'structure':'synced'})
      }
      
    }.bind(this))
    .on('complete', async function (err) {
      this.setStatus({'structure':'synced'})
      
      
    }.bind(this))
    .on('error', function (err) {
      
    });


  }
  async start_transactions_sync(live=true, retry=true){
    var transaction_opts = {
      live: live,
      retry: retry,
      filter:'pending_transactions_filter/by_user',
      query_params:{'user':this.connection.user_context.id},
      
      
    };
    return this.local_db.sync(this.remote_db, transaction_opts)
      .on('change', function (info) {
        if (info.direction == 'pull') {
          this.setStatus({'transactions':'syncing'})
          this.getDoneTransactions()
        }
      }.bind(this))
      // .on('active', function (info) {
      //   console.log("TRNSACT: Sync Active")
      //   console.log(info)
        
      // })
      .on('paused', async function (err) {
        if(live){
          this.setStatus({'transactions':'synced'})
          this.updatePendingTransactionQty()
        }
        
      }.bind(this))
      .on('complete', async function () {
        
        this.setStatus({'transactions':'synced'})
        this.updatePendingTransactionQty()
      }.bind(this))
      .on('error', function (err) {
        
      });

      
      
  }

  async start_live_sync() {

    let struct_opts = {
      live:false,
      retry:false,
      filter:'structure/get',
      query_params: {'role_id':this.connection.user_context.role}
    }

    // do one way, one-off sync from the server until completion
    this.local_db.replicate.from(this.remote_db, struct_opts).on('complete', function (info) {

      // Start one way continues for structure
      this.start_structure_sync()
      // start Transaction two ways sync
      this.start_transactions_sync()



    }.bind(this))
    .on('error', function (err) {
      // If the first pull fails, try to establish the retriable sync anyway
      // This is the case when the mobile app starts fully offline
      // Start one way continues for structure
      this.start_structure_sync()
      // start Transaction two ways sync
      this.start_transactions_sync()
    }.bind(this));

  }


  getPath(path) {

    if (path.startsWith('http')) {
      path = path.replace(this.connection.base_path, "")
    }
    return path
  }

  async getDocument(id) {
    let doc = {}
    try {
      doc = await this.local_db.get(id);

    } catch (err) {
      if (err.name === 'not_found') {
        doc = {}
      } else {
        throw err; // some error other than 404
      }
    }
    return doc
  }

  async composeDocument(id, values, no_sync=false) {
    let doc = await this.getDocument(id)
    if (!doc['_id']) {
      doc['_id'] = id
    }
    if(no_sync){
      doc['no_sync_'] = true
    }

    doc = { ...doc, ...values }

    return doc

  }


  async getActions(args) {
    let view_id = args['view_id']
    let view_actions = await this.local_db.query('actions/by_view',{
      key: view_id,
      // include_docs:true
    })
   
    let actions = await Promise.all(view_actions.rows.map(function(action){
      return this.getDocument(action.id)
    }.bind(this)))
  
    return actions
  }

  // selector:Selector to apply
  // fields: list of fnames to retrieve
  async executeQuery(options) {

    return await this.local_db.find(options)
  }

  get_record_id(model, id) {
    return model + ':' + id
  }


  async registerPendingTransaction({ type, path, args, headers }) {
    const transaction_values = args.values
    let dependencies = {}
    for (let rec in transaction_values) {
      const rec_id = transaction_values[rec].id
      if (rec_id < 0) {
        const doc_id = this.get_record_id(args.model, rec_id)
        const current_document = await this.getDocument(doc_id)
        // Negative ids are records wich only exist on local environment (new records).
        // If the document its created (but not synced), relate the original transaction to the new one
        // this way, could be proccesed on back with a consistent id
        if (current_document.origin_transaction) {
          dependencies[rec_id] = current_document.origin_transaction
        }
      }
    }
    let values = {
      '_id': 'pending_transactions:' + uuidv4(),
      'status': 'new',
      'user': this.connection.user_context.id,
      'created_at': new Date().toUTCString(),
      'doc_type': 'pending_transaction',
      headers,
      type,
      path,
      args,
      dependencies
    }
    let transaction = await this.local_db.put(values)
    // Update local counter
    await this.updatePendingTransactionQty()

    return transaction


  }

  getUserContextId(){
    return 'user:context'
  }

  async setUserContext(user_context) {
    // let id = 'user:'+user_context.id+'_user_context'
    let id = this.getUserContextId()
    let doc = await this.composeDocument(id, user_context, true)
    return await this.local_db.put(doc)
  }

  async getUserContext(){
    return await this.getDocument(this.getUserContextId())
  }

  async getRoutes(){
    const id = 'roles:'+this.connection.user_context.role
    let routes_doc = await this.getDocument(id)
    // return await this.getDocument(id)
    return routes_doc
  }

  async getTheme(){
    const selector = {
      selector: {
        type: { $eq: "theme" },
        is_default:{$eq: true}
      },
      // sort: ['id']

    }
    let theme = {}
    const theme_query = await this.executeQuery(selector)
    
    if(theme_query.docs.length){
      theme = theme_query.docs[0]
    }

    return theme
  }

  async countNewTransactions(){
    const new_transactions = await this.local_db.query('pending_transactions/new')
    return new_transactions.total_rows
  }

  async getRelatedRecords(transaction_ids) {
    // Get all records wich all of his related_transactions are on transaction_ids and ids are negative
    function map(doc, emit) {
      const included = (currentValue) => transaction_ids.includes(currentValue);
      // if(doc.id > 0){
      //   return
      // }
      if (!doc.related_transactions || !doc.related_transactions.length) {
        return
      }
      if (doc.related_transactions.every(included)) {
        emit(doc.id, { '_id': doc._id, '_rev': doc._rev })
      }
    }
    const related_records = await this.local_db.query({ 'map': map })
    return related_records
  }

  

  async getDoneTransactions() {

    const done_transactions = await this.local_db.query('pending_transactions/done_ids')
    let records_to_reload = {}
    let records_to_delete = []

    if (done_transactions.total_rows === 0) {
      return
    }
    // transaction_ids: Assemble a list of done transactions ids
    // records_to_reload: object with view_id:[ids], records to be reloaded by view
    const transaction_ids = done_transactions.rows.map(function (transaction) {
      const update_records = transaction.value.update_records
      if (update_records) {
        let view_id = update_records.view
        if (records_to_reload[view_id]) {
          records_to_reload[view_id] = [...records_to_reload[view_id], ...update_records.ids]
        }
        else {
          records_to_reload[view_id] = update_records.ids
        }
      }
      return transaction.id
    })

    // related_records: Get all records wich all of his related_transactions are done and ids are negative
    const related_records = await this.getRelatedRecords(transaction_ids)
    // Add done and negative records to be deleted
    records_to_delete = related_records.rows.map(function (rec) {
      return rec.value
    })

    // Delete the temporary Records on the local db
    let delete_prms = records_to_delete.map(function (doc) {
      return this.local_db.remove(doc)
    }.bind(this))
    let deleted = await Promise.all(delete_prms)

    // Update the records involved in the done transaction
    // this.pool.updateOfflineData(records_to_reload)
    this.pool.getUpdatedRecords(records_to_reload)

    // Check if all records related to the transactions are deleted before delete the transaction
    let pending_records = await this.getRelatedRecords(transaction_ids)
    // Any record is attached to any of the done transactions, so its safe to delete them
    if (pending_records.total_rows === 0) {
      let deleted_transactions = done_transactions.rows.map(function (transaction) {
        transaction.value['_deleted'] = true
        return transaction.value
      })
      this.local_db.bulkDocs(deleted_transactions)

    }
    // Flag done transactions as deleted




  }

  async dispatch({ type, path, args, raw_response, cache_write, blob, headers, pending_transaction = true }) {

    path = this.getPath(path)

    const original_args = { ...args }


    if (this.path_getters[path]) {
      let res = []
      if (type === 'POST' && pending_transaction) {
        const new_transaction = await this.registerPendingTransaction({ type, path, args, headers })
        res = await this.path_getters[path](original_args, new_transaction)
      }
      else {
        res = await this.path_getters[path](original_args, pending_transaction)
      }



      return res

    }
    else {
      // throw new Error("Calling not mapped method"+path)
      return
    }

  }

  async mirrorAction({ type, path, original_args, res }) {
    // Executed on Online Mode: the values getted from back are updated on the local_db
    original_args.values = res

    return await this.dispatch({ type, path, args: original_args, pending_transaction: false })


  }

}
      