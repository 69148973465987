import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useStore, useNodes, getBezierPath, EdgeLabelRenderer } from 'reactflow';
import { getEdgeParams } from '../utils.js';
import { getSmartEdge } from '@tisoap/react-flow-smart-edge'

function FloatingEdge({ id, source, target, markerEnd, style, data }) {

    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
    const nodes = useNodes()
    const path_ref = useRef()
    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

    let path

    let [edgeResponse, labelX, labelY] = getBezierPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
    });

    const smartEdge = getSmartEdge({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
        nodes: nodes
    })

    if (smartEdge) {
        path = smartEdge.svgPathString
    }
    else {
        path = [edgeResponse]
    }

    return (
        <React.Fragment>
            <path
                id={id}
                className="react-flow__edge-path"
                d={path}
                strokeWidth={5}
                markerEnd={markerEnd}
                style={style}
                path_ref={path_ref}

            />
            {data.label &&
                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(0%, -50%) translate(${labelX}px,${labelY}px)`,
                            background: 'white',
                            border:'1px solid #b1b1b7',
                            padding: 5,
                            borderRadius: 5,
                            fontSize: 12,
                            fontWeight: 500,
                        }}
                        className="nodrag nopan"
                    >
                        {data.label}
                    </div>
                </EdgeLabelRenderer>
            }



        </React.Fragment>
    );
}

export default FloatingEdge;