import React, { useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import clsx from 'clsx';

function BaseNode({ data }) {
    let cls = "border border-gray-300 px-2 py-2 text-xxxs font-medium cursor-pointer hover:bg-light-selected hover:text-primary"
    const is_selected = data.record.screen ? data.record.screen.is_selected(data.record.id):false
    if(is_selected){
        cls = clsx(cls, "bg-light-secondary-selected text-secondary")
    }
    else{
        cls = clsx(cls, "text-default-text")
    }
    if(data.internal_type =='complementary'){
        cls = clsx(cls, "border-secondary")
    }
    

    return (
        <React.Fragment>
            
            <Handle type="source" position={Position.Bottom} id="c"/>
            {data.internal_type != 'complementary' &&
                <React.Fragment>
                    <Handle type="source" position={Position.Top} id="a"/>
                    <Handle type="source" position={Position.Right} id="b" />
                    <Handle type="source" position={Position.Left} id="d" />
                </React.Fragment>
            }
            
            <div style={{maxWidth:100}} className={cls}>
                {data.label}
            </div>
        </React.Fragment>
    );
    }
export default BaseNode