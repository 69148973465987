import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import './html_field.css'


function HtmlField(props) {
  const { isModal, field, modalValue, setModalValue, state_attrs, value } = props;
  const editorRef = useRef(null);
  // const editor = useMemo(() => withReact(createEditor()), [])
  const HTMLwrapperRef = React.useRef(null);
  const [height, setHeight] = useState(200)

  function onBlurEditor(event, editor) {
    if (!editor) {
      editor = editorRef.current.editor
    }
    let body = editor.dom.getRoot().innerHTML
    if (isModal) {
      setModalValue(body);
    } else {
      props.onChange(false, body)
      editor.setDirty(false)
    }
  }

    useEffect(() => {
      if (!HTMLwrapperRef.current || !field.screen.use_view_layout) return; // wait for the elementRef to be available
      const resizeObserver = new ResizeObserver(() => {
        if(HTMLwrapperRef.current){
          const new_height = (HTMLwrapperRef.current.getBoundingClientRect().height)
          setHeight(new_height)
        }
      
       
      });
      resizeObserver.observe(HTMLwrapperRef.current);
      return () => resizeObserver.disconnect(); // clean up 
    }, []);

  return (
    <div ref={HTMLwrapperRef} className='overflow-scroll' style={{  height: '100%' }}>
        {
          ((field.no_wrap && state_attrs.readonly) || field.content_only) ?
            <div dangerouslySetInnerHTML={{ __html: value }}></div>
          :
          <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={isModal ? modalValue : value}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          init={{
            min_height: height,
            max_height:height,
            menubar: true,
            branding: false,
            elementpath: false,
            language:'es',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help autoresize fullpage'
            ],
            menu: {
              file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
              edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
              view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
              insert: { title: 'Insert', items: 'tryton_image tryton_stylesheet | link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
              format: { title: 'Format', items: 'italic bold underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
              tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
              table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
              help: { title: 'Help', items: 'help' }
          },
            toolbar: state_attrs.readonly ? false:'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: "@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');body { font-family:Roboto,Noto; }",
            // content_style: 'body { font-family:Roboto,Noto; font-size:1em }',
            cleanup_on_startup: false,
            trim_span_elements: false,
            verify_html: false,
            cleanup: false,
          }}
          onBlur={onBlurEditor}
          //disabled={state_attrs.readonly}
          disabled={props.previewEditor ? props.previewEditor : state_attrs.readonly}
          

        />
        }
      </div>
      

  )

}

export default HtmlField;