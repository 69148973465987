/** @flow */

import type { RenderedSection } from 'react-virtualized/Grid';
import type { ScrollIndices } from './ArrowNavigatorTypes';

import * as React from 'react';
import { polyfill } from 'react-lifecycles-compat';
// import FocusTrap from 'focus-trap-react';

/**
 * This HOC decorates a virtualized component and responds to arrow-key events by scrolling one row or column at a time.
 */

type ChildrenParams = {
  onSectionRendered: (params: RenderedSection) => void,
  scrollToColumn: number,
  scrollToRow: number,
};

type Props = {
  children: (params: ChildrenParams) => React.Element<*>,
  className?: string,
  columnCount: number,
  disabled: boolean,
  isControlled: boolean,
  mode: 'cells' | 'edges',
  onScrollToChange?: (params: ScrollIndices) => void,
  rowCount: number,
  scrollToColumn: number,
  scrollToRow: number,
};

type State = ScrollIndices & {
  instanceProps: {
    prevScrollToColumn: number,
    prevScrollToRow: number,
  },
};

class ArrowNavigation extends React.PureComponent<Props, State> {
  static defaultProps = {
    disabled: false,
    isControlled: false,
    mode: 'edges',
    scrollToColumn: 0,
    scrollToRow: 0,
  };

  state = {
    scrollToColumn: 0,
    scrollToRow: 0,
    instanceProps: {
      prevScrollToColumn: 0,
      prevScrollToRow: 0,
    },
  };

  _columnStartIndex = 0;
  _columnStopIndex = 0;
  _rowStartIndex = 0;
  _rowStopIndex = 0;

  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State,
  ): $Shape<State> {
    if (nextProps.isControlled) {
      return {};
    }

    if (
      nextProps.scrollToColumn !== prevState.instanceProps.prevScrollToColumn ||
      nextProps.scrollToRow !== prevState.instanceProps.prevScrollToRow
    ) {
      return {
        ...prevState,
        scrollToColumn: nextProps.scrollToColumn,
        scrollToRow: nextProps.scrollToRow,
        instanceProps: {
          prevScrollToColumn: nextProps.scrollToColumn,
          prevScrollToRow: nextProps.scrollToRow,
        },
      };
    }

    return {};
  }
 


  render() {
    const { className, children, onKeyDown } = this.props;
    const { scrollToColumn, scrollToRow } = this._getScrollState();

    return (
      
        <div id="arrow_navigator" tabIndex="-1" className={className} onKeyDown={onKeyDown} >
          {children({
            onSectionRendered: this._onSectionRendered,
            scrollToColumn,
            scrollToRow,

          })}
        </div>
      
    );
  }



  _onSectionRendered = ({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex,
  }: RenderedSection) => {
    this._columnStartIndex = columnStartIndex;
    this._columnStopIndex = columnStopIndex;
    this._rowStartIndex = rowStartIndex;
    this._rowStopIndex = rowStopIndex;
  };

  _getScrollState() {
    // return this.props.isControlled ? this.props : this.state;
    return this.props;
  }

}



polyfill(ArrowNavigation);

export default ArrowNavigation;