import React, {createRef, forwardRef} from 'react';
import Field from '../../ui/fields/Field';
import Container from '../../ui/surfaces/Container'
import { observer } from 'mobx-react-lite'
import GridHoc from '../../ui/GridHoc.js'

const FieldValue = observer(forwardRef(function FieldValue(props, ref) {

    const { field, record, edition_mode } = props;

    return (
        <div 
            ref={ref} 
            className={"text-xs text-gray-600 bg-green-300 font-medium"} 
            {...props} 
            key={field.name}
            onContextMenu={function (e) {

                if (edition_mode && field.edit_field()) {
                    e.preventDefault()
                }

            }}
            >
            <Field
                    field={field}
                    record={record}
                    variant="outlined"
                    ref={ref}
                    edition_mode={edition_mode}
                    // noWrap
                    // content_only

                />
            {props.children}
        </div>
    )
}));


const ListCardItem = observer(function Cell(props) {

    const { screen, record } = props;
    const fields = screen.visible_fields
    const edition_mode = screen.editor ? screen.editor.design_mode:false



    return (

        <Container type="paper" className="border border-gray-300" shadowClass="shadow-md">
            {fields && record ?
                <GridHoc screen={screen} rowHeight={5}>

                    {screen.visible_fields.map((field, index) => (
                                    <FieldValue
                                        edition_mode={edition_mode}
                                        ref={createRef()}
                                        key={field.name}
                                        field={field}
                                        record={record}
                                />


                                ))}
                </GridHoc>
                :
                ""
            }

        </Container>


    )


});

export default ListCardItem;