import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'



export const ActionWidget = observer(function ActionWidget(props) {

    
    const {widget,height} = props
    const image = widget.image
    

    return (
        <div style={{height:height}} className="flex bg-white shadow-xl flex-col w-1/2 px-4 py-4 text-center" onClick={()=>{widget.action.execute([])}}>
            
            <img className="img-fluid" style={{ display: 'inline-block' }} src={image} alt={widget.name} />
            <div  >{widget.name}</div>
            
        </div>

    )

}
)

export default ActionWidget;