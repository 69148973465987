import React from 'react';
import clsx from 'clsx';


export function Paper(props) {

    const classNames = " bg-white"
    const default_shadow = " shadow-xl"

    const getClassName = () => {
        let cls = props.className ? clsx(classNames,props.className):classNames
        cls = props.shadowClass ? clsx(cls,props.shadowClass):clsx(cls,default_shadow)
        return cls
    }
    return (

        <div 
            // className={props.className ? props.className.concat(classNames):classNames}
            className={getClassName()}
            style={props.style || {}}
           
        >
            {props.children}
        </div>

    )

}

export default Paper;