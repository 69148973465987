import React, { useState, useContext, useEffect } from 'react';
import { SessionContext, ThemeContext, Login } from 'kalenis-portal-frontend-main'
import {Paper} from 'kalenis-web-components'
import {View_} from 'kalenis-web-components'
import { observer } from 'mobx-react-lite'


import {
    useHistory
} from "react-router-dom";




const LoginView = observer(function LoginView(props) {
    const connection = useContext(SessionContext);
    const [login] = useState(() => new Login(connection))
    
    let history = useHistory()
    const theme = useContext(ThemeContext);
    const screen = login.screen

   

    useEffect(() => {
        if (connection.status) {
            history.push('/home')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection.status]);



    return (
        <div className="h-screen box-border flex content-center justify-center items-center">
            <Paper
                className=" px-2 py-16 border w-full sm:w-2/4 lg:w-1/4"
            >
                <div className="text-center mb-4">
                    <img style={{ display: 'inline-block' }} src={theme.logo} alt="Kalenis Customer Services" />
                </div>
                {screen &&
                <>
                    <View_ screen={screen} containerStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }} />
                
                <div className="text-center mt-4 mb-4">
                    <span onClick={()=>{login.forgotPassword()}} className="hover:text-primary cursor-pointer text-sm w-full text-gray-600 font-medium">¿ Olvidó su Contraseña ?</span><br></br>
                    <span onClick={()=>{login.requestSignup()}} className="hover:text-primary cursor-pointer text-sm w-full text-gray-600 font-medium"> Solicitar usuario y contraseña</span>
                </div>
                </>
                }
            </Paper>

        </div>

    );
});
export default LoginView;
