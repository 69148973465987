import React from 'react';



const base_cls = " py-2 px-2 rounded w-full font-medium text-sm outline-none"

const list_cls = " font-bold py-1 px-1 rounded-full outline-none"

const colors = {
    'primary':' bg-primary text-white hover:bg-primary-lighten',
    'secondary':' bg-secondary text-white',
    'disabled':' bg-disabled text-grey-500'
}

function FormButton(props) {
    const {state_attrs} = props
    const cls = () => {
    
    let base = props.variant === "list" ? list_cls:base_cls
    base = props.className ? base.concat(props.className):base
    const color = props.readonly ? 'disabled':props.color
    return base.concat(colors[color])

    }
    
    return (
    <button 
        type={props.type || 'button'}
        className={cls()}
        style={{display:state_attrs.invisible ? 'none':'',...props.style}}
        disabled={state_attrs.readonly}
        variant={props.variant}
        onClick={props.onClick}
    >
            {props.children}

    </button>

    )

}

export default FormButton;