import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { SpreadsheetScreen } from '../list/SpreadsheetScreen'
import { evalExpr } from '../../common'


export class FlowChartScreen extends SpreadsheetScreen {
    nodes = []
    edges = []
    parent_field = ""
    complementary_nodes = ""
    complementary_enabled = false
    constructor(group,
        view,
        connection,
        navigate,
        initial_search,
        route_state,
        is_modal,
        parent,
        initialize_fields = true,
        initialize_actions = true,
        initialize_data = true,
        initialize_callback = false,
        fileHandler = false) {
            super(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields, initialize_actions, initialize_data, initialize_callback, fileHandler)
            this.on_load_data = this.set_initial_nodes
            this.nodes = []
            this.edges = []
            this.node_name = view.flowchart_node_name || '{rec_name}'
            this.parent_field = view.flowchart_parent_field
            this.moves_field = view.flowchart_moves_field
            this.moves_field_from = view.flowchart_moves_field_from
            this.moves_field_to = view.flowchart_moves_field_to
            this.moves_field_label = view.flowchart_moves_field_label
            this.complementary_nodes = view.flowchart_complementary_nodes_field
            this.complementary_nodes_string = view.flowchart_complementary_nodes_string
            this.complementary_enabled = false
            this.x_step = 200
            this.y_step = 200

            makeObservable(this, {
                set_initial_nodes: action,
                set_nodes:action,
                complementary_enabled:observable,
                toogle_complementary:action,
                edges: observable,
                nodes: observable,

            })

    }

    toogle_complementary(){
        this.complementary_enabled = !this.complementary_enabled
        this.set_initial_nodes(this, this.data.records)
    }

    create_node(record, x_level, y_level) {
        let node_name = evalExpr(record.get_all_values(), this.node_name).str
        return {
            id: record.id.toString(),
            position: { x: x_level, y: y_level },
            type:'base',
            data: {
                label: node_name,
                record: record
            }
        }
    }

    create_complementary_node(record, x_level, y_level, node_name, parent_record){
        return {
            id: "comp_"+record.id.toString(),
            position: { x: x_level, y: y_level },
            type:"base",
            data: {
                label: node_name,
                record: record,
                classname: "bg-green-300",
                internal_type:'complementary',
                parent_record:parent_record.id
            }
        }
    }

    create_complementary_nodes(record, x_level, y_level){
        if(!this.complementary_nodes){
            return {nodes:[],edges:[]}
        }
        let complementary_data = record.get_value(this.complementary_nodes+'.')
        let complementary_nodes = []
        let complementary_edges = []
        let y_step = this.y_step/2
        let x_step = this.x_step/2 +10
        let current_y = y_level - y_step
        if(!complementary_data.length){
            return {nodes:[],edges:[],last_x:x_level}
        }
        let current_x = x_level - this.x_step/2
       
        complementary_data.forEach(function(rec, idx){
            let node = this.create_complementary_node(
                rec,
                current_x,
                current_y,
                rec.rec_name,
                record
            )
            
            
            complementary_nodes.push(node)
            // current_y -= y_step
            if (idx !== complementary_data.length - 1){ 
                current_x -= x_step
            }
            
            complementary_edges.push(
                this.create_edge(node.id, record.id,"",{'internal_type':'complementary'})
            )
            
            
        }.bind(this))

        return {nodes:complementary_nodes, edges:complementary_edges, last_x:current_x}

    }

    create_edge(from, to, label, extra_data) {
        if(!extra_data){
            extra_data = {}
        }
        let values = {
            id: from.toString() + to.toString(),
            source: from.toString(),
            target: to.toString(),
            type:"floating",
            sourceHandle:"c",
            targetHandle:"a",
            // type: label ? 'center_label' : "",
            data: {
                label: label ? label : "",
                screen:this,
                ...extra_data
            },
        }
      
        return values

    }

    get_moves_data(record) {
        const moves = record.get_value(screen.moves_field + '.')
        if (!moves || !moves.length) {
            return []
        }
        return record.get_value(screen.moves_field + '.').map(function (move) {
            return {
                'from': move[screen.moves_field_from],
                'to': move[screen.moves_field_to],
                'label': evalExpr(move, screen.moves_field_label).str
            }
        })
    }
    get_record_parents(record) {
        let parents = []
        if (this.parent_field) {
            parents = record.get_value(this.parent_field)
        }
        else if (this.moves_field) {
            parents = this.get_moves_data(record).map(function (move) {
                return move.from
            })
        }

        if (!parents) {
            return []
        }
        if (!Array.isArray(parents)) {
            parents = [parents]
        }
        return parents

    }

    set_initial_nodes(screen, records) {
        console.log("SETTING NODES")
        console.log(records)
        let to_process = [...records]
        let new_nodes = []
        let new_edges = []
        let current_y = 0
        let x_step = this.x_step
        let y_step = this.y_step
        let rows = {}
        const record_ids = records.map(function (rec) { return rec.id })

        function add_to_row(row, record) {
            if (!rows[row]) {
                rows[row] = { 'records': [], 'ids': [] }
            }
            rows[row]['records'].push(record)
            rows[row]['ids'].push(record.id)

        }

        function add_edges(current_y, row, record_parents, record) {
            let res = false
            if (!rows[row]) {
                return res
            }
            if (screen.moves_field) {
                // const moves_data = record.get_value(screen.moves_field+'.')
                const moves_data = screen.get_moves_data(record)
                moves_data.forEach(function (move) {
                    new_edges.push(screen.create_edge(move.from, move.to, move.label))
                })
                if (moves_data.length) {
                    res = true
                }

            }
            else {
                record_parents.forEach(function (id) {
                    if (rows[row]['ids'].includes(id)) {
                        new_edges.push(screen.create_edge(id, record.id))
                        res = true
                    }
                })
            }

            return res

        }

        function in_parent(row, record_parents) {
            let res = false
            if (!rows[row]) {
                return res
            }
            record_parents.forEach(function (id) {
                if (rows[row]['ids'].includes(id)) {
                    res = true
                }
            })
            return res
        }

        function all_parents_missing(record_parents) {
            let res = record_parents.map(function (id) {
                return record_ids.includes(id)
            })
            return res.every((currentValue) => currentValue == false)

        }

        while (to_process.length) {
            let prev_y = current_y > 0 ? current_y - y_step : current_y
            let to_remove = []
            let to_add = []
            to_process.forEach(function (record, idx) {
                let record_parents = screen.get_record_parents(record)
                if (!Array.isArray(record_parents)) {
                    record_parents = [record_parents]
                }
                if (!record_parents.length) {
                    to_add.push({ 'level': 0, 'record': record })
                    to_remove.push(idx)
                }
                else {
                    if (in_parent(prev_y, record_parents)) {
                        to_add.push({ 'level': current_y, 'record': record })
                        to_remove.push(idx)
                        add_edges(current_y, prev_y, record_parents, record)

                    }
                    else if (record_parents.length && all_parents_missing(record_parents)) {
                        to_add.push({ 'level': 0, 'record': record })
                        to_remove.push(idx)
                    }
                    else {
                        return
                    }
                }



            }.bind(this))

            to_add.forEach(function (add) {
                add_to_row(add.level, add.record)
            })
            for (var i = to_remove.length - 1; i >= 0; i--)
                to_process.splice(to_remove[i], 1);
            current_y += y_step

        }
        
        for (let level in rows) {
            // Center level on x axis
            let current_x = 0
            let level_nodes = []
            let current_complementary_x = 0
            rows[level].records.forEach(function (rec) {
                level_nodes.push(screen.create_node(rec, current_x, parseInt(level)))
                current_x += x_step
            })
            
            let prev_level = (parseInt(level) - screen.y_step).toString()
            if(rows[prev_level]){
                const regular_nodes = rows[prev_level].nodes.filter((node) => !node.data.internal_type)
                const prev_width = regular_nodes.length * screen.x_step
                const first_x = Math.min(...regular_nodes.map(function(n){return n.position.x}))
                const level_width = level_nodes.length * screen.x_step
                let offset
                console.log(prev_width)
                console.log(level_width)
                if(prev_width == level_width){
                    offset = first_x
                }
                else{
                   
                    offset = first_x + (prev_width-level_width)/2
                    
                }
                
                if(offset){
                    level_nodes = level_nodes.map(function(node){
                        return {...node, position:{...node.position, x:node.position.x + offset}}
                    })
                }
                

            }
            
            // Create complementaries in reverse order
            current_complementary_x = false
            if(screen.complementary_enabled){
                level_nodes.slice().reverse().forEach(function (node) {
                        if(current_complementary_x===false){
                            current_complementary_x = node.position.x
                        }
                        let complementary = screen.create_complementary_nodes(node.data.record, current_complementary_x, parseInt(level))
                        level_nodes = [...level_nodes, ...complementary.nodes]
                        new_edges = [...new_edges, ...complementary.edges]
                        if(current_complementary_x == complementary.last_x){
                            current_complementary_x = current_complementary_x - screen.x_step
                        }
                        else{
                            current_complementary_x = complementary.last_x
                        }
                        
                    
                })

            }
            new_nodes = [...new_nodes, ...level_nodes]
            rows[level]['nodes'] = level_nodes

            
            
            
        }
        
        console.log("FINAL NODES")
        console.log(new_nodes)
        console.log(new_edges)
        
        runInAction(() => {
            this.nodes = new_nodes
            this.edges = new_edges



        })


    }

    set_nodes(nodes){
        this.nodes = nodes
    }
    
    // Event Handlers
    on_node_click(node) {
        if(node.data.internal_type == 'complementary'){
            return
        }
        this.changeSelection({id:node.data.record.id, toogle:true})
        this.open_complementary_nodes(node)
        
    }
    on_node_double_click(node){
        if(node.data.internal_type == 'complementary'){
            return
        }
        let record = node.data.record
        if (this.default_action && record) {
            this.default_action.execute([record])
        }
    }

    open_complementary_nodes(node){
        let current_nodes = this.nodes.filter(n => n.data.internal_type!='complementary')
        let edges = this.edges.filter(n => n.data.internal_type!='complementary')
        if(this.is_selected(node.data.record.id)){
            let complementary = this.create_complementary_nodes(node.data.record, node.position.x, node.position.y)
            current_nodes = [...current_nodes, ...complementary.nodes]
            edges = [...edges, ...complementary.edges]

            
        }
        
        runInAction(() => {
            this.nodes = current_nodes
            this.edges = edges

        })
    }

}