import { makeAutoObservable, autorun, runInAction, computed, action, observable } from "mobx"
import { Cache } from './Cache';
import { createStorage } from './Storage'
import { Config } from './config'
import {fetch_ as fetch} from './fetch'
import EditorConfig from './EditorConfig'
import { environment } from '../common'
import {Database} from '../database/Database'
import {UserContext} from './UserContext'
import { Menu } from "./Menu";

const BASE_API_ROUTE = "/app/v1"

export class Connection {
    status = false
    token = ""
    // api_path = "/app/v1"
    base_path = ""
    api_path = ""
    cache = {}
    routes = []
    menu_tree = []
    user_context = false
    editor_config = {}
    router = {'routes':[],'navigate':false}
    superset = {}
    offline_mode = false
    main_screen_group = false

    get visible_routes(){
        
        if(!this.routes){
            return []
        }
        return this.routes.filter(function(route){return route.visible_menu === true})
    }

    get offline_models(){
       return this.offline_master_routes.map(function(route){return {'model':route.model, 'view':route.id}})
    }
    // Views to load records for offline use
    get offline_master_routes(){
        if(!this.routes){
            return []
        }
        return this.routes.filter(function(route){return route.offline_master === true})
    }
    get offline_routes(){
        if(!this.routes){
            return []
        }
        return this.routes.filter(function(route){return route.offline_available === true})
    }

    get is_sync_active(){
        return this.db_handler ? this.db_handler.is_sync_active:false
    }

    constructor(notifications, external_handler, load_db_adapter=false) {
        makeAutoObservable(this, {
            loadRoutes:action,
            setRouter:action,
            setEditorConfig:action,
            visible_routes:computed,
            routes:observable,
            menu_tree:observable,
            editor_config:observable,
            api_path:observable,
            base_path:observable,
            setApiPath:action,
            setSessionStatus:action,
            status:observable,
            initialize:action,
            user_context:observable,
            offline_mode:observable,
            setOfflineMode:action,
            setUserContext:action,
            setSession:action,
            main_screen_group:observable,
            setMainScreenGroup:action,
            status_getter:observable,
            set_status_getter:action,
            custom_home_view:observable,
            menu:observable
        })
        
        this.initialize(notifications, external_handler, load_db_adapter).then(function(session){
            
        })

    }
    async initialize(notifications, external_handler=false, load_db_adapter=false){
        this.external_handler = external_handler
        this.config = Config(external_handler)
        this.menu =new Menu(this)
        this.status = undefined
        this.token = ""
        this.api_path = this.config.api_path ? this.config.api_path:false
        this.auth_proxy = this.config.auth_proxy ? this.config.auth_proxy:false
        this.routes = []
        this.router = {'routes':[],'navigate':false}
        this.cache = new Cache()
        this.storage = createStorage()
        this.notifications = notifications
        this.db_adapter = load_db_adapter
        // this.main_screen_group = false
        this.user_context = false
        // Default value for the status_getter based on Web
        this.setStatusGetter(async () => navigator.onLine)
        this.checkConnection()
        // this.setUserContext({})
        return await this.getSessionFromStorage()
        
        
    }

    setOfflineMode(status){
        this.offline_mode = status
    }
    setStatusGetter(getter){
        this.status_getter = getter
    }
    offlineAvailable(view_id){
        const route = this.routes.find(route => route.id === view_id)
        if(route){
            return route.offline_available
        }
        return false
        
    }
    reSync(){
        this.db_handler.resync()
    }

    setMainScreenGroup(group){
        this.main_screen_group = group
    }
    async checkConnection(){
        // Only check if some kind of network is reachable
        const status = await this.status_getter()
        if(status === false){
            this.setOfflineMode(true)
        }
        else{
            this.setOfflineMode(false)
        }
        // Remove this, just checking mobile a bit
        // status = true
        return status

    }
    async getSessionFromStorage(){
        let session_info = await this.storage.getItem('kalenis_session')
        if (session_info) {
            
            
            this.setSession(JSON.parse(session_info))

          }
        else{
            this.setSessionStatus(false)
        }

    }

    async saveSessionToStorage(session){
        let stored_session = await this.storage.getItem('kalenis_session')

        if(!stored_session){
            await this.storage.setItem('kalenis_session', JSON.stringify(session))
        }
    }

    setSessionStatus(value){
        this.status = value;
    }
    
    getBasePath(){
        if(environment() === 'web'){
            return window.location.origin
        }
        else{
            return this.base_path
        }
    }
    getFullPath(path){
        if(environment() === 'web'){
            return window.location.origin + this.getPath(path)
        }
        else{
            return this.getPath(path)
        }
    }

    getPath(path) {
        
        if(!this.api_path){
            return false
        }
        return this.api_path.concat(path)

    }
    getRouteById(id){
        
        return this.router.routes.find(route => route.id === id)
    }
    setRouter(router){
        
        this.router = router;
    }
    createUrl(params) {
        var query = "?"
       
        Object.keys(params).map(function (field) {// eslint-disable-line
            
            if(field === "search"){
    
                let current_search = params[field]
                
                current_search.forEach(function(domain){
                    
                    const field = domain[0]
                    const operator = domain[1]
                    let value = domain[2]
                    if(value !== "" && value !== undefined){
                        if(operator){
                            
                            if(Array.isArray(value)){
                                value = value.toString()
                                value = '['.concat(value).concat(']')
                            }
    
                        }
                        value = operator.concat(";").concat(value)
                    }
                    query = query.concat(field).concat('=').concat(value).concat('&')
                    
                })
                
    
            }
            else if (field === 'action_params'){
                let action_params = params[field]
                
                if(action_params.length){
                    query = query.concat('_ap=')
                    action_params.forEach(function(domain){
                    
                        const field = domain[0]
                        const operator = domain[1]
                        let value = domain[2]
                        if(value !== "" && value !== undefined){
                            if(operator){
                                
                                if(Array.isArray(value)){
                                    value = value.toString()
                                    value = '['.concat(value).concat(']')
                                }
        
                            }
                            value = operator.concat(";").concat(value)
                        }
                        query = query.concat(field).concat(';').concat(value).concat('&')
                        
                    })
                }
                
                
            }
    
            else if(field === "order"){
               
                if(params[field].length){
                    query = query.concat('_order').concat("=").concat(params[field].toString()).concat('&')
                }
                
            }
            else if(field === "group_by"){
               
                if(params[field].length){
                    query = query.concat('_group_by').concat("=").concat(params[field].toString()).concat('&')
                }
                
            }
            else if(field === "action_id"){
                if(params[field]){
                    query = query.concat('_action_id').concat("=").concat(params[field]).concat('&')
                }
            }
      
      
        })
      
      
        return query
      }

    getHeaders() {
        let headers;
        if (this.status && this.token) {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token,
                'pragma':'no-cache',
                'cache-control':'no-cache'

            }
        }
        else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }

        return headers;
    }


    createQuery(params) {
        var search = "?"

        Object.keys(params).map(function (field) {// eslint-disable-line

            if (params[field] !== "" && params[field] !== undefined) {
                var field_value = params[field]
                if (field_value instanceof Array) {
                    field_value = JSON.stringify(field_value)
                    field_value = field_value.replaceAll('null','None')
                }

                else if (field_value instanceof Object) {
                    field_value = JSON.stringify(field_value)
                    field_value = field_value.replaceAll('null','None')
                }
                else if (field_value == 'null' || field_value == null){
                    field_value = 'None'
                }
                
                search = search.concat(field).concat('=').concat(field_value).concat('&')


            }

        })



        return search
    }

    async handleNativeBlobResponse(response){
        
        res = {}
        
        res['mimetype'] = response.headers.get('content-type')
        res['data'] = await response.blob()
        return res
    }


    async Post(body, path, headers, raw_response) {
        var body_req = JSON.stringify(body)
        let response = ""

        try {
            response = await fetch(path, {
                method: 'POST',
                headers: headers,
                mode: 'cors',
                body: body_req,
            })

        } catch (e) {
            
            // I/ReactNativeJS(28769): { [TypeError: Network request failed] line: 137, column: 8199, sourceURL: 'index.android.bundle' }
            
            return { 'error': 'retry' }
        }

        



        let res;

        if (!raw_response && response.status === 401) {
            this.removeLocalSession()
            return false
        }
        if (!raw_response && response.status === 400) {
            res = await response.json()
            this.notifications.addSnack(
                { message: res.error, level: 'error', persistant:false }
            )
            
            return false

        }

        if (!raw_response && response.status !== 200) {

            res = await response.text()
            // TODO: Sentry
            this.notifications.addSnack(
                { message: "Algo ha salido mal, por favor intentelo nuevamente mas tarde.", level: 'error', persistant:false }
            )
            return false
            // return false
        }


        if (raw_response) {
            res = response
        }
        else {
            res = await response.json();
        }

        return res;
    }

    async Get(args, path, headers, raw_response, blob, cache_write, abortController) {

        // var body_req = JSON.stringify(body)
        let fullPath = args ? path.concat(this.createQuery(args)) : path
        const cached = this.cache.get(fullPath)
        if (cached) {
            
            return cached
        }

        let response = await fetch(fullPath, {
            signal: abortController ? abortController.signal : false,
            method: 'GET',
            headers: headers,
            mode: 'cors',

        })



        if (response.status === 401) {
            this.removeLocalSession()
            return false
        }

        let res;

        if (!raw_response && response.status === 404) {
            
            // res = await response.text()
            // alert(res)
            return false
            // return false
        }
        else if(response.status === 400){
            res = await response.json()
            this.notifications.addSnack(
                { message: res.error, level: 'error', persistant:false }
            )
            
            

            return false
        }





        if (blob) {
            if(environment() === 'native'){
                res = await this.handleNativeBlobResponse(response)
            }
            else{
                const filename = response.headers.get('x-filename')
                const data = await response.blob()
                res = {filename, data}
            }

        }
        
        else {
            res = await response.json()
        }

        if (cache_write) {
            runInAction(() => {
                this.cache.set(fullPath, res)
              })
            
        }




        return res;

    }

    async removeLocalSession(){
        return await this.storage.removeItem('kalenis_session')
    }

    async setLocalDB(config){

        this.db_handler = new Database(this, config, this.db_adapter)
        await this.db_handler.initialize()
        // Temporary, remove this set
        // this.setOfflineMode(false)
    }

    async setSession(session){
        
        if(!session){
            return
        }
        
        this.token = session.token
        
        if(session.superset){
            this.superset = session.superset
        }
       
        
        if(session.url && this.auth_proxy){
            this.setApiPath(session.url)
        }
        
        await this.saveSessionToStorage(session)
        
        
        

        
        
        // this.setUserContext(session.context)
        
        this.setSessionStatus(true)
        this.user_context = new UserContext(this, session.context)
        console.log("USER CONTEXT CREATED ?")
        if(session.token && session.couch_db.enabled){
            await this.setLocalDB(session.couch_db)
            
        }
        this.setEditorConfig()
        await this.loadRoutes()
        if(this.db_handler){
            this.db_handler.initialize_pool()
        }
            
        // await this.compileOfflineData()
        
        
        
        

        
    }

    async loadUserContext(){
        const abortController = new AbortController();
        let args = {
            'environment':environment()
        }
        let user_context = await this.dispatch('GET', '/user/context', args, false, true, false, abortController)

        
        return user_context
        
    }
    setUserContext(context){
        // this.user_context = new UserContext(this, context)
        this.user_context.setValues(context)
    }
    setEditorConfig(){
        this.editor_config = new EditorConfig(this)
    }
    
    async loadRoutes(role){
        const abortController = new AbortController();
        let args = {
            'environment':environment()
        }
        if(role){
            args['role'] = role
        }
        let routes = await this.dispatch('GET', '/user/menu', args, false, false, false, abortController)
        console.log("ROUTES MENU GETTED")
        console.log(routes)
        runInAction(() => {
            this.routes = routes.routes
            this.menu_tree = routes.tree
            this.custom_home_view = routes.custom_home_view
            this.menu.setup_tree(routes.routes, routes.tree)
          })
        
        
        return routes
        
    }
    

    logout(){
        this.status = false
        this.token = ""
        this.removeLocalSession()
        this.initialize(this.notifications)
    }

    setApiPath(path){
        this.base_path = path;
        this.api_path = path.concat('/app/v1');
    }

    async getAvailableHosts(values){
        let headers = this.getHeaders()
        const session_path = this.config.auth_proxy.concat('/mid/v1/user/hosts')
        let hosts = await this.Get(values, session_path, headers, false)
        
        return hosts

        
    }

    async login(values){
        // if(this.auth_proxy){
        //     return await this.ProxyLogin(values) 
        // }

        let headers = this.getHeaders()
        const session_path = this.getPath("/auth/token")

        let session = await this.Post(values, session_path, headers, false)
        
        
        if(session){
            session['url'] = this.base_path
            await this.setSession(session)
            
        }
        return session
    }

    async dispatch(type, path, args, raw_response, cache_write, blob, abortController) {
        let headers = this.getHeaders()
        let res = ""
        path = path ? this.getPath(path) : ""
        if(args && this.user_context && this.user_context.initialized){
            args['_user_context'] = JSON.stringify(this.user_context.get_all_values())
        }
        
        if(!path){
            return false
        }
        const conn_status = await this.checkConnection()
        // const conn_status = false
        if(!conn_status && this.db_handler){
            // try{
                const offline_value = await this.db_handler.dispatch({type, path, args, raw_response, cache_write, blob, headers})
                return offline_value
            // }
            // catch(e){
            //     // If the offline operation fails, the view/model is not available, just return
            //     return
            // }
            
            
        }
        if(!abortController){
            abortController = new AbortController()
        }
        switch (type) {
            case 'POST': {
                
                res = await this.Post(args, path, headers, raw_response)
                if(args.view && this.offlineAvailable(args.view)){
                    this.db_handler.mirrorAction({type, path, original_args:args, res})
                }
                
                
                return res
            }
            case 'GET': {
                res = await this.Get(args, path, headers, raw_response, blob, cache_write, abortController)
                return res

            }

            default:
                throw new Error();
        }

    }

}