import React, { useContext, useEffect, useState } from 'react';
import {observer} from 'mobx-react-lite'


import LoginView from '../auth/Login.js';
// import SessionContext from '../auth/SessionContext.js';
import { SessionContext, ThemeContext } from 'kalenis-portal-frontend-main'
import Main from './Main.js';
import { ReactComponent as ViewListIcon } from '../icons/view-list.svg';
import { CreateView } from '../views/View.js'
import ResetPasswordView from '../auth/ResetPasswordView'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    

} from "react-router-dom";

const PrivateRoute = observer(function PrivateRoute({ children, ...rest }) {
// function PrivateRoute({ children, ...rest }) {
    const session = useContext(SessionContext)
    
    return (
        <Route
            {...rest}
            render={({ location }) =>
                session.status ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
        );
    })
    


const KalenisRouter = observer(function KalenisRouter(props) {
    const [preparedRoutes, setPreparedRoutes] = useState([])
   
    const ws_context = useContext(SessionContext);
    const routes = ws_context.routes
    const session_status = ws_context.status
    const theme = useContext(ThemeContext)
    const theme_ready = theme.ready;
    
    const titleToPath = (title) =>{
        return '/'.concat(title.replace(/\s/g, ''))
    }


    const createRoute = (option)=>{
        return {
            path:titleToPath(option.name),
            exact:true,
            id:option.id,
            group:option.group,
            visible_menu:option.visible_menu,
            sidebar:{title:option.name, icon:<ViewListIcon style={{ width: '1em', height: '1em', display:'inline-block' }} className="fill-current text-xl cursor-pointer h-4 mr-1 md:mr-4"/>},
            // main: () => <View screen={screens.getScreen(option.id)} view={option} navigate={true} />
            main: () => <CreateView view={option} navigate={true}/>
        }
    }

    useEffect(() => {
       if(routes){
        const pr = routes.map(function(route){
            return createRoute(route)
       })
       setPreparedRoutes(pr)
       ws_context.setRouter({'routes':pr})
       }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [routes]);

    

    return (
        <div>
            {(theme_ready && session_status !== undefined) && 
            <Router>


            <Switch>
           
                <Route path="/login" exact={true}>
                    <LoginView />
                </Route>
                <Route path="/reset_password" exact={true}>
                    <ResetPasswordView/>
                </Route>

                
                <Main routes={preparedRoutes}>

                    {preparedRoutes.map((route, index) => (

                        <PrivateRoute
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            sidebar={route.sidebar}
                            header={route.header}
                            children={<route.main />}
                        />
                    ))}

                </Main> 
               
                
                    
                    


            </Switch>








        </Router>
        }
            

        </div>
    );
})



export default KalenisRouter;