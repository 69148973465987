import React from 'react';
import Portal from '../ui/Portal'
import IconButton from '../ui/buttons/IconButton';
import { ReactComponent as CloseIcon } from '../ui/icons/svg/close.svg';
import { environment } from '../common'
//TODO: ADD Responsive Breakpoints

// TODO: Add rest of positions, currently supported: left-right
//props:

// position:  one of: top-left, top-righth, top-center





const height = 50
const pos = height + 10


function SnackBarWeb(props) {
    const { snack } = props
    const levels = {
        'sucessfull': " bg-green-200 text-green-800 border-green-400",
        'error': " bg-red-200 text-red-800 border-red-400"
    }
    const style = {
        position: 'fixed',
        zIndex: '9999',
        top: props.position ? Math.max(props.position * pos, pos) : 0,
        // left:'10%',
        right: 'auto',
        bottom: 'auto',
        height: height,
        // width:'15%'

    }
    return (
        <Portal target={props.target}>
            <div className={"snackbar-appear text-sm".concat(levels[snack.level])} style={style}>
                <div className="h-full w-full text-justify py-4 px-4 font-medium">
                    <div className="flex">
                        <span>{snack.message}</span>
                        <IconButton color="warning" onClick={() => { snack.remove() }} style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <CloseIcon style={{ width: '.7em', height: '.7em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                        </IconButton>
                    </div>
                </div>
            </div>

        </Portal>
    )

}


export let SnackBar;

if (environment() === 'web') {
    SnackBar = SnackBarWeb
}
