import React from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal h-full"



function TextField(props) {
    const {field, isModal, record, state_attrs, onFocus, setModalValue, modalValue} = props;

    const handleOnChange = (e) => {
        if (isModal) {
            setModalValue(e.target.value);
        } else {
            props.onChange(e,e.target.value)
        }
    }
    
    return (
        
            
            <textarea
                id={props.id || props.name}
                autoFocus={props.autoFocus}
                // tabIndex="-1"
                data-ismodal={isModal}
                ref={props.inputRef}
                name={props.name}
                label={props.label}
                required={state_attrs.required}
                readOnly={state_attrs.readonly}
                className={props.modalClass ? base_cls.concat(props.modalClass) : base_cls}
                value={isModal ? modalValue : props.value}
                onChange={(e)=> handleOnChange(e)}
                onBlur={props.onBlur}
                type={field.hide_value ? 'password':''}
                placeholder={field.description}
                // onKeyPress={handleKeyPress}
                onKeyDown={props.handleKeyDown}
                style={props.style}
                autoComplete="off"
                onFocus={onFocus}

            />
            
        


    )

}

export default TextField;