import React from 'react';



const base_cls = " py-2 px-2 rounded font-medium text-sm outline-none "

const colors = {
    'primary':' bg-primary text-white hover:bg-primary-lighten',
    'secondary':' bg-secondary text-white',
    'transparent':'bg-transparent text-primary hover:bg-light-selected rounded-full',
    'disabled':' bg-disabled text-gray-500',
    'warning':' bg-transparent text-gray-500 hover:text-red-600 rounded-full'
}

function IconButton(props) {

    const cls = () => {
    let base = props.className ? props.className.concat(base_cls):base_cls
    return base.concat(colors[props.color])

    }
    
    return (
    <button 
        type={props.type || 'button'}
        className={cls()}
        style={props.style}
        onClick={props.onClick}
    >
            {props.children}

    </button>

    )

}

export default IconButton;