import React, { Fragment, useRef, useState, useEffect } from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
import { ReactComponent as Dots } from '../../ui/icons/svg/navigation-more.svg'
import IconButton from '../buttons/IconButton'
import { ReactComponent as DownloadIcon } from '../icons/svg/download.svg';
import { ReactComponent as AddIcon } from '../icons/svg/add-solid.svg';
import { ReactComponent as DeleteIcon } from '../icons/svg/close.svg';
import { observer } from 'mobx-react-lite'
import Field from './Field';

const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal"

const widgets = {
    // 'image': ({ value }) => <img style={{ maxHeight: '90%' }} src={value}></img>,
    'image': (props) => <ImagePreview {...props}/>,
    // 'base':({value, field, record})=><div onClick={()=>field.download_content(record)}>Descargar</div>
    'base': (props) => <BaseWidget {...props} />

}


const ImagePreview = observer(function ImagePreview(props) {
    const [uri, setUri] = useState([])
    const { field, record, openFileHandler, state_attrs } = props

    let value = field.get_value(record)
    
    useEffect(() => {

        value.get_data().then(function(data){
            setUri(data)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
        {
        uri ?
            <img style={{ maxHeight: '100%' }} src={uri}/>
            :
            ""
        }
        
        </>
    )

});



const BaseWidget = observer(function BaseWidget(props) {

    const { field, record, openFileHandler, state_attrs } = props
    const value = field.get_value(record)
    const content = (value && value.name) ? value.rec_name : field.description
    const has_value = value.available_value
    const MainIcon = has_value ? DownloadIcon : state_attrs.readonly ? () => <></> : AddIcon

    function onClickMainIcon(e) {
        if (has_value) {
            return field.download_content(record)
        }
        else {
            if(!state_attrs.readonly){
                openFileHandler()
            }
            
        }
    }
    function onClickDeleteIcon(e) {
        field.set_value(null, record)
    }
    return (
        <div className="flex justify-between w-full h-full" style={field.style || {}}>
            {!field.hide_value &&
                <div className='whitespace-nowrap overflow-ellipsis overflow-hidden'>
                    {content}
                </div>
            }

            <div className="flex flex-row flex-shrink-0">
                <IconButton
                    onClick={onClickMainIcon}
                    color="transparent"

                >
                    <MainIcon className="fill-current cursor-pointer" />
                </IconButton>
                {(has_value && !state_attrs.readonly) &&
                    <IconButton
                        onClick={onClickDeleteIcon}
                        color="warning"

                    >
                        <DeleteIcon className="fill-current text-red-400 cursor-pointer" />
                    </IconButton>
                }
            </div>


        </div>
    )

});
const BinaryField = observer(function BinaryField(props) {
    // function BinaryField(props) {
    const { field, state_attrs, record } = props;
    const widget = field.attributes.widget || 'base'
    const file_handler_ref = useRef(null)

    const openFileHandler = () => {
        // `current` points to the mounted file input element
        file_handler_ref.current.click();
    };
    const onChangeFile = (e) => {
        console.log("file changin")
        console.log(e.target.files)
        field.set_value(e.target.files[0], record)

    }

    return (
        <>

            <input
                type='file'
                id='file'
                ref={file_handler_ref}
                style={{ display: 'none' }}
                onChange={onChangeFile}
            />
            {
                <>{widgets[widget]({ field, record, state_attrs, openFileHandler })}</>
            }
        </>




    )

});

export default BinaryField;