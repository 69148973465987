
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'
import CenterLabelEdge from './CenterLabelEdge';
import FloatingEdge from './edges/FloatingEdge';
import ComplementaryNode from './nodes/Complementary';
import BaseNode from './nodes/BaseNode'
import LoadingIndicator from '../../common/LoadingIndicator'
import ViewHeader from '../ViewHeader.js';
import FormButton from '../../ui/buttons/FormButton';
import ReactFlow, {
  MiniMap,
  EdgeTypes,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  applyNodeChanges,
  ConnectionMode,
  applyEdgeChanges,
  Panel,
  MarkerType
} from 'reactflow';

import 'reactflow/dist/style.css';
import './flowstyle.css'


const node_types = {
  complementary: ComplementaryNode,
  base: BaseNode
}

const edgeTypes = {
  center_label: CenterLabelEdge,
  floating: FloatingEdge
};

const FlowChartView = observer(function FlowChartView(props) {
  const { screen } = props
  const height = props.height ? props.height : (window.innerHeight * 75) / 100;
  const container_ref = React.createRef()

  function onNodesChange(changes) {
    let new_changes = applyNodeChanges(changes, screen.nodes)
    screen.set_nodes(new_changes)

  }
  return (
    <div ref={container_ref} style={{ height: height }}>
      <LoadingIndicator
        ref={container_ref}
        show={screen.data.loading}
      />
      {(!props.headless) &&
        <ViewHeader
          screen={screen}
          hide_toolbar={props.hide_toolbar}
          isSpreadsheet={false}
          container_classname={props.height ? false : "rounded bg-white border border-gray-300"}
          // rowCount={rowCount}
          {...props.header_props}
        />
      }
      <ReactFlow
        nodes={screen.nodes}
        edges={screen.edges}
        edgeTypes={edgeTypes}
        nodeTypes={node_types}
        onNodeClick={(e, node) => { screen.on_node_click(node) }}
        onNodeDoubleClick={(e, node) => { screen.on_node_double_click(node) }}
        connectionMode={ConnectionMode.Loose}
        fitView
        panOnDrag
        onNodesChange={onNodesChange}
        defaultEdgeOptions={{
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#0B6623',
          }
        }}
      >
        <Background />
        <MiniMap />
        {/* <Panel position="top-right">
          {screen.complementary_nodes &&
            <FormButton
              variant="list"
              state_attrs={{}}
              className=" truncate text-xs py-1 px-2 mr-2"
              color={screen.complementary_enabled ? 'secondary':'transparent'}
              onClick={() => screen.toogle_complementary()}>
              {screen.complementary_nodes_string}
            </FormButton>
          }


        </Panel> */}
      </ReactFlow>


    </div>

  )

});


export default FlowChartView;