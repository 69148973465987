import React, {  useState, useEffect, Fragment } from 'react';
import Portal from '../common/Portal.js'
import useClickOutside from '../common/useClickOutside.js'



const DropDown = React.forwardRef((props, ref) => (

    <>
    {props.open &&
    
    <Portal>
        <DropDownP key={'filter_bar'} parent_ref={ref} {...props}> 
            {props.children}
        </DropDownP>

    </Portal>
    }
        
    </>
    
    

));


function DropDownP(props) {
    const [position, setPosition] = useState({})

    const { screentype, posx, posy, closeOnClickOutside, open, onClose } = props;
    const wrapperRef = React.useRef(null);

    useClickOutside(wrapperRef, onClose, open, closeOnClickOutside)
    useEffect(() => {
        const dimensions = props.parent_ref.current.getBoundingClientRect()
        let pos;
        if (screentype === "spreadsheet") {
            console.log("DROPDOWNJS: GOING THROW SPREADHSEET")
            pos = {
                position: 'absolute',
                left: posx,
                top: posy,
                width: dimensions.width,
                zIndex:'9999'
            }
        } else if (screentype === "form"){
            console.log("DROPDOWNJS: GOING THROW FORM")
            pos = {
                position: 'absolute',
                left: dimensions.left,
                top: posy,
                width: dimensions.width,
                zIndex:'9999'
            }
        } else {
            console.log("DROPDOWNJS: GOING THROW ELSE")
            pos = {
                position: 'absolute',
                left: dimensions.left - 10,
                // top: dimensions.y >= 300 ? ((dimensions.y + window.scrollY + dimensions.height) - 300) : dimensions.y + window.scrollY + dimensions.height,
                top: dimensions.y + window.scrollY + dimensions.height,
                width: dimensions.width,
                zIndex:'9999'
            }
        }
                
        if(props.modifiers){
            pos.width +=props.modifiers.width || '100%'
            pos.left+= props.modifiers.left || 0
            pos.top += props.modifiers.top || 0
        }
        
        
        setPosition(pos)
        


    }, [props.parent_ref]);

   

    return(
        
            <div ref={wrapperRef} {...props} style={position}>
                {props.children}
            </div>
            

    )

}

export default DropDown;