import React, {Fragment}  from 'react';
import Portal from '../common/Portal.js'
import Paper from './surfaces/Paper.js';
// import useClickOutside from '../common/useClickOutside.js';
//import useClickOutside from '../common/useClickOutside.js';



function Modal(props) {
    // const width = 150


    const style = {
        position:'absolute',
        top                   : props.top ? props.top:'50%',
        left                  : props.left ? props.left:'50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        zIndex                : '9999',
        
        // backgroundColor       : 'transparent',
        // border                : 'none'
    }
    // useClickOutside(content_ref, props.onClose, props.open)

    /*function focusOut(target){
        if(!target.id.includes('react-select')){
            if(props.modalType === "preview"){
                props.showModalPreview(false)
            }
            if(props.modalType === "edition"){
                props.saveRecord()
                props.showModalEdit(false)
            }
            // props.active_record.save()
            

        }
        
    }*/

    //useClickOutside(props.containerRefModal, focusOut, props.open)

    return (
        <>
            {props.open && 
                <Portal target={props.target}>
                    <div className="absolute bg-modal-background inset-0 z-40">
                        {props.paper ?
                            <Paper targetRef={props.containerRefModal} className={props.className} style={props.style ? {...style,...props.style}:style} >
                                {props.children}
                            </Paper>
                        :
                            <div ref={props.containerRefModal} className={props.className} 
                                 style={props.style ? {...style,...props.style}:style}>
                                     {props.children}
                            </div>
                        }
                       


                    </div>



                </Portal>
            }
        </>


    )

}
export default Modal;