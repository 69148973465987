import React, { useEffect } from 'react';

import Item from './Item.js';

function ListItem(props){
    const {
        formHeight,
        items,
        saveChanges,
        selectedItems,
        title
    } = props;

    useEffect(() => {
        saveChanges && saveChanges()
    }, [selectedItems])

    return (
        <div className="p-1 border border-gray-400 rounded-lg" style={{height: "456px", width: "400px"}}>
            <p className="font-medium text-center">{title}</p>
            <hr/>
            <div className="m-3">
                <div
                    style={{height: '380px'}}
                    className={`${items && items.length > 10 ? 'overflow-y-scroll' : ''}`}>
                    {
                        items && items.map((item, i)=>{
                            return (
                                <Item
                                    key={item.id ? item.id : i}
                                    checkValues={props.checkValues}
                                    isReadonly={props.isReadonly}
                                    item={item}
                                    handleClickLeft={props.handleClickLeft}
                                    handleClickRight={props.handleClickRight}
                                    handleOneLeft={props.handleOneLeft}
                                    handleOneRight={props.handleOneRight}
                                    listType={props.listType}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ListItem;