import React, { useState, useContext, useEffect } from 'react';
import { SessionContext, ThemeContext, ResetPassword } from 'kalenis-portal-frontend-main'
import {Paper} from 'kalenis-web-components'

import { observer } from 'mobx-react-lite'

// import { View_ } from '../views/View'
import {View_} from 'kalenis-web-components'

import {
    useHistory,
    useParams,
    useLocation
} from "react-router-dom";




const ResetPasswordView = observer(function ResetPasswordView(props) {
    const connection = useContext(SessionContext);
    let history = useHistory()
    const [reset_screen] = useState(() => new ResetPassword(connection, history.push))
    const screen = reset_screen.screen
    
    reset_screen.setUrl(window.location.href)
    
    // const [login] = useState(() => new Login(connection))
    
    
    const theme = useContext(ThemeContext);
    // const screen = login.screen
    function useQuery() {

        return new URLSearchParams(useLocation().search);
    }
    
    const params = useQuery()
    console.log("PARAMS")
    console.log(params.forEach(function(f){
        console.log("PARAM LOOPED")
        console.log(f)
    }))
    
    
    



    return (
        <div className="h-screen box-border flex content-center justify-center items-center">
            <Paper
                className=" px-2 py-16 border w-full sm:w-2/4 lg:w-1/4"
            >
                <div className="text-center mb-4">
                    <img style={{ display: 'inline-block' }} src={theme.logo} alt="Kalenis Customer Services" />
                </div>
                {screen &&
                <>
                    <View_ screen={screen} containerStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }} />
                
                {/* <div className="text-center mt-4 mb-4">
                    <span className="hover:text-primary cursor-pointer text-sm w-full text-gray-600 font-medium">¿ Olvidó su Contraseña ?</span><br></br>
                    <span onClick={()=>{login.requestSignup()}} className="hover:text-primary cursor-pointer text-sm w-full text-gray-600 font-medium"> Solicitar usuario y contraseña</span>
                </div> */}
                </>
                }
            </Paper>

        </div>

    );
            })
export default ResetPasswordView;
