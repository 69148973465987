import React, { useState, useContext, useEffect } from 'react';
import { SessionContext, ThemeContext, Login } from 'kalenis-portal-frontend-main'
import {Paper} from 'kalenis-web-components'
import {View_} from 'kalenis-web-components'
import { observer } from 'mobx-react-lite'


import {
    useHistory
} from "react-router-dom";




const UserContextView = observer(function UserContextView(props) {
    const connection = useContext(SessionContext);
    const user_context = connection.user_context
    const screen = user_context.screen

    // useEffect(() => {
    //     if (connection.status) {
    //         history.push('/home')
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [connection.status]);



    return (
        <>
        {screen &&
            <View_ screen={screen} containerStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }} />
        }
        
        </>
        

    );
});
export default UserContextView;
