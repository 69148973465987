import { makeAutoObservable, autorun, runInAction, makeObservable, computed, action, observable, values } from "mobx"
import { browseObject, formatDate, getSelectionOption, flatten, downloadFile, readFile, parse_domain } from '../common'
import moment from 'moment';
import clsx from 'clsx';
import {findTags, setImgPlaceholders} from '../html_template'
import { BinaryValue } from "./BinaryValue";
import {NewCalculatedValue} from './NewCalculatedValue'
import {Record} from './Record'



export const createField = (attributes, screen) => {
    switch (attributes.type) {
        case 'char': return new CharField(attributes, screen)
        case 'integer': return new IntegerField(attributes, screen)
        case 'float': return new FloatField(attributes, screen)
        case 'many2one': return new Many2OneField(attributes, screen)
        case 'multi_link': return new MultiLinkField(attributes, screen)
        case 'reference': return new ReferenceField(attributes, screen)
        case 'date': return new DateField(attributes, screen)
        case 'datetime': return new DateTimeField(attributes, screen)
        case 'selection': return new SelectionField(attributes, screen)
        case 'multi_selection': return new MultiSelectionField(attributes, screen)
        case 'boolean': return new BooleanField(attributes, screen)
        case 'one2many': return new One2ManyField(attributes, screen)
        case 'dynamic_one2many': return new DynamicOne2Many(attributes, screen)
        case 'button': return new Button(attributes, screen)
        case 'divider': return new Divider(attributes, screen)
        case 'text': return new TextField(attributes, screen)
        case 'dict': return new DictField(attributes, screen)
        case 'lookup': return new LookUpField(attributes, screen)
        case 'binary': return new BinaryField(attributes, screen)
        case 'html': return new HTMLField(attributes, screen)
        case 'formula': return createFormulaField(attributes, screen)
        case 'attachment': return new AttachmentField(attributes, screen)
        default: throw new Error("Unsupported Field Type: " + attributes.type)
    }
}



export class Field {

    get default_state_attrs() {
        
        return {
            'required': this.required,
            'readonly': this.screen.readonly ? this.screen.readonly:this.readonly,
            'invisible': !this.visible
        }
    }

    get grid_cls(){
        let cls = clsx("col-span-"+this.screen.default_columns, "md:col-span-"+this.colspan)
        if(this.column_start){
            cls = clsx(cls, 'md:col-start-'+this.column_start)
        }
        return cls
    }

    get parent_widget(){
        return this.screen.get_field_by_id(this.parent_widget_id)
    }
    
    
    constructor(attributes, screen) {
        if (screen) {
            this.screen = screen;
        }
        this.attributes = attributes;
        this.char_search = attributes.char_search
        this.colspan = attributes.colspan ? attributes.colspan:(this.screen && this.screen.default_columns) ? this.screen.default_columns:4
        this.column_start = attributes.column_start
        this.default_value = attributes.default_value
        this.description = attributes.description
        this.id = attributes.id
        this.lazy = attributes.lazy
        this.mobile_available = attributes.mobile_available
        this.name = attributes.name
        this.original_name = attributes.original_name
        this.readonly = attributes.readonly
        this.required = attributes.required
        this.searchable = attributes.searchable
        this.group_by = attributes.group_by
        this.sortable = attributes.sortable
        this.states = attributes.states
        this.type = attributes.type
        this.visible = attributes.visible
        this.width = attributes.width
        this.hide_value = attributes.hide_value
        this.hide_label = attributes.hide_label
        this.background_color = attributes.background_color
        this.text_color = attributes.text_color
        this.prefix = attributes.prefix
        this.in_edition = false
        this.height = attributes.height
        this.filter = attributes.filter || false
        // this.style = attributes.style ? JSON.parse(attributes.style):'',
        this.style = attributes.style ? this.parse_style(attributes.style):'',
        this.conditional_style = attributes.conditional_style
        this.conditional_style_dependencies = attributes.conditional_style_dependencies ? attributes.conditional_style_dependencies.split(';'):[]
        this.tooltip = attributes.tooltip || false
        this.freeze = attributes.freeze ? attributes.freeze:false
        this.content_only = attributes.content_only
        this.no_wrap = attributes.content_only ? true:attributes.no_wrap
        this.include_in_template = attributes.include_in_template
        this.sequence = attributes.sequence
        this.default_height = 1
        this.parent_widget_id = attributes.parent_widget
        this.on_change = attributes.on_change ? new Function('{value,record}', attributes.on_change) : false
        makeObservable(this, {
            in_edition: observable,
            set_edition:action,
            colspan:observable,
            sequence:observable

        })

    }

    parse_style(style){
        try{
            return JSON.parse(style)
        }
        catch(e){
            console.log("Invalid style on field:" + this.name)
            return ""
        }
    }
    //TODO: Check perf and memoize
    get_style(record){
        let style = {}
        if(this.style){
            style = this.style
        }

        style = {...style,...record.get_style(this.name)}
        
        return style
    }

    async get_conditional_style(calc){
        let args = {}
        let value = {}
        args['field'] = this.id
        args['data'] = calc.get_dependencies_values()
        
        const abortController = new AbortController();
        value = await this.screen.connection.dispatch('GET', '/field/conditional_style', args, false, true, false, abortController)
    
        
        return value
    }

    set_edition(record){
        
        this.in_edition = record.id
    }

    get_value(record) {

        return record.get_value(this.name)
    }

    //TODO: RECOVER PREFIX VALUE FORM RECORD IF its variable
    get_prefix_value(record) {
        return this.prefix
    }
    get_string_value(record, val) {
        
        let value = val ? val:this.get_value(record)
        if (value) {
            value = value.toString()
        }
        else {
            value = ""
        }
        let prefix = this.get_prefix_value(record)

        if (prefix) {
            value = prefix.concat(value)
        }

        return value
    }


    set_value(value, record) {
        record.set_value(this.name, value)
    }
    get_state_attrs(record) {
        let state_attrs = record.state_attrs ? record.state_attrs[this.name] ? record.state_attrs[this.name].get_value() : false :false
        if (!state_attrs) {
            state_attrs = { 'invisible': this.invisible, 'readonly': this.readonly, 'required': this.required }
        }
        if(this.parent_widget_id){
            if(this.parent_widget && this.parent_widget.collapsed){
                return {...state_attrs, 'invisible':true}
            }
        }
        if(this.screen.readonly){
            state_attrs['readonly'] = true
        }
        return state_attrs


    }


    has_value(record) {
        return Boolean(record._values[this.name])
    }

    extend_value(record){
        return
    }
    nameInFilters(fname){
        let filter_name = false
        if(!this.filter){
            return filter_name
        }
        return this.filter.filter_group.get_filter_fname(fname)
        
    }
    
    custom_on_change({ value, record }) {
        if (!record || !this.on_change) {
            return
        }
        

        try {
            this.on_change({ value, record })
            
        }
        catch (e) {
            let message = "A problem ocurred with the custom on_change method of the field: " + this.name + '  =>  '
            message += (e.toString())
            // throw new Error(message)
            this.screen.notifications.addSnack(
                { message: message, level: 'error', timeout: 5000 }
            )
        }



    }

    edit_field(){
        return this.screen.editor.openFieldScreen(this.id)
        

    }


}

class CharField extends Field {

    constructor(attributes, screen) {
        super(attributes, screen)
        // makeAutoObservable(this)
        // baseConstructor(this, attributes)
    }

    get_value(record) {
        return record._values[this.name] || ""
    }
    set_value(value, record) {

        record.set_value(this.name, value)
    }
}

class TextField extends CharField {

    constructor(attributes, screen) {
        super(attributes, screen)
        // makeAutoObservable(this)
        // baseConstructor(this, attributes)
    }

}

class IntegerField extends Field {

    constructor(attributes, screen) {
        super(attributes, screen)
        // makeAutoObservable(this)
        // baseConstructor(this, attributes)
    }

    get_value(record) {
        return record._values[this.name]
    }
    get_string_value(record, val) {
        let value = (!isNaN(val))? val:this.get_value(record)
        if (value || value === 0) {
            value = value.toString()
        }
        else {
            value = ""
        }
        let prefix = this.get_prefix_value(record)

        if (prefix) {
            value = prefix.concat(value)
        }

        return value
    }
    set_value(value, record) {
        value = (value || value==0) ? parseInt(value):null
        if(isNaN(value)){
            value = null
        }
        record.set_value(this.name, value)
    }
}

class FloatField extends Field {
    
    constructor(attributes, screen) {
        super(attributes, screen)
        
        
    }

    get_value(record) {
        return record.get_value(this.name)
    }
    get_string_value(record, val) {
        let value = (!isNaN(val))? this.get_formatted_value(false,val):this.get_formatted_value(record)
        if (value || value === 0) {
            value = value.toString()
        }
        else {
            value = ""
        }
        let prefix = this.get_prefix_value(record)

        if (prefix) {
            value = prefix.concat(value)
        }

        return value
    }
    set_value(value, record) {
        //TODO: Localization. Replace , by ., allowing users to input numbers in both ways.
        value = (value || value===0) ? Number(value.replace(',','.')):null
        record.set_value(this.name, value)
    }
    get_formatted_value(record, value){
        //TODO: Localization && extra args for float fields: digits
        const val = (!isNaN(value)) ? value:this.get_value(record)
        if(!val && val!==0){
            return null
        }
        const options = {
            minimumFractionDigits:2,
            maximumFractionDigits:20
        }
        
        return Number(val).toLocaleString('es', options);
        

    }


}

class Many2OneField extends Field {
    rec_name_field = ""
    key_fields = ""
    data_name = ""
    read_fields = ""
    no_cache = false


    constructor(attributes, screen) {
        super(attributes, screen)

        this.rec_name_field = attributes.rec_name_field || ""
        this.key_fields = attributes.key_fields ? attributes.key_fields.split(';') : null
        this.group_options = attributes.group_options
        this.value_field = attributes.value_field
        this.data_name = this.name.concat('.') || ""
        this.read_fields = attributes.read_fields ? attributes.read_fields.split(';'):[]
        this.no_cache = attributes.no_cache || false

    }

    get_key_fields_values(record) {
        if (!this.key_fields) {
            return ""
        }

        let values = {}
        // this.screen.parent.active_record
        let parent_record = this.screen.parent ? this.screen.parent.active_record : false
        
        function solve_keyword(record, keyword, expr){
            let res = record.keywords[keyword]()
            if(res instanceof Record){
                // The first field on expression is the keyword, 
                // so remove it to browse on the record returned
                expr = expr.replace(expr.split('.')[0]+'.',"")
                res = browseObject(res.get_all_values(), expr)
            }
            return res
        }

        function get_real_fname(record, fname){
            // Support translated fnames for multimodel views
            const real_field = record.screen.get_field_by_any_name(fname)
            if(!real_field){
                return fname
            }
            return real_field.name

        }

        this.key_fields.forEach(function (field) {
            let value = -1
            let fname = ""
            const original_expr = field
            let first_field = null

            if (field.includes('.')) {
                const spl = field.split('.')
                // fname = spl[0]
                fname = get_real_fname(record, spl[0])
                // first_field = spl[1]
                first_field = get_real_fname(record, spl[1])
                field = field.replace(spl[0], fname)
                field = field.replace(spl[1], first_field)
                
            }
            else{
                fname = get_real_fname(record, field)
                field = fname
            }
            const is_keyword = record.keywords.hasOwnProperty(fname)
            
            if (fname === '_parent_record') {
                if(parent_record && record.keywords.hasOwnProperty(first_field)){
                    // value = parent_record.keywords[first_field]()
                    value = solve_keyword(parent_record, first_field, field)
                }
                else{
                    value = browseObject(record.get_all_values(), field, {}, [], parent_record.get_all_values())
                }
                
            }
            else if (is_keyword) {
                value = solve_keyword(record, fname, field)
            }
            else{
                value = browseObject(record.get_all_values(), field)
            }
            
            if (Array.isArray(value)) {
                value = flatten(value)
            }
            if (!value) {
                value = -1
            }
            values[original_expr] = value
        })
        return values
    }


    //TODO: Async autocompletion
    async getOptions(record, inputValue) {
        let args = { 'id': this.id }

        let options = []
        const abortController = new AbortController();
        args['search'] = []
        const filter_options = (v) => {
            let res = v.id
            if (this.group_options) {

                res = browseObject(v.data, this.group_options)

            }

            return res
        }
        if (this.key_fields) {

            let key_field_values = this.get_key_fields_values(record)
            for (let field in key_field_values) {
                args['search'].push([field, '=', key_field_values[field]])
            }

        }
        const use_cache = !this.no_cache
        options = await this.screen.connection.dispatch('GET', '/field/field_options', args, false, use_cache, false, abortController)
        if(!options){
            options = []
        }
        options = options.map(function (option) {


            return {
                ...option,
                id: this.value_field ? browseObject(option, this.value_field) : option.id,
                rec_name: this.rec_name_field ? browseObject(option, this.rec_name_field) : option.rec_name,
                // Keep "data" for compatibility, will be deprecated
                data: option
            }
        }.bind(this)
        ).filter((v, i, a) => a.findIndex(t => (filter_options(t) === filter_options(v))) === i)
        
        return options;
    }
    //TODO: Check custom value field behavior
    get_value(record, raw = false) {


        let value = record.get_value(this.data_name)

        if (raw && value) {
            return value.id
        }
        if(this.rec_name_field && value){
            let val = {}
            const data = value.data ? value.data:value
            val.rec_name = browseObject(data,this.rec_name_field) || value.rec_name
            val.id = value.id
            value = val
        }
        return value ? value:null
    }
    get_string_value(record) {
        const val = this.get_value(record)
        const value = typeof val === 'object' && val !== null ? val.rec_name : val
        
        return value
    }

    set_value(value, record) {
        
        let values = {}
        values[this.name] = value ? value.id : null
        values[this.data_name] = value ? value : null
        record.set_values(values)
    }
    has_value(record) {
        return record._values[this.name] > 0
    }

    async read_current_values(record){
        let args = {}
        const abortController = new AbortController();
        args['ids'] = record._values[this.name]
        args['id'] = this.id
        return await this.screen.connection.dispatch('GET', '/field/get_option_values', args, false, false, false, abortController)
    }

    // Add data_name info to the record, based on current value && current options
    async extend_value(record){
        // let options = await this.getOptions(record)
        
        // let data_value = options.find(o => o.id === record._values[this.name])
        // if(!data_value){
        //     let readed_values = await this.read_current_values(record)
        //     console.log("Readed values getted")
        //     console.log(readed_values)
        //     data_value = readed_values
        // }
        // if(!data_value){
            let data_value = await this.read_current_values(record)
            if(data_value){
                data_value = data_value[0]
            }
        // }

        
        
        record.set_value(this.data_name, data_value)

        return data_value
    }
}

class MultiLinkField extends Many2OneField {

    constructor(attributes, screen) {
        super(attributes, screen)

    }

    get_rec_name(value){
        if(this.rec_name_field){
            
            return browseObject(value,this.rec_name_field) || value.rec_name
        }
        else{
            value = (value && value.rec_name) ?  value.rec_name:""
        }
        return value
    }

    get_string_value(record) {
        const value = this.get_value(record)
        
        if (!value) {
            return ""
        }
        else {
            return this.get_value(record).map(function (item) {
                 return this.get_rec_name(item) 
                }.bind(this)).toString()
        }

    }
    get_value(record) {


        const value = record.get_value(this.data_name)


        return value
    }
    has_value(record) {
        const value = record._values[this.name]
        return (value && value.length) > 0
        
    }
    async extend_value(record){
        console.log("EXTENDING MULTILINK FIELD VALUE")
        console.log(record._values[this.name])
        if(record._values[this.name]){
            let data_values = await this.read_current_values(record)
            record.set_value(this.data_name, data_values)
        }
        
        
        
        
    }
    /**
 * Execute action
 * @param {array}  value - Array with values => [id] || [{id,rec_name}]
 * @param {Record}  record - Record to set
 
 * @return {void} ""
 */
    set_value(value, record) {

        let values = {}
        let data_values = value ? value : null
        values[this.name] = value ? value.map(function (v) {

            return v.id ? v.id : v
        }) : null

        let no_data = value ? value.filter(function (v) { return !v.rec_name }) : []        
        values[this.data_name] = data_values
        record.set_values(values)
        if(no_data.length){
            this.extend_value(record)
        }
        

    }
}



class ReferenceField extends Many2OneField {
    constructor(attributes, screen) {
        super(attributes, screen)

    }
}

class SelectionField extends Field {
    selection_options = []
    constructor(attributes, screen) {
        super(attributes, screen)
        this.selection_options = attributes.selection_options || []

    }

    get_value(record, raw = false) {

        const values = this.selection_options.filter(function (option) { return option.value === record.get_value(this.name)}.bind(this))
        let value = values.length ? values[0] : []
        if (raw && !Array.isArray(value)) {
            value = value.value
        }
        return value

    }
    get_style(record){
        let style = {}
        const selected_option = this.selection_options.find(opt => opt.value === record.get_value(this.name))
        
        if(selected_option && selected_option.color){
            style['color'] = selected_option.color
        }
        return style
    }

    get_string_value(record) {
        const value = this.get_value(record)
        return value ? value.label : ""

    }
    set_value(value, record) {
        record.set_value(this.name, value.value)
    }
}

class MultiSelectionField extends Field {
    selection_options = []
    constructor(attributes, screen) {
        super(attributes, screen)
        this.selection_options = attributes.selection_options || []

    }

    get_value(record) {
        const vals = record.get_value(this.name)

        if (!vals) {
            return vals
        }
        else {
            let res = vals.map(function (val) {
                return this.selection_options.filter(function (option) { return option.value === val }.bind(this))[0]
            }.bind(this))

            return res
        }


    }

    get_string_value(record) {
        const values = this.get_value(record)
        if (!values) {
            return ""
        }
        return values.map(function (val) { return val.label }).toString()


    }

    set_value(value, record) {
        if (!value) {
            value = []
        }
        const values = value.map(function (val) {

            return val.value ? val.value : val
        })
        record.set_value(this.name, values)
    }

}





class DateField extends Field {
    min_date = ""
    max_date = ""
    constructor(attributes, screen) {
        super(attributes, screen)
        this.min_date = attributes.min_date
        this.max_date = attributes.max_date
    }

    get_value(record) {
        const val = record.get_value(this.name)
        if(!val){
            return null
        }
        return moment(record.get_value(this.name))

    }

    get_string_value(record, val) {
        const value = val ? val:record.get_value(this.name)
        return formatDate(value, 'date')
    }

    date_from_string(val){
        let vals = val.split('-')
        return new Date(parseInt(vals[0]), parseInt(vals[1])-1, parseInt(vals[2]))
    }

    parse_limit_value(value, record){
        let res = false
        if(value === '{_today}'){
            res = new Date()            
        }
        //here => reverse field names of filters
        else if (value.includes('{')){

            
            let expr = value
            let fname = expr.replace('{', "").replace('}', "")
            if(this.filter){
                const expr_filter = this.nameInFilters(fname)
                expr = expr.replace(fname,expr_filter.id)
                fname = expr_filter.id
            }

            const expr_value = browseObject(record._values,expr)

            //browseObject returns the expression field if no values was found
            if(expr_value!=fname){
                
                res = this.date_from_string(expr_value)
                
            }

            
        }
        return res
    }


    get_min_value(record){
        if(!this.min_date){
            return false
        }
        const min = this.parse_limit_value(this.min_date, record)
        if(this.get_value(record).toDate() < min){
            
            this.set_value("",record)
        }
        
        return min
        
    }

    get_max_value(record){
        if(!this.max_date){
            return false
        }
        const max = this.parse_limit_value(this.max_date, record)
        if(this.get_value(record).toDate() > max){
            this.set_value("",record)
        }
        
        return max
    }

}

class DateTimeField extends DateField {
    constructor(attributes, screen) {

        super(attributes, screen)
    }
    get_value(record){
        const val = record.get_value(this.name)
        if(!val){
            return null
        }
        return moment.utc(record.get_value(this.name)).local()
    }
    get_string_value(record, val) {
        const value = val?val:record.get_value(this.name)
        return formatDate(value, 'datetime')
    }

}

class Button extends Field {
    button_type = "primary"

    get action() {
        return this.screen.actions.find(act => act.id === this.action_id);

    }
    constructor(attributes, screen) {
        super(attributes, screen)

        makeObservable(this, {
            action: computed,

        })
        this.action_id = attributes.action
        this.button_variant = attributes.button_variant || 'primary'

    }
}

class Divider extends Field {
    collapsible = false
    collapsed = false
    constructor(attributes, screen) {
        super(attributes, screen)
        this.collapsible = attributes.collapsible
        this.collapsed = attributes.default_collapsed
        makeObservable(this, {
            collapsible: observable,
            collapsed:observable,
            collapse:action
        })
    }

    collapse(record){
        this.collapsed = !this.collapsed
        let childs = this.screen.visible_fields.filter(f => f.parent_widget_id == this.id)
        childs.forEach(function(child){
            child.get_state_attrs(record)
        })
    }
}



class BooleanField extends Field {

    constructor(attributes, screen) {
        super(attributes, screen)

    }

    get_value(record) {
        return Boolean(record.get_value(this.name))
        // let val = record.get_value(this.name)
        // if(val){
        //     val = true
        // }
        // else{
        //     val = false
        // }
        // return val
    }
}

class One2ManyField extends Many2OneField {
    screen = false;
    current_record = false;
    initialized = false;
    list_active_record = false;
    key_fields = ""

    get parent_widget(){
        return this.parent.get_field_by_id(this.parent_widget_id)
    }

    constructor(attributes, screen) {
        super(attributes)
        this.view = attributes.display_view
        this.view_form = attributes.display_form_view
        this.record_form_action = attributes.record_form_action
        this.confirm_form_action = attributes.confirm_form_action
        this.domain = attributes.domain
        this.parent = screen;
        this.active_record = false
        this.new_record_button = attributes.new_record_button ? attributes.new_record_button:false
        this.key_fields = attributes.key_fields ? attributes.key_fields.split(';') : null
        this.default_height = 6

        makeObservable(this, {
            initialize: action,
            initialized: observable,
            screen: observable,
            list_active_record:observable,
            set_list_active_record:action,
            

        })

    }
    edit_field(){
        return this.parent.editor.openFieldScreen(this.id)
        

    }
    get_key_fields_values(record){
        let key_values = super.get_key_fields_values(record)
        return JSON.stringify(key_values)
    }



    // get_key_fields_values(record) {
    //     if (!this.key_fields) {
    //         return ""
    //     }

    //     let values = {}
    //     // this.screen.parent.active_record
    //     let parent_record = this.screen.parent ? this.screen.parent.active_record : false
    //     const record_values = {...record.get_all_values()}

    //     this.key_fields.forEach(function (field) {
    //         let value = -1

    //         let fname = field

    //         if (field.includes('.')) {
    //             fname = field.split('.')[0]
    //         }

    //         if (fname === '_parent_record') {
    //             value = browseObject(record_values, field, false, [], parent_record._values)


    //         }
    //         else if (record_values[fname]) {
    //             // const record_values = {...record.get_all_values()}
    //             value = browseObject(record_values, field,false)

    //         }
    //         if (Array.isArray(value)) {
    //             value = flatten(value)
    //         }
    //         if (!value) {
    //             value = -1
    //         }
    //         values[field] = value
    //     })

    //     return JSON.stringify(values)
    // }

    get_domain(record){
        if(!record){
            record = this.current_record
        }
        let domain = this.domain
        if(typeof domain == 'string'){
            domain = parse_domain(record.get_all_values(),this.domain,false,this.parent.parent_selected_records)        
        }

        
        const initial_search = { current_search: [] }
        if (domain) {
            
            initial_search.current_search = domain
        }

        return initial_search
    }
    /**
     * Initialize o2m Field Screen
     * @param record {Record} Parent record for the screen
     * Creates o2m screen and attach it to parent screen
     
     * @return O2M Screen ""
     */
    initialize(record, force_reload, callback=false) {

        if (record.id === this.current_record.id && !force_reload) {
            return false
        }
        this.current_record = record;
        const local_values = this.current_record.get_value(this.name)
        const initialize_data = local_values ? false:true
        const intialize_callback = local_values ? (screen)=> screen_callback(record,screen):false

        // The field domain is now added on the SpreadsheetScreen do_search
        // const initial_search = this.get_domain(record)
        const initial_search = {
            'current_search':[]
        }

        const screen_callback = (record, screen) => {
            if(record){
                if(callback){
                    return callback(record)
                }
    
            }

        }

        
        // addScreen(view, history, initial_search, route_state = {}, set_active = true, is_modal = false, parent = false, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false, data_callback=false) {
        screen = this.parent.field_childs.addScreen(this.view, false, initial_search, {}, false, true, this.parent, true,true, initialize_data, intialize_callback)
        screen.parent = this.parent
        screen.auto_save = false
        // screen.filterable = false
        screen.set_title(this.description)
        
        screen.set_readonly(this.get_state_attrs(record).readonly)
        if(this.record_form_action){
            let actions_map = {}
            let add_record_button = this.new_record_button ? this.new_record_button:false
            if(add_record_button){
                add_record_button['execute'] = ()=>{this.openFormView()}
                screen.set_add_record_button(add_record_button)
            }
            

            actions_map[this.record_form_action.name] = (records) => { this.openFormView(records[0]) }
            screen.set_actions_map(actions_map)
        }
        
        this.screen = screen
        this.initialized = true
        this.screen.set_field_instance(this)
        return screen


    }
    get_local_values(){
        return this.current_record.get_value(this.name)
    }
    apply_local_values(){
        const values = this.get_local_values()
        this.addRecords(values)
    }
    reload(record){
        if(!this.initialized){
            return
        }
        const local_values = this.current_record.get_value(this.name)
        // Check if local values are id only (happens when values are readed from backend) and just reload the full data
        if(local_values && !local_values.every(function(element) {return typeof element === 'number';})){

            this.apply_local_values()
        }
        else{
            let domain = this.get_domain(record)
            this.current_record = record
            if(this.screen.start_empty && (!domain || !domain.length)){
                return
            }
            this.screen.do_search(domain)
        }
        

    }

    cleanValues(values){
        // remove calculated values when opening /confirming form
        let cleaned_values = {...values}
        const fnames = this.screen.field_names;
        for(let fname in values){
            
            if(!fnames.includes(fname) && !fname.endsWith('.')){
                delete cleaned_values[fname]
            }

            else{
                let field = this.screen.get_field_by_name(fname)
                if(field && ['lookup', 'formula'].includes(field.type)){
                    delete cleaned_values[fname]
                }

            }
        }
        return cleaned_values
    }

    confirmForm(records, screen, callback) {

        const record = records[0]

        //delete id if new record on form, the new record will take the group next new id
        if (record._values.id < 0) {
            delete record._values['id']
        }
        // const fnames = this.screen.field_names;
        const values = this.cleanValues({...record.get_all_values()})
        
        //delete values on form if are not included on list screen
        
        // for(let fname in values){
            
        //     if(!fnames.includes(fname) && !fname.endsWith('.')){
        //         delete values[fname]
        //     }

        //     else{
        //         let field = this.screen.get_field_by_name(fname)
        //         if(field && ['lookup', 'formula'].includes(field.type)){
        //             delete values(fname)
        //         }

        //     }
        // }
        console.log("VALUES ON CONFIRM FORM o2m")
        console.log(values)
        if(this.list_active_record){
            this.list_active_record.set_values(values)
            this.set_list_active_record(false)

        }
        else{
            this.screen.data.addRecord({ values: values, index: this.screen.data.length })
            this.screen.data.setChanged(true)
        }

        
        this.screen.modal_childs.removeScreen(screen)
        if (callback) {
            callback()
        }
        this.custom_on_change({value:this.screen.data.records, record:this.screen.data.records})
    }

    openFormView(record, callback) {

        if (!this.view_form) {
            return false
        }
        const initialize_data = record ? false:true
        this.set_list_active_record(false)

        //if record, executed when the screen is fully initialized
        const screen_callback = (record, screen) => {
            if(record){
                const form_record = screen.data.addRecord({ values: {}, index: 0 })
                screen.set_active_record(form_record)
                // let new_values = record.get_all_values()
                let new_values = this.cleanValues(record.get_all_values())
                console.log("SET NEW VALUES ON FORML VIEW")
                console.log(new_values)
                form_record.set_values(new_values)
                this.set_list_active_record(record)
                
                
    
            }

        }
        
        const form_screen = this.screen.modal_childs.addScreen(this.view_form, false, {}, {}, true, true, this.parent, true,true,initialize_data, (screen)=> screen_callback(record,screen) )
        
        const confirm_action_name = this.confirm_form_action.name
        let actions_map = {}
        actions_map[confirm_action_name] = (records) => { this.confirmForm(records, form_screen.id, callback) }
        form_screen.set_actions_map(actions_map)
        if(this.record_form_action.modal_style){
            form_screen.add_style({'modal':JSON.parse(this.record_form_action.modal_style)})    
        }
        


    }

    set_list_active_record(record){
        this.list_active_record = record;
    }

    has_value(record) {
        let res = true
        if (!this.initialized) {
            return false
        }

        if (this.screen.data.records.length < 1) {
            res = false
        }
        // Check also if all records are valid
        else {
            this.screen.data.records.forEach(function (rec) {
                if (!rec.validate()) {
                    res = false
                }
            })
        }
        return res
    }

    get_value(record){
        if(!this.initialized || !this.screen.data.records){
            return []
        }
        return this.screen.data.records.map(function(rec){return rec.get_all_values()})
        // return this.screen.data.records ? this.screen.data.records:[]
    }

    // All current values will be replaced by new ones
    // TODO: Check saved records case (not being deleted), 
    // currently, this method is only used on template load
    set_value(value, record){
        this.clearRecords()
        this.addRecords(value)
    }

    addRecord({values,index}){
        let record = this.screen.data.addRecord({values,index})
        this.screen.data.setChanged(true)
        return record
    }
    addRecords(values){
        let records = values.map(function(val){
            return this.addRecord({values:val})
        }.bind(this))
        return records
    }
    getRecords(){
        return this.screen.data.records
    }
    clearRecords(){
        return this.screen.data.clearRecords()
    }
    async extend_value(record){
        const template_fnames = this.screen.template_fields.map(function(f){return f.name})
        this.screen.data.records.forEach(function(rec){
            rec.extend_values(template_fnames)
        })

    }





}

class DynamicOne2Many extends Field {
    childs = []
    constructor(attributes, screen) {
        super(attributes, screen)
        console.log("DYnamic Constructor")
        makeObservable(this, {
            childs: observable,
            set_childs: action,

        })
    }

    set_childs(values){
        console.log("VALUES to set")
        console.log(values)
        this.childs = values.map(function(attributes){return createField(attributes, this.screen)}.bind(this))
        console.log(this.childs)
        this.screen.extend_fields(this.childs)
    }

    
    

}

class DictField extends Field {

    constructor(attributes, screen) {
        super(attributes, screen)
    }

    get_value(record) {
        return record.get_value(this.name)
    }

    get_string_value(record) {
        let value = record.get_value(this.name) || false
        let string = ""
        if (value) {
            const addValue = (k) => {
                return k.concat(':').concat(value[k].toString()).concat(';')
            }
            for (let k in value) {
                string = string.concat(addValue(k))
            }
        }
        return string

    }
}

class LookUpField extends Field {
    expression = ""
    depends = []

    get pre_readed_value(){
        // const fname = this.depends[0]
        const field = this.root_field
        if(field && field.type == 'dict'){
            return true
        }
        else if(field && field.read_fields){
            return field.read_fields.includes(this.expression.replace(field.name+'.',''))
        }
        return false

    }
    get root_field(){
        const fname = this.depends[0]
        return this.screen.get_field_by_name(fname)
    }

    constructor(attributes, screen) {
        super(attributes, screen)
        this.expression = attributes.lookup_expression
        this.depends = [this.expression.split('.')[0]]
        

    }

    get_value(record) {
        let value = ""
        if (this.expression === this.name) {
            return record._values[this.name]
        }
        else if(this.pre_readed_value){
            let local_value = browseObject(record._values, this.expression)
            if((local_value || local_value==0) && local_value != this.expression){
                return local_value
            }
            return " "
        }

        if (record._values[this.name]) {
            console.log("LOOKUP FOR ?")
            console.log(this.name)
            console.log(record._values[this.name])
            value = record._values[this.name].get_value()
        }
        else {
            value = record.set_calculated_value(this)
        }
        return value

    }

    get_string_value(record) {
        let value = this.get_value(record)
        if(this.prefix){
            value = this.get_prefix_value(record).concat(value)
        }
        return value

    }
    get_dependencies(record) {
        let values = {}
        this.depends.forEach(function (d) {
            values[d] = record.get_value(d)
        })
        return values
    }
    is_loading(record) {
        let loading = true
        if (record._values[this.name]) {
            loading = record._values[this.name].loading
        }
        return loading


    }

    async get_lookup_value(record) {
        let args = {}
        let value = ""

        args['field'] = this.id
        args['data'] = this.get_dependencies(record)
        if (Object.values(args['data']).includes(null) || Object.values(args['data']).includes(undefined)) {
            return ""
        }
        const abortController = new AbortController();
        value = await this.screen.connection.dispatch('GET', '/field/lookup_value', args, false, true, false, abortController)
        return value
    }
}

class BinaryField extends Field {
    filename_field = ""
    constructor(attributes, screen) {
        super(attributes, screen)
        this.filename_field = attributes.filename_field
    }

    async get_binary_value(record) {
        const abortController = new AbortController();
        const args = {
            'field': this.id,
            'id': record.id
        }
        let value = await this.screen.connection.dispatch('GET', '/field/binary_value', args, false, false, true, abortController)


        //REVIEW: Currently this drops support for json files download
        if (value) {
            
            
            if (value.type === 'application/json') {
                value = false;
            }
            else {
                value = await readFile(value.data)
                
            }

        }

        else {
            value = false
        }

        return value
    }
    get_value(record) {

        return record.set_binary_value(this)

    }

    get_string_value(record){
        return this.get_filename(record)
    }
    async get_data(record){
        
        return await record._values[this.name].get_data()
    }
    async download_content(record){
        const notification = this.screen.notifications.addSnack(
            { message: "Descarga en curso...", persistant: true }
        )
        const filename = this.get_filename(record)     
        // temporary hack until the invisible formula resolution gets improved
        const data = await this.get_data(record)
        if(filename instanceof Promise){
            filename.then(function(val){
                downloadFile(data, val)
            }.bind(this))
        }
        else{
            downloadFile(data, filename)
        }
        notification.remove()
                this.screen.notifications.addSnack(
                    { message: "Descarga Completa" }
            )
        
        

        
    }
    get_filename(record){
        let filename = ""
        if(this.filename_field){
            filename = this.screen.get_field_by_name(this.filename_field).get_string_value(record)
        }

        if(!filename && this.has_value(record)){
            filename = this.get_value(record).name
        }
        return filename
        
    }
    is_loading(record) {
        return false
        let loading = true
        if (record._values[this.name]) {
            loading = record._values[this.name].loading
        }
        return loading


    }

    set_value(value, record){
        record._values[this.name].set_value(value)
        record.set_changed([this.name])
        
    }
    
    has_value(record) {
        const val = this.get_value(record)
        if(!val || !val.data){
            return false
        }
        return true
    }
}

class AttachmentField extends BinaryField {
    data_name = ""
    constructor(attributes, screen) {
        super(attributes, screen)
        this.data_name = this.name.concat('.') || ""
    }
    async get_binary_value(record) {
        console.log("Getting binary value from attachment")
        const id = this.get_value(record).id
        let data = null
        if(id && id > 0){
            data = await this.screen.attachment_handler.get_by_id(id, true)
        }
        console.log("DATA on binary value")
        console.log(data)
        return data

        
    }

    get_filename(record){
       return this.get_string_value(record)
        
    }

    get_string_value(record){
        if(!record){
            return ""
        }
        let val = record.get_value(this.data_name)
        if(val){
            return val.rec_name
        }
        return ""
    }


   
}

class HTMLField extends Field {
    constructor(attributes, screen) {
        super(attributes, screen)
        
    }


    get_value(record) {

        return setImgPlaceholders(record.get_value(this.name) || "")
    }

    // get_string_value(record){
    //     let value = record.get_value(this.name) || false
    //     let string = ""
    //     if(value){
    //         const addValue = (k) =>{
    //             return k.concat(':').concat(value[k].toString()).concat(';')
    //         }
    //         for(let k in value){
    //             string = string.concat(addValue(k))
    //         }
    //     }
    //     return string

    // }
}

class FormulaField extends Field {
    formula_field_widget=""
    formula_type = ""
    formula_dependencies = []
    field_widget = ""

    
    constructor(attributes, screen) {
        super(attributes, screen)
        this.formula_field_widget = attributes.formula_field_widget
        this.formula_type = attributes.formula_type
        this.formula_dependencies = attributes.formula_dependencies ? attributes.formula_dependencies.split(';'):[]
        this.readonly = true
        const widget_attributes = {...attributes}
        widget_attributes['type'] = attributes.formula_field_widget
        this.field_widget = createField(widget_attributes, screen)
        this.selection_options = attributes.selection_options
    }

    is_loading(record){
        if(record._values[this.name]){
            return record._values[this.name].loading
        }
        
    }    

    get_formatted_value(record){
        return this.field_widget.get_formatted_value(false,this.get_value(record))
    }
    

    get_value(record) {

        let value = ""
        
        if (record._values[this.name] && record._values[this.name].hasOwnProperty('get_value')) {
         
                value = record._values[this.name].get_value()
            
        }
        else {


            value = record.set_new_calculated_value(this)
        }
        return value

    }
    get_string_value(record, value=false){
        value = value ? value: this.get_value(record)
        if(value instanceof Promise){
            value.then(function(val){
                return val ? val.toString():""
            })
            
        }
        return value ? value:""
    }


    async get_calculated_value(calc) {
        let args = {}
        let value = ""

        args['field'] = this.id
        args['data'] = calc.get_dependencies_values()
        

        // if (Object.values(args['data']).includes(null)) {
        //     return ""
        // }
        for(let k in args['data']){
            if(args['data'][k] === null){
                args['data'][k] = ""
            }
        }
        const abortController = new AbortController();
        value = await this.screen.connection.dispatch('GET', '/field/formula_value', args, false, true, false, abortController)
        return value
    }


}

class InlineFormulaField extends FormulaField{
    formula_value = ""
    constructor(attributes, screen) {
        super(attributes, screen)
        this.formula_value = attributes.formula_value
    }

    

}

class BackendCustomFormula extends FormulaField {
    backend_method_name = ""
    constructor(attributes, screen) {
        super(attributes, screen)
        this.backend_method_name = ""
    }

}

class FrontendCustomFormula extends FormulaField {
    formula_js_value=""
    constructor(attributes, screen) {
        super(attributes, screen)
        this.formula_js_value = attributes.formula_js_value
        this.formula = attributes.formula_js_value ? new Function('{values, record}', attributes.formula_js_value) : false
    }

    async get_calculated_value(calc) {
        let args = {}
        args['field'] = this.id
        let values = calc.get_dependencies_values()
        try {
            return this.formula({values, 'record':calc.record})
            
            
        }
        catch (e) {
            let message = "A problem ocurred with the formula field: " + this.name + '  =>  '
            message += (e.toString())
            this.screen.notifications.addSnack(
                { message: message, level: 'error', timeout: 5000 }
            )
        }
        
        
    }

}

const FORMULA_FIELDS = {
    'formula': InlineFormulaField,
    'custom_method': BackendCustomFormula,
    'frontend_custom_method': FrontendCustomFormula
}
const createFormulaField = (attributes, screen) => {

    return new FORMULA_FIELDS[attributes.formula_type](attributes, screen)
}

// const FORMULA_FIELDS = {
//     'formula': FormulaField,
//     'custom_method':BackendCustomFormula,
//     'frontend_custom_method':FrontendCustomFormula
// }