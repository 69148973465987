import React from 'react';
import CheckBox from './CheckBox'
const base_cls = "text-center"

function BooleanField(props) {

    const {state_attrs} = props

    return (

        <div className="w-full text-center">
            <CheckBox
                id={props.id || props.name}
                inputRef={props.inputRef}
                name={props.name}
                label={props.label}
                required={state_attrs.required}
                readonly={state_attrs.readonly}
                className={base_cls}
                value={props.value}
                onChange={(e)=>{
                    props.onChange(e, e.target.checked)
                    props.onBlur(e, e.target.checked)
                }}
                type='checkbox'
                onKeyDown={props.handleKeyDown}
                style={props.style}
                autoFocus={props.autoFocus}

            />
        </div>



    )

}

export default BooleanField;