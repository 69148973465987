import React, { useEffect, useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';


function CodeText(props) {
    const {
        field,
        onFocus,
        state_attrs,
        record,
        language
    } = props;

    return (
        
            <CodeEditor
                id={props.id || props.name}
                value={props.value}
                language={language}
                placeholder={field.description}
                onChange={(e) => { props.onChange(e, e.target.value) }}
                onBlur={props.onBlur}
                padding={15}
                onFocus={onFocus}
                style={{
                    fontSize: 12,
                    backgroundColor: "#f5f5f5",
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
            />
            

        
    )
}

export function PythonEditor(props){
    return <CodeText
                language="py"
                {...props}
            />
}

export function JavascriptEditor(props){
    return <CodeText
                language="js"
                {...props}
            />
}
