import {environment} from '../common'

let rnfa = false
try{
    rnfa = require('react-native-fetch-api')
    // const {fetch} = require('react-native-fetch-api')
}
catch(e){
    console.log("Web environment, no polyfills")
    
}
export const {fetch_} = environment() === 'web' ? {fetch_:fetch}:{fetch_:fetch}

export default fetch_