import React, { useRef } from 'react';

import BinaryField from '../BinaryField';


function BinaryImagePreview(props) {

        return (
           
            <BinaryField 
                widget='image' 
                {...props} />
            
        )
    
            }

export default BinaryImagePreview;