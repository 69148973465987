import React, {useEffect} from "react";
import Field from "./Field.js";
import Select from "react-select";
import IconButton from "../buttons/IconButton";
import { ReactComponent as MinusIcon } from "../icons/svg/minus-solid.svg";
import { observer } from "mobx-react-lite";

const icon_cls = "fill-current text-xl cursor-pointer";

const customInputStyle = {
  // border: "1px solid rgba(203, 213, 224, 1)",
  minHeight: "2.25rem",
  borderRadius: "0.25rem",
  paddingLeft: "0.625rem",
  paddingRight: "0.625rem",
  paddingTop: "0rem",
  paddingBottom: "0rem",
};

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "0rem",
    marginTop: "0.125rem",
  }),

  control: (provided, { isDisabled, menuIsOpen, isFocused }) => {
    return {
      ...provided,
      borderRadius: "4px",
      borderColor: menuIsOpen
        ? "rgba(var(--color-primary, 1))"
        : "rgba(203, 213, 224, 1)",
      boxShadow: isFocused ? "0 0 0 1px rgba(var(--color-primary, 1))" : "none",
      minHeight: "2.25rem",
      top: "10%",
      backgroundColor: isDisabled ? "rgba(203, 213, 224, .3)" : "transparent",
      ":hover": {
        borderColor: !isFocused ? "rgba(var(--color-primary, 1))" : "none",
      },
    };
  },
  
  menuPortal: (provided, state) => ({
    ...provided,
    // left: provided.left - 80,
    zIndex: 9999,
    minWidth: 200,
  }),
  valueContainer: (provided, state) => ({
    // display: 'none'
    ...provided,
    cursor: "pointer",
    padding: "0.25rem 0.5rem",
    paddingRight: "0rem",
    textOverflow: "ellipsis",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "inherit",
  }),

  placeholder: (provided, state) => ({
    ...provided,
    left: "0.45rem",
    right: "0",
    color: "inherit",
    fontWeight: "400",
    opacity: "0.5",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: "1.5rem",
    top: "40%",
  }),

  container: (provided, state) => ({
    ...provided,
  }),
  
  indicatorContainer: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: "1.5rem",
  }),

  clearIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    margin: 0,
  }),

  dropdownIndicator: (provided, { isDisabled }) => {
    return {
      ...provided,
      cursor: "pointer",
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: "1.5rem",
      display: isDisabled ? "none" : "inline",
    };
  },

  multiValue: (provided, state) => ({
    ...provided,
    border: "1px solid #CBD5E0",
    backgroundColor: "white",
    borderRadius: "5rem",
    paddingLeft: ".25rem",
    paddingRight: ".25rem",
    marginBottom: ".25rem",
    margintop: "0rem",
  }),

  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "rgba(113, 128, 150,1)",
    fontWeight: "500",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    opacity: "0.45",
    ":hover": { opacity: "1", transition: "0.3s" },
  }),

  option: (provided, { isSelected, isFocused }, state) => {
    return {
      ...provided,
      fontWeight: "400",
      cursor: "pointer",
      color: isSelected ? "white" : "rgba(113, 128, 150, 1)",
      /*  backgroundColor:
        isFocused && !isSelected
          ? "rgba(113, 128, 150, 0.2)"
          : isSelected
          ? "rgba(113, 128, 150, 0.75)"
          : "none",*/
      backgroundColor:
        isFocused && !isSelected
          ? "rgba(var(--color-primary), 0.15)"
          : isSelected
          ? //   ? "rgba(var(--color-primary), 0.55)"
            "rgba(113, 128, 150, 0.8)"
          : "none",
    };
  },
};

const initialState = {
  operator_options: [],
};

const Filter = observer(function Filter(props) {
  /*  **************** FOCUS AUTOMATICO  **************** */

  const { filter, removeFilter, record, fieldWrapperStyle } = props;
  const defaultFilters = record.screen.default_filters

  useEffect(() => {
    let activeElementTabIndex = document.activeElement.tabIndex;
    let activeElementName = document.activeElement.tagName;
    let activeElementOpacity = document.activeElement.style.opacity;
    let activeElement = document.activeElement;
    const getInputs = document.querySelectorAll(`.field-filter__input input`);
    const getOperators = document.querySelectorAll(`.operator input`);
    let arrInputs = Array.from(getInputs);
    let arrOperators = Array.from(getOperators);
    let lastInput = arrInputs[arrInputs.length - 1];
    let prevLastInput = arrInputs[arrInputs.length - 2];
    //let indexLastInput = arrInputs.indexOf(lastInput);
    let indexActiveElement = arrInputs.indexOf(document.activeElement);
    let indexActiveOperator = arrOperators.indexOf(document.activeElement);

    /*console.log("arrInputs.length :" + arrInputs.length )
    console.log("activeElementName :" + document.activeElement.tagName )
    console.log("defaultFilters :" + defaultFilters ) */
    
    if (activeElementName === "BUTTON" && ((arrInputs.length - defaultFilters.length) === 1)) {
      setTimeout(() => {
        lastInput.focus({
          preventScroll: true,
        })
        console.log("CASO 1")
      });
    } 

    else if (activeElementName === "BODY" && arrInputs.length === 2) {
      setTimeout(() => {
        prevLastInput.focus({
          preventScroll: true,
        })
        console.log("CASO 1.1")
      });
    } 

  /*  if (activeElementName !== "INPUT" && arrInputs.length === 2) {
      prevLastInput.focus({
        preventScroll: true,
      });
    }    */

    else if (activeElementName === "BUTTON" && arrInputs.length > 2) {
        prevLastInput.focus({
        preventScroll: true,
      });
      console.log("CASO 2")
    } 
    
  /*  else if (activeElementName !== "INPUT" && arrInputs.length === 2) {
      prevLastInput.focus({
        preventScroll: true,
      });
    }     */

    else if (
      activeElementName !== "INPUT" &&
      arrInputs.length > 2 &&
      activeElementTabIndex === -1 &&
      activeElementOpacity === ""
    ) {
      prevLastInput.focus({
        preventScroll: true,
      });
      console.log("CASO 3")
    } else if (
      activeElementName === "INPUT" &&
      arrInputs.length > 2 &&
      activeElementTabIndex === 1 &&
      activeElementOpacity === "0" &&
      arrInputs[indexActiveElement + 1] !== undefined
    ) {
      setTimeout(() => {
        arrInputs[indexActiveElement + 1].focus({
          preventScroll: true,
        });
        console.log("CASO 4")
      });
    } else if (
      indexActiveOperator !== -1 &&
      activeElementTabIndex === 0 &&
      activeElementName === "INPUT" &&
      arrInputs[indexActiveElement + 1] !== undefined
    ) {
      setTimeout(() => {
        arrInputs[indexActiveElement + 1].focus({
          preventScroll: true,
        });
        console.log("CASO 5")
      });
    } else {
      activeElement.focus({
        preventScroll: true,
      });
      console.log("CASO 6")
    }

  });

  return (
    <div className="w-full">

      <div className="flex">
        {!filter.is_field_readonly && (
              /*  <IconButton
                    onClick={() => {
                      removeFilter(filter);
                    }}
                    color="warning"
                  >
                    <DeleteIcon
                      style={{ width: ".8em", height: ".8em" }}
                      className="fill-current text-base cursor-pointer w-4 h-2"
                    />
                  </IconButton>*/
            <IconButton
            title="Eliminar"
            onClick={() => {
              removeFilter(filter);
            }}
            color="warning"
            className="h-9 pt-0 pb-0 pl-2 pr-2"
            >
            <MinusIcon className={icon_cls} />
          </IconButton>
                )}
      {!filter.hide_modifiers && (
      <React.Fragment>
        <div style={{ width: "35%"}}>
          <Select
            isSearchable
            value={filter.field}
            styles={customStyles}
            placeholder={"Campo"}
            isDisabled={filter.is_field_readonly}
            // menuPortalTarget={document.body}
            menuPosition={"fixed"}
            onChange={(value) => {
              filter.setField(value);
            }}
            options={filter.field_options}
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => option["description"]}
            tabIndex={1}
            classNamePrefix="field-filter"
            className="appearance-none px-3 m-0 font-medium"
          />
        </div>

        <div style={{ width: "25%"}}>
          <Select
            isSearchable
            classNamePrefix="operator field-filter"
            value={filter.operator}
            className="appearance-none px-3 m-0 uppercase text-sm font-normal"
            styles={customStyles}
            placeholder={"Operador"}
            isDisabled={filter.is_operator_readonly}
            // menuPortalTarget={document.body}
            menuPosition={"fixed"}
            onChange={(value) => {
              filter.setOperator(value);
            }}
            options={filter.operator_options}
          />
        </div>
      </React.Fragment>
      )
      }

      <div className="field-filter__input appearance-none pl-0 m-0" style={{ width: filter.hide_modifiers ? "100%":"35%"}}>
        {filter.field && 
        <Field 
          field={filter.field} 
          classNamePrefix="field-filter" 
          record={record} 
          styles={customStyles} 
          style={customInputStyle} 
          fieldWrapperStyle={fieldWrapperStyle} 
          content_only={false} 
          className=" border border-gray-400 hover:border-primary focus:shadow-primary" />
        }
      </div>
      </div>
    </div>
  );
});

export default Filter;
