import React, { useState, Fragment } from 'react';
import { observer } from 'mobx-react-lite'


const ViewEditionToolbar = observer(function ViewEditionToolbar(props) {
    const {screen} = props
    const editor = screen.editor
    return (
        <div className='text-gray-600 border-r border-gray-300 text-xs font-medium'>Pantalla: {screen.editor.current_breakpoint}</div>




    )

});
export default ViewEditionToolbar;