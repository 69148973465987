import React from 'react';

function EmptyWidget(props) {
    const { field, state_attrs, record } = props;
    const widget = field.attributes.widget
    return (
        <div>  
            <div className="text-red-600">Sorry, the widget {widget} does not exist.</div>       
        </div>





    )

        }


export default EmptyWidget;