import React from 'react';
import { View_ } from './View.js'
import { Modal, IconButton } from 'kalenis-portal-frontend-main'
import { ReactComponent as CloseIcon } from '../ui/icons/svg/close.svg';
import { observer } from 'mobx-react-lite'

const ModalView = observer(function ModalView(props) {

    const { screen } = props
    const modal_style = screen.style_modifiers.styles.modal || {}
    const style = { height: 'calc(100% - 101px)',  backgroundColor: 'transparent', boxShadow: 'none', ...modal_style }
    // const style = {height: window.innerHeight, backgroundColor:'transparent',boxShadow:'none'}

    const classname = screen.style_modifiers.classnames.modal || ""


    
    return (
        <Modal
            open={props.open}
            className={"sm:w-8/12 z-50 overflow-scroll ".concat(classname)}
            paper={false}
            onRequestClose={() => { props.close(false) }}
            style={style}

        >
            <div>
                <div className="w-full bg-white px-2 py-2">
                    <IconButton onClick={(e) => { screen.group.removeScreen(screen.id) }} color="warning">
                        <CloseIcon style={{ width: '.8em', height: '.8em' }} className="fill-current  text-xl cursor-pointer w-4 h-2" />
                    </IconButton>
                </div>
                
                    <View_ screen={screen} height={modal_style.height ? (modal_style.height*60)/100:false} />
                
                
                
                
                

            </div>





        </Modal>




    )

});

export default ModalView;