import React, { useContext } from 'react';
import ReactTooltipLite from 'react-tooltip-lite';
import { ThemeContext } from 'kalenis-portal-frontend-main'
import './tooltip.css';
import { ReactComponent as InfoIcon } from './icons/svg/info.svg';
import IconButton from './buttons/IconButton.js';

const DEFAULT_TEMPLATE = {
	'background_color':"",
	'type':'standard',
	'color':""
}

function Tooltip(props){
	const { children, content, template } = props;
	// const background = type === 'html' ? 'white':''
	const theme = useContext(ThemeContext);
	const tooltip_template = template ? theme.getTooltipTemplate(template):DEFAULT_TEMPLATE


	return (
			<ReactTooltipLite
				background={tooltip_template.background_color}
				tipContentClassName=" text-gray-800  text-xs font-medium"
				tipContentHover
				// eventOff="onClick"
				eventToggle="onClick"
				mouseOutDelay={0}
				color={tooltip_template.color}
				content={tooltip_template.type==='standard' ? 
					content
					:
					<div dangerouslySetInnerHTML={{ __html: content }}></div>
				}
            >
				<IconButton 
                    onClick={(e) => { console.log("OPEN THE TOOLTIP PLEASE") }} 
                    color="transparent"
                    size="sm"
                    shape="circle"
                    title="Info">
                    <InfoIcon  />
                </IconButton>
			</ReactTooltipLite>
		)
}

export default Tooltip;
