import React, { useEffect, useState } from 'react';
import { computed } from 'mobx';
import IconButton from '../../buttons/IconButton';
import { ReactComponent as CheveronLeft } from '../../icons/svg/cheveron-left.svg';
import { ReactComponent as CheveronRight } from '../../icons/svg/cheveron-right.svg';
import ListItem from './ListItem.js';
import TransferList from './TransferList.js';
import Tooltip from '../../Tooltip.js';

function MultiLink(props){
    const { field, state_attrs, record } = props;

    const [posible, setPosible] = useState([]);
    const [selected, setSelected] = useState([]);
    const [checked, setChecked] = useState([]);

    const values = field.get_value(record);
    const [wKey, setWkey] = useState("");
    const key_fields = field.get_key_fields_values && computed(() => field.get_key_fields_values(record)).get();

    useEffect(() => {
        // const key_fields = field.get_key_fields_values(record)
        let newK = ""

        for(let k in key_fields){
            newK = newK.concat(key_fields[k])
        }

        if(newK!==wKey){
            console.log("I HAVE A NEW KEY")
            console.log(field.name)
            console.log(newK)
            console.log(wKey)
            setWkey(newK)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key_fields]);


    useEffect(() => {
        field.getOptions(record).then(options => {
            if(values && values.length > 0){
                addPosibleValues(options, values);
            } else {
                setPosible(options)
            }
        })
    }, [field, record]);


    function addPosibleValues(posibles, selecteds) {
        setPosible([]);
        let posibleIDs = [];
        let posibleNames = [];
        let selectedIDs = [];
        let setposibles = [];

        for (var i = 0; i < posibles.length; i++) {
            posibleIDs.push(posibles[i].id);
            posibleNames.push(posibles[i].rec_name);
        }

        for (var i = 0; i < selecteds.length; i++) {
            selectedIDs.push(selecteds[i].id);
        }

        const posibleValues = not(posibleIDs, selectedIDs)

        for (var i = 0; i < posibleValues.length; i++) {
            setposibles.push({id: posibleValues[i], rec_name: posibleNames[posibleValues[i] - 1]});
        }

        setPosible(setposibles);
        setSelected(selecteds);
    }

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(checkedValues, actualValues) {
        return checkedValues.filter((value) => actualValues.indexOf(value) !== -1);
    }

    const leftChecked = intersection(checked, posible);
    const rightChecked = intersection(checked, selected);


    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleOneRight = (item) => {
        setSelected(selected.concat(item));
        setPosible(not(posible, [item]));
        setChecked(not(checked, [item]));
    };

    const handleAllRight = () => {
        setSelected(selected.concat(posible));
        setPosible([]);
    };

    const handleCheckedRight = () => {
        setSelected(selected.concat(leftChecked));
        setPosible(not(posible, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setPosible(posible.concat(rightChecked));
        setSelected(not(selected, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleOneLeft = (item) => {
        setPosible(posible.concat(item));
        setSelected(not(selected, [item]));
        setChecked(not(checked, [item]));
    };

    const handleAllLeft = () => {
        setPosible(posible.concat(selected));
        setSelected([]);
    };

	return (
        <TransferList
            addPosibleValues={addPosibleValues}
            autoFocus={props.autoFocus}
            field={field}
            handleAllLeft={handleAllLeft}
            handleAllRight={handleAllRight}
            handleCheckedLeft={handleCheckedLeft}
            handleCheckedRight={handleCheckedRight}
            handleKeyDown={props.handleKeyDown}
            handleOneLeft={handleOneLeft}
            handleOneRight={handleOneRight}
            handleToggle={handleToggle}
            leftChecked={leftChecked}
            onBlur={props.handleFieldBlur}
            onChange={props.onChange}
            onFocus={props.onFocus}
            posible={posible}
            style={props.style}
            rightChecked={rightChecked}
            record={record}
            selected={selected}
            setPosible={setPosible}
            state_attrs={state_attrs}
        />
	)
}

export default MultiLink;