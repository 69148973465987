import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'
export const SupersetDashboard = observer(function SupersetDashboard(props) {
    const {height, width, widget} = props
    const iframe_style = "height:"+height+"px"+";width:"+width+"px"
    const enabled = widget.isEnabled()

    useEffect(() => {

        if(enabled && document.getElementById(widget.uuid)){
            widget.embedSupersetDashboard(document.getElementById(widget.uuid), iframe_style)
        }
        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if(enabled && document.getElementById(widget.uuid)){
            // embedSuperset()
            document.getElementById(widget.uuid).children[0].style = iframe_style
        }
        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height, width]);
    

    return (
            <div id={widget.uuid} className="w-full">
                {!enabled &&
                <div>BI is not enabled. Please contact your consultant.</div>
                }

            </div>

    )

}
)

export default SupersetDashboard;