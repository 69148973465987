import React, { useEffect, useState } from 'react';
import IconButton from '../../buttons/IconButton';
import { ReactComponent as CheveronLeft } from '../../icons/svg/cheveron-left.svg';
import { ReactComponent as CheveronRight } from '../../icons/svg/cheveron-right.svg';
import ListItem from './ListItem.js';
import TransferList from './TransferList.js';
import Tooltip from '../../Tooltip.js';

function MultiSelection(props){
    const { field, state_attrs, record } = props;

    const [posible, setPosible] = useState([]);
    const [selected, setSelected] = useState([]);
    const [checked, setChecked] = useState([]);

    const values = field.get_value(record);

    useEffect(() => {
    	if(values && values.length > 0){
    		setPosible(not(field.selection_options, values));
    		setSelected(values);
    	} else {
    		setPosible(field.selection_options);
    	}
    }, [field, record]);

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(checkedValues, actualValues) {
        return checkedValues.filter((value) => actualValues.indexOf(value) !== -1);
    }

    const leftChecked = intersection(checked, posible);
    const rightChecked = intersection(checked, selected);


    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleOneRight = (item) => {
        setSelected(selected.concat(item));
        setPosible(not(posible, [item]));
        setChecked(not(checked, [item]));
    };

    const handleAllRight = () => {
        setSelected(selected.concat(posible));
        setPosible([]);
    };

    const handleCheckedRight = () => {
        setSelected(selected.concat(leftChecked));
        setPosible(not(posible, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setPosible(posible.concat(rightChecked));
        setSelected(not(selected, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleOneLeft = (item) => {
        setPosible(posible.concat(item));
        setSelected(not(selected, [item]));
        setChecked(not(checked, [item]));
    };

    const handleAllLeft = () => {
        setPosible(posible.concat(selected));
        setSelected([]);
    };

	return (
        <TransferList
            autoFocus={props.autoFocus}
            field={field}
            handleAllLeft={handleAllLeft}
            handleAllRight={handleAllRight}
            handleCheckedLeft={handleCheckedLeft}
            handleCheckedRight={handleCheckedRight}
            handleKeyDown={props.handleKeyDown}
            handleOneLeft={handleOneLeft}
            handleOneRight={handleOneRight}
            handleToggle={handleToggle}
            leftChecked={leftChecked}
            onBlur={props.handleFieldBlur}
            onChange={props.onChange}
            onFocus={props.onFocus}
            posible={posible}
            style={props.style}
            rightChecked={rightChecked}
            record={record}
            selected={selected}
            setPosible={setPosible}
            state_attrs={state_attrs}
        />
    )
}

export default MultiSelection;