import React, { Fragment } from 'react';
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge, SmoothStepEdge } from 'reactflow';



function CenterLabelEdge(props){
const { id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data} = props




  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-10%, -50%) translate(${labelX}px,${labelY}px)`,
            background: 'white',
            border:'1px solid #b1b1b7',
            padding: 5,
            borderRadius: 5,
            fontSize: 12,
            fontWeight: 500,
          }}
          className="nodrag nopan"
        >
          {data.label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default CenterLabelEdge;
