import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { SpreadsheetScreen } from '../list/SpreadsheetScreen'
import { validateDate } from "../../common"
import moment from 'moment';

export class CalendarScreen extends SpreadsheetScreen {
    start_date_field_id = ""
    end_date_field_id = ""
    event_title_field_id = ""
    event_detail_action_id = ""
    event_new_action_id = ""
    view_types = []
    initial_view = ""
    
    get start_date_field(){
        return this.fields.find(f => f.id === this.start_date_field_id)
    }
    get end_date_field(){
        return this.fields.find(f => f.id === this.end_date_field_id)
    }
    get event_title_field(){
        return this.fields.find(f => f.id === this.event_title_field_id)
    }
    // All day events for date fields, false on datetimefields
    get all_day_events(){
        return this.start_date_field.type == 'date' && this.end_date_field.type == 'date'
    }

    get event_detail_action(){
        return this.actions.find(a => a.id === this.event_detail_action_id)
    }

    get event_new_action(){
        return this.actions.find(a => a.id === this.event_new_action_id)
        
    }

    constructor(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false) {
        
        super(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields, initialize_actions, initialize_data, initialize_callback, fileHandler)

        this.start_date_field_id = view.calendar_start_date_field
        this.end_date_field_id = view.calendar_end_date_field
        this.event_title_field_id = view.calendar_event_title_field
        this.event_detail_action_id = view.calendar_event_detail_action
        this.event_new_action_id = view.calendar_event_new_action
        this.view_types = ['month','week','day','agenda','work_week']
        this.initial_view = 'month'
        
        makeObservable(this, {
            start_date_field_id:observable,
            end_date_field_id:observable,
            view_types:observable,
            initial_view:observable,

        })

    }
    
    get_start_date_value(record){
        const value = moment.utc(this.start_date_field.get_value(record)).toDate()
        // return this.start_date_field.get_value(record)
        return value
    }

    get_end_date_value(record){
        let start_date = this.get_start_date_value(record)
        let end_date = this.end_date_field.get_value(record)
        // console.log("TIME")
        // console.log(end_date)
        const is_range = start_date != end_date
        
        if(end_date){
            if(is_range){
                // Date Fields: Always are all day events by default, add 1 day to make inclusive the end date.
                if(this.all_day_events){
                    
                    end_date = end_date.add(1,'days')
                    
                }
                // DateTime Fields: if time is not set, add 1 day to include the end date
                
                else if( moment.utc(end_date).local().format("HH:mm:ss") == "00:00:00"){
                    
                    end_date = end_date.add(1,'days')
                }
                
            }
            const value = moment.utc(end_date).toDate()
            return value
        }
    
        
        
        
        
        // console.log(moment.utc(end_date).local().format("HH:mm:ss"))
        


        
    }
    
    get_title_value(record){
        return this.event_title_field.get_string_value(record)
    }

    view_event_detail(records){

        if(this.event_detail_action){
            this.event_detail_action.execute(records)
        }


    }
    new_event(event_info){
        if(! this.event_new_action){
            return
        }
        

        function setInitialValues(start_field,end_field,form_screen){
            console.log("Callbacking")
            console.log("modal childs")
            if(form_screen.active_record){
                console.log("Event info")
                console.log(event_info)
                // let start_date = moment.utc(event_info.start).format('YYYY-MM-DD HH:mm:ss')
                let start_date = validateDate(event_info.start,start_field.type)

                form_screen.active_record.set_value(start_field.name,start_date)
                form_screen.active_record.set_value(end_field.name,start_date)
            }
            
        }
        
        this.event_new_action.execute([],false,false,false,(screen)=>{setInitialValues(this.start_date_field, this.end_date_field,screen)})
        



    }

    


}