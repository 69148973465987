import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'
import {BarPieGraph} from './BarPieGraph'
import {Record} from '../../model/Record'
import { LineGraph } from "./LineGraph"

const GRAPH_TYPES = {
    'bar':BarPieGraph,
    'pie':BarPieGraph,
    'line':LineGraph,
}

export class GraphScreen extends Screen {
    graphs = []
    active_graph = false
    show_data = false
    data_screen = false
    
    get data_values(){
        return this.data.records.map(function(record){return record._values})
    }
    
    constructor(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false) {

        super(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false)

        makeObservable(this, {
            graphs:observable,
            show_data:observable,
            data_screen:observable,
            set_active_graph:action,
            switch_view:action

        })
        this.set_graphs(view.graphs)
        this.set_active_graph(this.graphs[0])
        
        const data_view = {...view}
        data_view['type'] = 'list'
        data_view['id'] = data_view['id']+'data_view'
        data_view['start_empty'] = true
        // this.data_screen = new Screen(group, data_view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = false, initialize_actions = false)
        this.data_screen = group.addScreen(data_view,false,[],{},true,false,false,false,false)
        this.data_screen.title = ""
        


    }

    set_active_graph(graph){
        this.active_graph = graph;
    }

    set_graphs(definitions){
        runInAction(() => {
            this.graphs = definitions.map(function(definition){
                if(!GRAPH_TYPES[definition.type]){
                    throw new Error("Graph type not supported: " + definition.type)
                }
                return new GRAPH_TYPES[definition.type](definition,this)
            }.bind(this))
            
        })
    }

    
    async do_search(search, force) {
        if(!this.active_graph){
            return 
        }
        let count = false
        let reset_page = false
        if (search) {
            // this.search = search
            this.current_search = search.current_search || this.current_search
            this.action_params = search.action_params || this.action_params
            this.action_id = search.action_id || false
            this.order = search.order || this.order
            //count records and reset page in every search change
            count = true
            reset_page = true
        }
        if (this.filterable) {
            this.filters.setInitialFilters()
        }
        // add graph definition to data request
        // if(this.default_graph){
        const extra_args = {'graph':this.active_graph.definition}

        // }
        



        this.data.loadData(count, reset_page, extra_args)

    }

    switch_view(){
        
        this.show_data = !this.show_data;

        const fields = this.fields.map(function(field){return field.attributes})
        this.data_screen.createFields(fields)
        this.data_screen.loadingFields= false

        if(this.show_data && !this.data_screen.data.records.length){
            const values = this.data.records.map(function(record){return {...record._values}})
            
            const records = values.map(function(val){
                return new Record(val,this.data_screen,this.data_screen.data)
            }.bind(this))
            this.data_screen.data.records = records;
            
        }
        

    }





}