import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"

const date_formats = {
    'datetime':'time:%Y-%m-%d %H:%M:%SZ',
    'date':'time:%Y-%m-%dZ'
}


export class LineGraph {
    id = ""
    type = ""
    groupby = ""
    action = ""
    markers = []
    screen = ""
    x_axis = ""
    y_axis = ""
    

    get definition(){
        return {
            id:this.id,
            type:this.type,
            groupby:this.groupby,
            action:this.action,
            markers:this.markers,
            x_axis: this.x_axis,
            y_axis:this.y_axis

        }
    }
    get x_field(){
        
        return this.screen.fields.find(f => f.name == this.x_axis)
    }
    get y_field(){
        
        return this.screen.fields.find(f => f.name == this.y_axis)
    }

    get x_legend(){
        return this.x_field ? this.x_field.description:""
    }
    get y_legend(){
        return this.y_field ? this.y_field.description:""
    }
    get x_format(){
        let res = false;
        if(['date','datetime'].includes(this.x_field.type)){
            res = date_formats[this.x_field.type]
        }
        
        return res;
    }
    
    constructor(definition, screen) {
        this.id = definition.id
        this.type = definition.type
        this.groupby = definition.groupby
        this.action = definition.action
        this.markers = definition.graph_markers
        this.x_axis = definition.x_axis
        this.y_axis = definition.y_axis
        this.screen = screen

        makeObservable(this, {
            type: observable,
            groupby: observable,
            action: observable,
            markers: observable,
            

        })

    }



}