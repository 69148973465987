import { makeAutoObservable, autorun, runInAction, action, observable, makeObservable, computed } from "mobx"
import { evalCondition } from '../common'

export class NewCalculatedValue {
    field = false
    record = false
    value = ""
    dependencies = []
    inputs = ""
    loading = true
    value_getter = false

    constructor(field, record, dependencies=false,value_getter=false) {
        makeObservable(this, {
            loading: observable,
            dependencies: observable,
            inputs: observable,
            reload: action,
            update_inputs:action,
            setLoading:action
        })

        this.field = field
        this.record = record
        this.loading = false
        this.dependencies = dependencies ? dependencies:field.formula_dependencies
        // this.dependencies = field.formula_dependencies
        this.value_getter = value_getter ? value_getter:false
        


    }
    
    //TODO: Check here for invisible formula_fields. Should be solved and then used as input for other formulas.
    //TODO: Check cache of request => fails when chaining formula>formula>formula.
    get_dependencies_values(){
        let vals = {}

        this.dependencies.forEach(function (dep) {
            const field = this.field_by_name(dep)
            let value;
            if(!field){
                return
            }
            if(field.type === 'formula' && field.is_loading(this.record)){
                
                return
            }
           
            value = field.get_value(this.record, true)
            
        
            vals[dep] = value

        }.bind(this))
        return vals

    }
    create_json_value() {
        let vals = this.get_dependencies_values()
        if(Object.keys(vals).length === 0){
            return false
        }
        return JSON.stringify(vals)
    }

    update_inputs() {

        this.inputs = this.create_json_value()
    }

    field_by_name(fname) {
        return this.record.screen.get_field_by_any_name(fname)
        // return this.record.screen.fields.find(f => f.name === fname)
    }



    get_value() {
        
        if (this.inputsChanged()) {
            
            return this.reload()

        }
        else {
            return this.value
        }


    }
    inputsChanged() {
        let res = false

        const in_edition = this.dependencies.map(function(fname){
            
            return this.record.field_in_edition == fname
            // const field = this.field_by_name(fname)
            // if(field){
            //     return this.field_by_name(fname).in_edition
            // }
            // else{
            //     return false
            // }
            
        }.bind(this)).some(function (a) {
            return a === true
        }.bind(this))
        
        if(in_edition){
            return false
        }
        
        const new_values = this.create_json_value()
        if(!new_values){
            return false
        }
        if (new_values !== this.inputs) {
            res = true
        }


        return res
    }
    setLoading(value){
        this.loading = value;
    }

    async calc_value(){
        if(this.value_getter){
            return  this.value_getter(this)
        }
        else{
            return  this.field.get_calculated_value(this)
        }

    }

    async reload() {
        // this.readonly = !this.readonly;
        // let json = JSON.parse(this.states)
        if(!this.loading){
            this.setLoading(true)
        
            // this.value = await this.field.get_calculated_value(this)
            this.value = await this.calc_value()
            
            this.update_inputs()
            this.setLoading(false)
            
            
            return this.value
        }
        



    }

}