import React, { useEffect, useState, forwardRef, createRef } from 'react';
// import View from '../View.js'
import Draggable from 'react-draggable';
// import FilterBar from '../../ui/FilterBar.js';
import { observer } from 'mobx-react-lite'
import {View_} from '../View'
import ActionWidget from './ActionWidget'
import SupersetDashboard from './SupersetDashboard';
import GridHoc from '../../ui/GridHoc.js'
import ViewHeader from '../ViewHeader'



// const widgets_map = {
//     'view': ({props}) => <View_  screen={props.widget.screen} container="paper"  hide_toolbar={false} {...props}/>,
//     'action': (props) => <ActionWidget {...props}/>,
//     'superset_dashboard':  (props) => <SupersetDashboard target="board_base" {...props}/>
    
// }

const widgets_map = {
    'view': (props) => <View_  screen={props.widget.screen} container="paper"  hide_toolbar={false}  {...props} height={(props.height-30)*0.85}/>,
    'action': (props) => <ActionWidget {...props}/>,
    'superset_dashboard':  (props) => <SupersetDashboard target="board_base" {...props} height={(props.height)*0.90}/>
    
}


const WidgetContainer = observer(forwardRef(function WidgetContainer(props, ref) {
    const {widget} = props
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)

    useEffect(() => {
        if (!ref.current) return; // wait for the elementRef to be available
        const resizeObserver = new ResizeObserver(() => {
        // const new_height = (ref.current.getBoundingClientRect().height-30)*0.85
        const new_height = ref.current.getBoundingClientRect().height
        setHeight(new_height)
        setWidth(ref.current.getBoundingClientRect().width)
         
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up 
      }, []);

    return (
        <div 
            ref={ref}
            {...props}
            key={widget.id}
            // className={"bg-red-300"}
            >

                {widgets_map[widget.type]({widget:widget, height:height,width:width})}
                {props.children}



               
            </div>
    )
}));

export const NewBoardView = observer(function NewBoardView(props) {
    const {screen} = props
    const widgets = screen.widgets
    const container_ref = React.createRef()
    const editor_options = screen.editor.edition_options
    const edition_mode = screen.editor ? screen.editor.design_mode:false
    return (
            <div id="board_base" ref={container_ref}>
                {editor_options.length ?
                
                    <ViewHeader
                        screen={screen}
                        title_classname="text-xl px-6 py-1 text-gray-800"
                        hide_toolbar={props.hide_toolbar}
                        isSpreadsheet={false}
                    />
                    :
                    ""
            }
            
                {widgets.length ? 
                    <GridHoc screen={screen}>
                        {widgets.map((widget, index) => (
                            <WidgetContainer
                                key={widget.id}
                                widget={widget}
                                edition_mode={edition_mode}        
                                ref={createRef()}
                                // widget_component={widgets_map[widget.type]({widget:widget, screen:screen})}
                                // widget_component={widgets_map[widget.type]}
                                />


                        ))}
                    </GridHoc>
                    :
                    ""
                }
                

            </div>
        

    )

}
)

export default NewBoardView;
