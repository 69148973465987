import React, { useContext } from 'react';
import { NotificationContext } from './NotificationContext'
// Dinamyc import native-web
import {SnackBar} from './SnackBar.js'
import ConfirmationDialog from './ConfirmationDialog'
import { observer } from 'mobx-react-lite'




export const NotificationArea = observer(function NotificationArea(props) {


    return (
        <React.Fragment>
            <SnackList/>
            <DialogList/>
           
        </React.Fragment>
    )

});

const SnackList = observer(function SnackList(props) {
    const nc = useContext(NotificationContext)
    const snack_queue = nc.snack_queue
    const SnackComponent = nc.snack_component ? nc.snack_component:SnackBar

    return (
        <React.Fragment>
            {snack_queue.map((n, index) => (
                <SnackComponent
                    key={n.id}
                    position={index}
                    snack={n}
                    target="notifications" />

            ))}
            
        </React.Fragment>
    )

});

const DialogList = observer(function DialogList(props) {
    const nc = useContext(NotificationContext)
    const dialog_queue = nc.dialog_queue
    const DialogComponent = nc.dialog_component ? nc.dialog_component.component:ConfirmationDialog
    const extra_props = (nc.dialog_component && nc.dialog_component.extra_props) ? nc.dialog_component.extra_props:{}

    return (
        <React.Fragment>
            
        {dialog_queue.map((n, index) => (
          <DialogComponent
            key={n.id}
            position={index}
            dialog={n}
            target="notifications" {...extra_props} />
  
        ))}
        </React.Fragment>
    )

});
