import React, {useContext} from 'react';
import './App.css';
import KalenisRouter from './navigation/KalenisRouter.js'
// import NotificationManager from './ui/notifications/NotificationManager'

import {AppRoot} from 'kalenis-portal-frontend-main'
import 'kalenis-web-components/dist/index.css'

//temp: remove when upgrade tailwind
const library_css = [
  'bg-green-200',
  'text-green-800', 
  'border-green-400'
]
function App() {
  

  return (

    // <NotificationManager>
      <AppRoot>
  
        <KalenisRouter className="bg-background" />
  
        
        {/* </Theme> */}
      </AppRoot>
    // </NotificationManager>


  );
}

export default App;
