import React from 'react';

import { observer } from 'mobx-react-lite'
import { ReactComponent as ChevronRight } from '../../ui/icons/svg/cheveron-right.svg';
import { ReactComponent as ChevronDown } from '../../ui/icons/svg/chevron-down.svg';
import IconButton from '../../ui/buttons/IconButton';
import clsx from 'clsx';

const LeftSideCell = observer(function LeftSideCell(props) {

    const { rowIndex, changeSelection, screen, className, cell_key, style, isSpreadsheet } = props;
    const isSelected = screen.is_selected(screen.get_record_by_index(rowIndex).id)
    const record = screen.get_record_by_index(rowIndex)
    let cls = isSelected ? clsx(className, "bg-light-selected",screen.get_group_by_cls(record)):clsx(className, screen.get_group_by_cls(record))
    if(screen.readonly && isSpreadsheet){
        cls = clsx(cls, 'border border-gray-300 bg-gray-200')
    }
    const grouped = record.groupby_childs_domain

    return (
        <div className={cls} >
            {grouped ?
            <IconButton 
                onClick={(e) => { record.set_groupby_expanded() }} 
                color="transparent" 
                // size='md'
                shape='circle'
                title="Expand"
                style={{paddingTop:10}}
                >
                {record.groupby_expanded ? <ChevronDown />:<ChevronRight />}
            </IconButton>
            :
            <input
                checked={isSelected}
                type="checkbox"
                size="small"
                color="primary"
                style={{ padding: 0 }}
                onChange={(e) => { changeSelection(e, rowIndex) }}
                


            />
            }
            
        </div>

    )


});

export default LeftSideCell;