import React, {Fragment} from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
import { ReactComponent as Dots } from '../../ui/icons/svg/navigation-more.svg'
import { observer } from 'mobx-react-lite'

const base_cls = "bg-transparent focus:placeholder-transparent placeholder-gray-900  placeholder-opacity-50 focus:outline-none rounded-lg w-full appearance-none leading-normal"



const FormulaField = observer(function FormulaField(props) {
    const { field, state_attrs, record } = props;
    // const loading = field.is_loading(record)
    const loading = record._values[field.name] ? record._values[field.name].loading:true




    return (
<>
        {loading ? 
        <div className="flex justify-end content-end" style={{minHeight:'1.5rem'}}>
            <Dots style={{ width: '1.2em', height: '1.2em' }} className="fill-current text-xl cursor-pointer w-4 h-2 scale-left-to-right" />
            
        </div>
           
            :
            <>{props.children}</>
        }
  </>      




    )

});

export default FormulaField;