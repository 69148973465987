import React, {useContext} from 'react';
import { ThemeContext } from 'kalenis-portal-frontend-main'
import Select from 'react-select';



const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderRadius: '0px',
    marginTop: '0px'
  }),
  container: (provided, state) => ({

    ...provided,
    // height:'100%',
    // backgroundColor: 'red',
    
    




  }),

  control: (provided, state) => ({

    ...provided,
    border: '0px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    // padding: '0px',
    minHeight: '0px',
    top:'10%'
    
    




  }),
  clearIndicator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    ':hover': {},



  }),
  indicatorSeparator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    margin:0,
    


  }),
  dropdownIndicator: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    


  }),
  input: (provided, state) => ({

    ...provided,
    margin:0,
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    


  }),
  valueContainer: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    paddingLeft:0,
    maxHeight:'1.5rem',
    
    
    


  }),
  placeholder: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    paddingTop:0,
    paddingBottom:0,
    maxHeight:'1.5rem',
    top:'55%',
    color: "inherit",
    fontWeight: "400",
    opacity: "0.6",
  }),
  singleValue: (provided, state) => ({

    ...provided,
    cursor: 'pointer',
    padding:0,
    maxHeight:'1.5rem',
    overflow:'unset',
    top:'40%',
    color:state.data.color ? state.data.color:"inherit"


  }),
  
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    minWidth:200
  }),
  option: (provided, state) => {
    return {
    ...provided,
    //color:state.data.color ? state.data.color:"inherit",
    // backgroundColor:state.isSelected ? 'red':undefined
      color: 'rgba(113, 128, 150, 1)',
  }},

  multiValue: (provided, state) => ({
    ...provided,
    border: '1px solid #CBD5E0',
    backgroundColor: 'white',
    borderRadius: '5rem',
    paddingLeft: '.25rem',
    paddingRight: '.25rem',
    marginBottom: '.45rem',
  }),

  multiValueLabel: (provided, state) => ({
    ...provided,
    color: 'rgba(113, 128, 150,1)',
    fontWeight: '500',
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    ':hover': {},
  }),
}

function SelectField(props) {
  const {field, record, onBlur} = props
  const value = field.get_value(record)
  const general_theme = useContext(ThemeContext);
  const onFocus = (e) => {};

    return (
        
        <Select
          isSearchable
          autoFocus={props.autoFocus}
          ref={props.inputRef}
          value={value}
          required={props.state_attrs.required}
          isDisabled={props.state_attrs.readonly}
          styles={props.styles ? props.styles : customStyles}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          placeholder={field.description}
          onChange={(value)=>{
            props.onChange(false, value)
            onBlur(false,value)
          }}
          // HERE: CUSTOMIZE THEME A BIT
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: general_theme.values.light_selected,
              primary: general_theme.values.light_selected,
            },
          })}
          onKeyDown={props.handleKeyDown}
          options={field.selection_options}
          isMulti={props.multi}
          onBlur={props.saveOnBlur}
          classNamePrefix={props.classNamePrefix ? props.classNamePrefix : ""}
          name={props.name}
          onFocus={onFocus}
      
/>
            


    )

}

export default SelectField;