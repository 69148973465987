import React, { useState, useEffect } from 'react';
import Modal from '../ui/Modal.js'
import Portal from '../common/Portal'
// import loading_gif from '../img/loader.gif';

const fullscreen_style = {
    position:'fixed',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform: 'translateX(-50%) translateY(calc(-50% - .5px))',
    zIndex                : '9999',
    // backgroundColor       : 'transparent',
    // border                : 'none'
    fontSmoothing: 'subpixel-antialiased',
}

const LoadingIndicator = React.forwardRef((props, ref) => (

    <div>
        {(props.show && ref) &&

            <Portal>
                <LoadingIndicatorComp key={'loading'} parent_ref={ref} {...props} />


            </Portal>
        }

    </div>



));



function LoadingIndicatorComp(props) {
    const [position, setPosition] = useState({})
    const {fullscreen, modifiers} = props
    // useClickOutside(props.parent_ref, props.onClose, props.open);
    const cls = fullscreen ? 'absolute bg-modal-background inset-0 z-40':'flex items-center justify-center'

    useEffect(() => {
        if(!fullscreen){
            const dimensions = props.parent_ref.current.getBoundingClientRect()
            let pos = {
                position: 'absolute',
                left: dimensions.left,
                top: dimensions.top,
                width: dimensions.width,
                height: dimensions.height,
                zIndex: '9999'
            }
    
            if (props.modifiers) {
                pos.width += modifiers.width || 0
                pos.left += modifiers.left || 0
                pos.top += modifiers.top || 0
                pos.height = modifiers.height || 0
            }
            setPosition(pos)  
        }
        



    }, [props.parent_ref, modifiers, fullscreen]);


    // const wrapperRef = React.useRef(null);




    return (
        // <Portal>

        <div className={cls} {...props} style={!fullscreen ? position:{}}>
            <div style={fullscreen ? fullscreen_style:{}}>
                <svg  className="ml-1 mr-3 h-10 w-10 text-primary animation_spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            

        </div>

        // </Portal>

    )

}



export default LoadingIndicator;