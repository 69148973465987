import React from 'react';
import Modal from '../ui/Modal'
import FormButton from '../ui/buttons/FormButton'
import { observer } from 'mobx-react-lite'


function DefaultBody(props) {
    const { dialog } = props
    return (
        <div className="flex flex-col flex-grow px-2 py-2" style={{ justifyContent: 'space-around' }}>
            {dialog.html ?
             <div className="py-8 px-2" dangerouslySetInnerHTML={{ __html: dialog.message }}></div>
             :
            <div className="py-8 px-2">{dialog.message}</div>
            }
            
            <div className="flex">
                {dialog.actions.map((a, index) => (
                    <div key={a.name} className="flex-grow px-2">
                        <FormButton
                            onClick={() => dialog.callbackWrapper(a.callback)}
                            state_attrs={{}}
                            color={a.color}>
                            {a.name}
                        </FormButton>
                    </div>

                ))}
            </div>
        </div>
    )
}

const ConfirmationDialog = observer(function ConfirmationDialog(props) {
    const { dialog } = props
    return (
        <Modal
            open={true}
            paper={true}
            target={props.target}
            style={{ minWidth: '40%', minHeight: '15%', marginTop: props.position * 40 }}
            top='25%'
            className="flex w-2/5"

        >

           
                {dialog.body_component ?
                    <React.Fragment>{dialog.component({dialog})}</React.Fragment>
                    :
                    <DefaultBody dialog={dialog} />
                }


            

        </Modal>
    )
});

export default ConfirmationDialog;