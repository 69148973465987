import React, { useEffect, useState } from 'react';
// import View from '../View.js'
import Draggable from 'react-draggable';
// import FilterBar from '../../ui/FilterBar.js';
import { observer } from 'mobx-react-lite'
import {View_} from '../View'
import ActionWidget from './ActionWidget'
import SupersetDashboard from './SupersetDashboard';



const widgets_map = {
    'view': ({ widget, height }) => <View_ screen={widget.screen} container="base" height={height} hide_toolbar={false}/>,
    'action': ({widget, height}) => <ActionWidget widget={widget} height={height}/>,
    'superset_dashboard':  ({widget, height, screen}) => <SupersetDashboard widget={widget} height={height} screen={screen} target="board_base"/>
    
}

const widgetColspan = {
    1: "md:col-span-1",
    2: "md:col-span-2",
    3: "md:col-span-3",
    4: "md:col-span-4"
}

const boardColumns = {
    1:'grid-cols-1 ',
    2: 'grid-cols-2 ',
    3:"grid-cols-3 ",
    4:"grid-cols-4 "
}


function WidgetContainer(props) {

    const {widget, position} = props
    const backgroundColor = widget.type === 'view' ? ' bg-white shadow-xl ':' bg-transparent '

    return (

            <div style={{overflow:'hidden'}} className={"  w-full px-2 py-2 ".concat(widgetColspan[widget.colspan] || 4).concat(backgroundColor)}>

            
                {props.children}



               
            </div>

        


    )
}

export const BoardView = observer(function BoardView(props) {

    
    const {screen} = props
    const columns = screen.default_columns;
    const widgets = screen.widgets
    // const rows = 6
    // const max_widget_height = window.innerHeight / rows
    
    const baseGridClass = ()=>{
        let base_cls = "flex-grow grid w-full  gap-x-5 gap-y-4 py-4 px-4 "
        if(columns){
           base_cls =  base_cls.concat(boardColumns[columns])
        }
        // if(rows){
        //     base_cls = base_cls.concat(boardRows[rows])
        // }
        return base_cls
    }


    return (
        // <div className="relative flex  w-full h-screen mb-12">

            <div id="board_base" className={baseGridClass()}>
                {/* <SupersetDashboard screen={screen} target="board_base"/> */}
                {widgets.map((widget, index) => (
                    <WidgetContainer
                        position={index}
                        key={widget.id}
                        widget={widget}
                        >
                            {/* <View_ screen={widget.screen} height={wSizes[widget.id] ? wSizes[widget.id].height : false} /> */}
                            {
                                widgets_map[widget.type]({widget:widget, height: window.innerHeight * widget.height, screen:screen})
                            }

                        

                    </WidgetContainer>


                ))}

            </div>
        // </div>

    )

}
)

export default BoardView;
