import React from 'react';
// import {sheet_navigation_signals} from '../../common/common.js'
// import FocusTrap from 'focus-trap-react'
const base_cls = `field bg-transparent focus:placeholder-transparent placeholder-gray-600 placeholder-opacity-60 focus:outline-none rounded-lg w-full appearance-none leading-normal`;

const types = {
    'char': { type: '', validateKey: false, caretPosition: true },
    // 'float': { type: '', align: 'right', validateKey: (key) => { return /[0-9]|[-]|[.]|[,]|[e]|[E]/.test(key) }, caretPosition: true, formatValue: (value) => { return parseFloat(value) } },
    'integer': { type: 'number', align: 'right', validateKey: (key) => { return /^\d+$/.test(key) }, caretPosition: false },
}

function BaseField(props) {
    const {field, state_attrs, onFocus, value} = props;
    const type = field.formula_field_widget ? field.formula_field_widget:field.type
    const inputType = types[type].type;
    function handleKeyPress(e) {
        if(types[type]){
            if (types[type].validateKey) {

                if (!types[type].validateKey(e.key)) {
    
                    return e.preventDefault()
    
                }
    
            }

        }
        
    }

    

   

    return (
        
            
            <input
                id={props.id || props.name}
                autoFocus={props.autoFocus}
                // tabIndex="-1"
                ref={props.inputRef}
                name={props.name}
                label={props.label}
                required={state_attrs.required}
                readOnly={state_attrs.readonly}
                className={base_cls.concat(
                  !value ? " font-normal" : " font-medium"
                ).concat(props.className)}
                value={value}
                onChange={(e)=>{props.onChange(e,e.target.value)}}
                // onBlur={props.onBlur}
                onBlur={(e)=>{props.onBlur(e,e.target.value)}}
                //type={field.hide_value ? 'password':''}
                type={field.hide_value ? 'password':inputType}
                placeholder={field.description}
                onKeyPress={handleKeyPress}
                onKeyDown={props.handleKeyDown}
                style={props.style}
                autoComplete="off"
                onFocus={onFocus}

            />
            
        


    )

}

export default BaseField;