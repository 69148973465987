import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { Screen } from '../Screen'
import {findTags, setImgPlaceholders} from '../../html_template'

export class HTMLScreen extends Screen {
    template_source = ""
    template_field = ""
    view_template = ""

    get template_field_object() {
        let field = false
        if (this.template_field && this.fields.length) {
            field = this.fields.find(f => f.name === this.template_field)
        }

        return field
    }
    get content() {
        let content = ""
        if (this.template_source === 'view_field' && this.active_record.id) {
            content = this.template_field_object.get_value(this.active_record)
        }
        else {
            content = this.view_template
        }

        return content

    }
    constructor(group, 
        view, 
        connection, 
        navigate, 
        initial_search, 
        route_state, 
        is_modal, 
        parent, 
        initialize_fields = true, 
        initialize_actions = true, 
        initialize_data=true, 
        initialize_callback=false, 
        fileHandler=false,
        data_callback=false,
        on_load_data=false) {
        
        on_load_data = (screen, records) => {screen.render_template(records)}
        super(group, 
                view, 
                connection, 
                navigate, 
                initial_search, 
                route_state, 
                is_modal, 
                parent, 
                initialize_fields, 
                initialize_actions, 
                initialize_data,
                initialize_callback,
                fileHandler,
                data_callback,
                on_load_data)
        
        this.template_source = view.html.template_source
        this.template_field = view.html.template_field
        // this.view_template = view.html.view_template
        this.view_template = ""
        this.processing = true
        

        makeObservable(this, {
            view_template: observable,
            template_field: observable,
            setViewTemplate:action,
            render_images:action
        })

    }

    setViewTemplate(content){
        this.view_template = content
    }

    async render_images(content){
        let {tags, html_tree} = findTags(content, 'img')
        for(let img of tags){
            img.src = await this.attachment_handler.get_by_id(img.id, true)
            img.className += 'skeleton'
            img.alt = ''
            
        }
        this.setViewTemplate(html_tree.outerHTML)
        return html_tree.outerHTML
    }
    
    async render_template(records=[]){
        if(!records.length){
            records = this.data.records
        }
        console.log(records)
        const abortController = new AbortController();
        let args = {}
        args.ids = records.map(function(rec){return rec.id})
        args.view_id = this.view.id
        let content = await this.connection.dispatch('GET', '/view/render_html_content', args, false, false, false, abortController)
        
        this.setViewTemplate(setImgPlaceholders(content.content))
        this.set_processing(false)

        return await this.render_images(content.content)
        
    }





}