export const grid_layout_defaults = {
    'breakpoints':{ default: 800, mobile: 600 },
    'cols': { default: 200, mobile: 2},
    'default_colspan':200,
    'margin':[10,10]
}

const ui_defaults = {
    grid_layout_defaults,
    
}

export default ui_defaults

