import React  from 'react';
import Portal from './Portal.js'
import Paper from './surfaces/Paper.js';
// import useClickOutside from '../common/useClickOutside.js';



function Modal(props) {
    // const width = 150


    const style = {
        position:'fixed',
        top                   : props.top ? props.top:'50%',
        left                  : props.left ? props.left:'50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform: 'translateX(-50%) translateY(calc(-50% - .5px))',
        zIndex                : '9999',
        // backgroundColor       : 'transparent',
        // border                : 'none'
        fontSmoothing: 'subpixel-antialiased',
    }
    // useClickOutside(content_ref, props.onClose, props.open)


    return (
        <React.Fragment>
            {props.open &&
                <Portal target={props.target}>
                    <div className="absolute bg-modal-background inset-0 z-40">
                        {props.paper ?
                            <Paper className={props.className} style={props.style ? {...style,...props.style}:style} >
                                {props.children}
                            </Paper>
                        :
                            <div className={props.className} style={props.style ? {...style,...props.style}:style}>{props.children}</div>
                        }
                       


                    </div>



                </Portal>
            }
        </React.Fragment>


    )

}
export default Modal;