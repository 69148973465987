import { makeObservable, action, observable } from 'mobx'
import { Screen } from '../screen/Screen'
import { ScreenGroup } from '../screen/ScreenGroup'
import {evalExpr} from '../common'
import { environment } from '../common'

export class UserContext {
    screen=""
    connection=""
    base_values = {}
    initialized = false
    appbar_expression = ""
    view_editor = false
    role = null

    get appbar_data(){
        if(this.screen && this.screen.active_record){
            return evalExpr(this.screen.active_record.get_all_values(), this.appbar_expression).str
        }
        return ""
    }
    constructor(connection, values){
        makeObservable(this,{
            setScreen:action,
            screen:observable,
            initialized:observable,
            role:observable
        })
        this.connection = connection
        this.base_values = values
        this.setRole(values.role)
        this.getUserContextView()
        this.appbar_expression = ""
        this.view_editor = values.view_editor

    }
    
    setValues(values){
        console.log("NOT SETTING THE VALUES YET...")
        console.log(values)
    }
    get_all_values(){
        const record = this.screen.active_record
        let values = {}
        this.screen.fields.forEach(function(field){
            if(field.has_value(record)){
                values[field.name] = record.get_value(field.name)
            }
        })
        // return this.screen.active_record.get_all_values()
        return values
    }
    get_field(fname){
        return this.screen.get_field_by_name(fname)
    }
    get_value(fname){
        const field = this.get_field(fname)
        return field.get_value(this.screen.active_record)
    }
    get_string_value(fname){
        const field = this.get_field(fname)
        if(!field){
            return ""
        }
        return field.get_string_value(this.screen.active_record)
    }

    reloadRole(new_role){
        this.setRole(new_role)
        this.connection.loadRoutes(this.role)
    }

    onRecordUpdate(record){
        let new_record_role = record.get_value('role')
        if(new_record_role && new_record_role != this.role){
            this.reloadRole(new_record_role)
        }
        else{
            if(this.connection.main_screen_group && this.connection.main_screen_group.active_screen){
                this.connection.main_screen_group.active_screen.do_search()
            }
        }

        
        
        
    }
    createScreen(definition){
        let group = new ScreenGroup(this.connection, true)
        
        // let screen = new Screen({}, definition.view, this.connection,false, [], {},false, false,false,false)
        // let screen = group.addScreen({}, definition.view, this.connection,false, [], {},false, false,false,false)
        let screen = group.addScreen(definition.view,false,[],{},true,false,false,false,false, false, false, false, (record) => this.onRecordUpdate(record) )
        screen.createFields(definition.fields)
        screen.createActions(definition.actions)
        screen.loadingFields= false
        
        return screen
    }

    setScreen(definition){
        let screen = this.createScreen(definition)
        // let stored_values = JSON.parse(this.connection.storage.getItem('kalenis_login_info'))
        // let values = login_info ? login_info:{'id':-1}

        let record = screen.data.addRecord({values:this.base_values})
        screen.set_active_record(record)

        screen.data.loadingInitialData = false
        screen.data.loading = false

        //Map 'login' action to getToken method
        screen.set_actions_map({
            'logout': ()=>{this.connection.logout()}
        })
        this.screen = screen
        this.initialized = true

        

    }
    setRole(role_id){
        this.role = role_id
    }

    async getUserContextView(){
        const abortController = new AbortController();
        let args = {
            'environment':environment()
        }
        let view_info = await this.connection.dispatch('GET', '/user/context_view', args, false, true, false, abortController)
        if(!view_info){
            return
        }
        // let stored_data = await this.connection.storage.getItem('kalenis_login_info');
        
        // if(stored_data){
        //     login_info = JSON.parse(stored_data)
        // }
        
        if(view_info){
            this.setScreen(view_info.view)    
            this.appbar_expression = view_info.appbar_info
        }
        
    }

    

}